import { useState } from "react";
import { appConfig } from "../../../config";
import { useStepper } from "../../../context/stepperContext";
import {
  NETWORK_STATE,
  alertError,
  alertSuccess,
  capitalizeString,
  convertToGigaByte,
} from "../../../utils";
import { createDedicatedProject } from "../../../services/ProjectsService";
import { useAuth } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";
import { getUnit } from "./02-step";

const DisplayBox = ({ title, text, full }) => {
  return (
    <div
      className={`bg-[#F1F6FF] h-[50px] rounded-[8px] pl-3 ${
        full ? "w-full" : ""
      } text-[13px] flex flex-col justify-center`}
    >
      <p className="text-[12px] text-[#858585]">{title}</p>
      <p className="text-[13px] font-[600]">{text}</p>
    </div>
  );
};

const Step4 = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [status, setStatus] = useState(NETWORK_STATE.IDLE);
  const {
    dedicatedCurrentStep,
    setDedicatedCurrentStep,
    dedicatedCurrentConfig,
    setDedicatedCurrentConfig,
  } = useStepper();

  const createProject = async () => {
    let argumentRules = "";
    dedicatedCurrentConfig.argumentRules.map((rule, index) => {
      argumentRules = `${argumentRules} ${rule} `;
    });

    const postData = {
      projectConfig: {
        networkName:
          appConfig.networks[
            dedicatedCurrentConfig?.networkId
          ].name.toLowerCase(),
        createdBy: auth.user.id,
        projectName: dedicatedCurrentConfig?.projectName,
        imageVersion: dedicatedCurrentConfig?.imageVersion,
        nodeType: dedicatedCurrentConfig?.nodeType,
      },
      providerConfig: {
        cloudProvider: capitalizeString(
          dedicatedCurrentConfig?.metadata.cloudProvider.label
        ),
        cpuCores: dedicatedCurrentConfig?.cpu,
        storageSize: convertToGigaByte(dedicatedCurrentConfig?.storageSize),
        memorySize: convertToGigaByte(dedicatedCurrentConfig?.memorySize),
      },
      launchConfig: {
        ami: dedicatedCurrentConfig?.ami,
        nodeKey: dedicatedCurrentConfig?.nodeKey,
        argumentRules: argumentRules,
      },
    };

    if (!dedicatedCurrentConfig?.argumentRules) {
      delete postData.launchConfig.argumentRules;
    }

    /* if (dedicatedCurrentConfig.monitoring) {
      postData.projectConfig.monitoringDashboard =
        dedicatedCurrentConfig.monitoringDashboard;
    } */

    console.log(postData);

    try {
      setStatus(NETWORK_STATE.LOADING);
      const response = await createDedicatedProject(postData);
      setStatus(NETWORK_STATE.SUCCESS);
      console.log(response);
      alertSuccess(response.data.message);
      setDedicatedCurrentConfig({});
      setDedicatedCurrentStep(0);
      navigate(appConfig.nav.routes.nodes);
    } catch (err) {
      console.log(err);
      alertError(err.response.data.message);
      setStatus(NETWORK_STATE.ERROR);
      if (err.response.status === 409) {
        alertError(err.response.data.message);
      } else {
        console.log(err);
      }
      //setDedicatedCurrentConfig({});
    }
  };

  return (
    <div className="step">
      <h2 className="self-start pb-5">Summary</h2>
      <div className="w-full flex flex-col gap-5">
        <DisplayBox
          title={"Network"}
          text={appConfig?.networks[dedicatedCurrentConfig?.networkId]?.name}
          full
        />
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-3 md:gap-5">
          <DisplayBox
            title={"Project Name"}
            text={dedicatedCurrentConfig?.projectName}
          />
          <DisplayBox
            title={"Node type"}
            text={dedicatedCurrentConfig?.nodeType}
          />
          <DisplayBox
            title={"Image Version"}
            text={dedicatedCurrentConfig?.imageVersion}
          />
          <DisplayBox
            title={"Cloud Provider"}
            text={dedicatedCurrentConfig?.metadata.cloudProvider.label}
          />
        </div>
        <div className="w-full flex flex-col md:grid grid-cols-1md: grid-flow-col gap-7 mt-5">
          <div className="w-auto h-[375px] bg-white rounded-[8px] border-[1px] border-[#CACACA] px-5 pt-3 pb-10 relative flex flex-col justify-between">
            <div>
              <p className="text-[12px] text-[#858585]">Node configuration</p>
              <p className="text-[13px] font-[600]">Default</p>
            </div>
            <div className="h-[250px] relative">
              <div className="absolute w-full border-b-[1px] border-dashed border-b-[#E3E3E3] top-[250px]"></div>
              <div className="absolute w-full border-b-[1px] border-dashed border-b-[#E3E3E3] top-[200px]"></div>
              <div className="absolute w-full border-b-[1px] border-dashed border-b-[#E3E3E3] top-[150px]"></div>
              <div className="absolute w-full border-b-[1px] border-dashed border-b-[#E3E3E3] top-[100px]"></div>
              <div className="absolute w-full border-b-[1px] border-dashed border-b-[#E3E3E3] top-[50px]"></div>
              <div className="absolute w-full border-b-[1px] border-dashed border-b-[#E3E3E3] top-[0px]"></div>
              <div className="absolute w-full bottom-0 h-[250px] flex justify-between items-end px-3">
                <div className="flex flex-col items-center justify-between">
                  <div className="h-[70px] w-[40px] sm:w-[50px] bg-blue rounded-[8px] text-center text-[12px] text-white pt-2">
                    <p>{getUnit(dedicatedCurrentConfig?.cpu, "")}</p>
                  </div>
                  <p className="text-[12px] md:text-[13px] font-[600] absolute -bottom-5">
                    CPU
                  </p>
                </div>
                <div className="flex flex-col items-center justify-between">
                  <div className="h-[120px] w-[40px] sm:w-[50px] bg-blue rounded-[8px] text-center text-[12px] text-white pt-2">
                    <p>
                      {getUnit(dedicatedCurrentConfig?.storageSize, "byte")}
                    </p>
                  </div>
                  <p className="text-[12px] md:text-[13px] font-[600] absolute -bottom-5">
                    Storage Size
                  </p>
                </div>
                <div className="flex flex-col items-center justify-between">
                  <div className="h-[200px] w-[40px] sm:w-[50px] bg-blue rounded-[8px] text-center text-[12px] text-white pt-2">
                    <p>{getUnit(dedicatedCurrentConfig?.memorySize, "byte")}</p>
                  </div>
                  <p className="text-[12px] md:text-[13px] font-[600] absolute -bottom-5">
                    Memory
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-auto flex flex-col gap-3">
            <DisplayBox title={"Ami"} text={dedicatedCurrentConfig?.ami} />
            <div className="w-auto flex flex-col gap-3">
              <div className="pricing-summary">
                <p>Pricing summary</p>
                <h1>${dedicatedCurrentConfig?.priceEstimate}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mt-5">
        <div className="app_form">
          <div className="w-full flex justify-center md:gridmd:grid-cols-2lg:grid-cols-3gap-5 mt-2">
            <div className="relative w-full">
              <div className="w-full min-h-[350px] pt-20 bg-[#292936] text-white text-[13px] px-5 py-7 rounded-[8px] overflow-scroll">
                {dedicatedCurrentConfig.argumentRules?.map((arg, index) => {
                  return <p key={index}>{arg}</p>;
                })}
              </div>
              <div className="w-full h-20 absolute top-0 left-0 bg-[#292936] rounded-t-[8px] flex justify-between px-5 pt-5">
                <div className="flex items-center justify-center p-2 h-10 bg-[#383847] text-[14px] text-white font-[500] rounded-full">
                  Argument rules
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        type="submit"
        className="app_button bottom-10 mt-20 full"
        onClick={() => {
          createProject();
        }}
        disabled={status === NETWORK_STATE.LOADING}
      >
        Deploy Project
      </button>
    </div>
  );
};

export default Step4;
