import { useEffect } from "react";
import { useStepper } from "../../../context/stepperContext";
import Networks from "../../api-services/Networks";

const Step1 = () => {
  const {
    chainCurrentStep,
    setChainCurrentStep,
    chainCurrentConfig,
    setChainCurrentConfig,
  } = useStepper();

  useEffect(() => {
    if (!chainCurrentConfig.networkId) {
      setChainCurrentConfig({});
    }
  }, []);

  return (
    <Networks
      connectAction={() => {
        setChainCurrentStep(chainCurrentStep + 1);
      }}
      type={"parachain"}
      config={chainCurrentConfig}
      setConfig={setChainCurrentConfig}
    />
  );
};

export default Step1;
