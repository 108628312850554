import { ReactComponent as DropDown } from "../../../assets/svg/icons/select-arrow.svg";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { ErrorText } from "../../../components/layouts/Auth";
import * as Yup from "yup";
import { useStepper } from "../../../context/stepperContext";
import { appConfig } from "../../../config";
import Pen from "../../../assets/img/pen.png";
import GrafanaIcon from "../../../assets/img/grafana-icon.png";
import BlockopsIcon from "../../../assets/img/tiny-logo.png";

const ArgumentInput = ({ keyName, value, header }) => {
  return (
    <div
      className={`w-full lg:w-[50%] flex items-center gap-5 text-[15px] ${
        header ? "text-[14px] mb-3" : "text-[16px]"
      }`}
    >
      <div className="w-[30%] text-right">
        {keyName === "Key name" ? keyName : `${keyName}=`}
      </div>
      <div className="w-[70%]">{value}</div>
    </div>
  );
};

const Step3 = () => {
  const [amiDropdown, setAmiDropdown] = useState(false);
  const {
    dedicatedCurrentStep,
    setDedicatedCurrentStep,
    dedicatedCurrentConfig,
    setDedicatedCurrentConfig,
  } = useStepper();
  const [networkId] = useState(dedicatedCurrentConfig.networkId);
  const [amis] = useState(appConfig.networks[networkId].parameters.v1.amis);
  const [nodeKey, setNodeKey] = useState(
    dedicatedCurrentConfig.nodeKey || "System Generated"
  );
  const [argumentRules, setArgumentRules] = useState("");
  const defaultVars = {
    "--rpc-cors": "all",
    "--ws-external": true,
    "--rpc-external": true,
    "--rpc-methods": "Unsafe",
    "--name": dedicatedCurrentConfig.projectName,
    extraArgs: "",
  };
  const [launchVars, setLaunchVars] = useState(
    dedicatedCurrentConfig.launchVars || defaultVars
  );

  const formik = useFormik({
    initialValues: {
      ami: dedicatedCurrentConfig.ami || "Ubuntu",
      argumentRules: dedicatedCurrentConfig.argumentRules || "",
      monitoring: dedicatedCurrentConfig.monitoring || false,
      monitoringDashboard:
        dedicatedCurrentConfig.monitoringDashboard || "grafana",
    },
    validationSchema: Yup.object({
      ami: Yup.string().required(<ErrorText text="Ami Version is required." />),
      monitoringDashboard: Yup.string().required(
        <ErrorText text="Select dashboard type." />
      ),
    }),
    onSubmit: async (values) => {
      let extraArgs = launchVars["extraArgs"]?.split("\n");
      if (!extraArgs) extraArgs = [];
      const argumentRules = [
        `--rpc-cors=${launchVars["--rpc-cors"]}`,
        `--ws-external=${launchVars["--ws-external"]}`,
        `--rpc-external=${launchVars["--rpc-external"]}`,
        `--rpc-methods=${launchVars["--rpc-methods"]}`,
        `--name=${launchVars["--name"]}`,
        ...extraArgs,
      ];
      setDedicatedCurrentConfig({
        ...dedicatedCurrentConfig,
        ami: values.ami,
        nodeKey: nodeKey,
        argumentRules: argumentRules,
        launchVars: launchVars,
        monitoring: values.monitoring,
        monitoringDashboard: values.monitoringDashboard,
      });

      setDedicatedCurrentStep(dedicatedCurrentStep + 1);
    },
  });

  const ErrorDisplay = ({ name }) => {
    return (
      <div
        className={`${
          formik.touched[name] && formik.errors[name]
            ? "inline-block"
            : "hidden"
        } app_form-error`}
      >
        {formik.touched[name] && formik.errors[name] && formik.errors[name]}
      </div>
    );
  };

  useEffect(() => {
    //const key = "khjgu3786g";
    const rules = "--rpc-external";
    //setNodeKey(key);
    setArgumentRules(rules);
  }, []);

  useEffect(() => {
    if (dedicatedCurrentConfig.monitoringDashboard) {
      handleRadioChange(
        dedicatedCurrentConfig.monitoringDashboard,
        "monitoring"
      );
    }
  }, []);

  const handleRadioChange = (value, type) => {
    if (type === "monitoring") {
      const radio1 = document.querySelector("#grafana-radio");
      const radio2 = document.querySelector("#blockops-radio");
      if (radio1 && radio2) {
        if (value === "grafana") {
          radio1.checked = true;
          radio2.checked = false;
        }
        if (value === "blockops") {
          radio2.checked = true;
          radio1.checked = false;
        }
        formik.setFieldValue("monitoringDashboard", value);
      }
    }
  };

  return (
    <div className="step">
      <h2 className="self-start pb-5">Launch Configuration</h2>
      <form onSubmit={formik.handleSubmit} className="w-full">
        <div className="w-full mb-5">
          <div className="app_form">
            <label htmlFor="projectNetwork">Select Ami</label>
            <button
              type="button"
              className="dropdown__button relative"
              onClick={() => {
                setAmiDropdown(!amiDropdown);
              }}
              onBlur={() => setAmiDropdown(false)}
            >
              <div>
                {formik.values.ami ? (
                  <div className="flex items-center justify-start gap-0 h-[45px]">
                    <p>Default</p>
                  </div>
                ) : (
                  "Default"
                )}
              </div>
              <DropDown />

              {amiDropdown && (
                <div className="dropdown__options">
                  {amis?.map((ami, index) => {
                    return (
                      <div
                        key={index}
                        className={`option ${
                          index === amis.length - 1
                            ? ""
                            : "option__apply-border"
                        } opacity-70 cursor-not-allowed`}
                        onClick={(e) => {
                          e.stopPropagation();
                          //formik.setFieldValue("ami", ami.label);
                          setAmiDropdown(false);
                        }}
                      >
                        {ami.label}
                      </div>
                    );
                  })}
                </div>
              )}
            </button>
            <ErrorDisplay name="ami" />
          </div>
        </div>

        <div className="w-full mb-5">
          {/* <div className="flex items-center mb-10 gap-5">
            <label className="switch">
              <input
                className="radio-slider-input"
                type="checkbox"
                onChange={(e) => {
                  formik.setFieldValue("monitoring", e.target.checked);
                }}
                defaultChecked={dedicatedCurrentConfig.monitoring}
              />
              <span className="radio-slider round"></span>
            </label>
            <p>Enable monitoring</p>
          </div> */}

          <div className="w-full lg:w-[60%]">
            {formik.values.monitoring && (
              <div>
                <div className="w-full mb-5">
                  <div className="app_form">
                    <div className="grid sm:grid-cols-2 grid-cols-1 gap-3 md:gap-8">
                      <button
                        type="button"
                        className={`
                          flex items-center gap-2 pl-5 pr-2 h-[50px] border-[1px] border-[#B8B8B8] rounded-[8px]
                          ${
                            formik.values.monitoringDashboard === "grafana"
                              ? "border-[#2180FF] bg-[#E9F1FF]"
                              : ""
                          }`}
                        onClick={() => {
                          handleRadioChange("grafana", "monitoring");
                        }}
                      >
                        <input
                          type={"radio"}
                          name={"grafana"}
                          id={`${"grafana"}-radio`}
                          value={"grafana"}
                        />
                        <div className={`text-[#717171]`}>
                          <div className="flex items-center gap-2 text-[14px] text-black font-[600]">
                            <img src={GrafanaIcon} alt={""} width={20} />
                            <p>Grafana dashboard</p>
                          </div>
                        </div>
                      </button>
                      <button
                        type="button"
                        className={`
                          flex items-center gap-2 pl-5 pr-2 h-[50px] border-[1px] border-[#B8B8B8] rounded-[8px]
                          ${
                            formik.values.monitoringDashboard === "blockops"
                              ? "border-[#2180FF] bg-[#E9F1FF]"
                              : ""
                          }`}
                        onClick={() => {
                          handleRadioChange("blockops", "monitoring");
                        }}
                      >
                        <input
                          type={"radio"}
                          name={"blockops"}
                          id={`${"blockops"}-radio`}
                          value={"blockops"}
                        />
                        <div className={`text-[#717171]`}>
                          <div className="flex items-center gap-2 text-[14px] text-black font-[600]">
                            <img src={BlockopsIcon} alt={""} width={20} />
                            <p>Blockops dashboard</p>
                          </div>
                        </div>
                      </button>
                    </div>
                    <ErrorDisplay name={"monitoringDashboard"} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="w-full mb-5 mt-5">
          <div className="app_form">
            <div className="w-full flex flex-wrapjustify-center md:gridmd:grid-cols-2lg:grid-cols-3gap-5 mt-2">
              <div className="relative w-full">
                <div className="w-full min-h-[350px] pt-20 bg-[#292936] text-white text-[13px] px-5 py-7 rounded-[8px] overflow-scroll">
                  <p>{`--base-path=/chain-data`}</p>
                  <p>{`--rpc-cors=${launchVars["--rpc-cors"]}`}</p>
                  <p>{`--port=<system generated>`}</p>
                  <p>{`--rpc-port=9933`}</p>
                  <p>{`--ws-port=9944`}</p>
                  {launchVars["--ws-external"] && <p>--ws-external</p>}
                  {launchVars["--rpc-external"] && <p>--rpc-external</p>}
                  <p>{`--node-key=<system generated>`}</p>
                  <p>{`--rpc-methods=${launchVars["--rpc-methods"]}`}</p>
                  <p>{`--name=${launchVars["--name"]}`}</p>
                  <p>{`--chain=${
                    appConfig.networks[dedicatedCurrentConfig.networkId].name
                  }`}</p>
                  {launchVars["extraArgs"]?.split("\n")?.map((arg, index) => {
                    return <p key={index}>{arg}</p>;
                  })}
                </div>
                <div className="w-full h-20 absolute top-0 left-0 bg-[#292936] rounded-t-[8px] flex justify-between px-5 pt-5">
                  <div className="flex items-center justify-center p-2 h-10 bg-[#383847] text-[14px] text-white font-[500] rounded-full">
                    Argument rules
                  </div>
                  <button
                    type="button"
                    className="flex items-center justify-center px-2 py-1 h-7 bg-[#383847] text-[10px] text-white font-[500] rounded-full"
                    onClick={() => {
                      setLaunchVars(defaultVars);
                      const radio1 = document.querySelector(`#--ws-external`);
                      const radio2 = document.querySelector(`#--rpc-external`);
                      if (radio1) radio1.checked = true;
                      if (radio2) radio2.checked = true;
                    }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            <ErrorDisplay name={"argumentRules"} />
          </div>
        </div>

        <div className="flex flex-col gap-2 app_form py-5">
          <ArgumentInput keyName={"Key name"} value={"Value"} header />
          {appConfig.argumentRules.map((arg, index) => {
            let argInput;
            if (arg.type === "string") {
              argInput = (
                <input
                  key={`${arg.key}`}
                  type="text"
                  id={`argrule-${index}`}
                  className={`border-[1px] border-[#B8B8B8] focus:outline-none ticket-input`}
                  defaultValue={launchVars[arg.key]}
                  onChange={(e) => {
                    const newVars = { ...launchVars };
                    newVars[arg.key] = e.target.value;
                    setLaunchVars(newVars);
                  }}
                />
              );
            } else {
              argInput = (
                <div className="flex items-center">
                  <label className="switch">
                    <input
                      id={`${arg.key}`}
                      key={`${arg.key}`}
                      className="radio-slider-input"
                      type="checkbox"
                      onChange={(e) => {
                        const newVars = { ...launchVars };
                        newVars[arg.key] = e.target.checked;
                        setLaunchVars(newVars);
                      }}
                      defaultChecked={launchVars[arg.key]}
                    />
                    <span className="radio-slider round"></span>
                  </label>
                </div>
              );
            }

            return (
              <div key={index}>
                <ArgumentInput keyName={arg.key} value={argInput} />
              </div>
            );
          })}
          <ArgumentInput
            keyName={"Extra Args"}
            value={
              <textarea
                className="w-full textarea-input outline-none"
                placeholder="--argName=config"
                defaultValue={launchVars["extraArgs"]}
                onChange={(e) => {
                  const newVars = { ...launchVars };
                  newVars["extraArgs"] = e.target.value;
                  setLaunchVars(newVars);
                }}
              />
            }
          />
        </div>

        {/* <div className="w-full mb-5">
          <div className="app_form">
            <label htmlFor="argumentRules">
              Argument Rules{" "}
              <span className="text-[12px] opacity-50">(optional)</span>
            </label>
            <div className="w-full bg-[#292936] text-white text-[13px] px-4 md:px-7 xl:px-10 py-7 rounded-[8px]">
              <p>--base-path=/chain-data </p>
              <p>--rpc-cors=all</p>
              <p>{"--port=<system generated>"}</p>
              <p>--rpc-port=9933</p>
              <p>--ws-port=9944</p>
              <p>--ws-external</p>
              <p>--rpc-external</p>
              <p>{"--node-key=<system generated>"}</p>
              <p>--rpc-methods=Unsafe</p>
              <p>{"--name=<system generated>"}</p>
              <p>{"--chain=<chain></chain>"}</p>
            </div> */}
        {/* <input
              id="argumentRules"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="e.g --rpc-unsafe"
              type="text"
              name="argumentRules"
              value={formik.values.argumentRules}
              className={`border-[1px] focus:outline-none ticket-input
                ${
                  !formik.errors?.argumentRules && formik.touched.argumentRules
                    ? "focus:border-blue"
                    : ""
                }
                ${
                  formik.errors?.argumentRules && formik.touched.argumentRules
                    ? "border-[#C63737] border-[1.5px]"
                    : "border-[rgba(18,18,18,0.3)]"
                }
              `}
            /> */}
        {/* <ErrorDisplay name={"argumentRules"} />
          </div>
        </div> */}

        <button type="submit" className="app_button bottom-10 mt-8 full">
          Continue
        </button>
      </form>
    </div>
  );
};

export default Step3;
