import React from "react";
import { motion } from "framer-motion";
import lens from "../../assets/svg/projects/lens.svg";
import _404 from "../../assets/svg/projects/404.svg";
import { Link } from "react-router-dom";
import { appConfig } from "../../config";

function NotFound() {
  return (
    <div className="bg-[#DCDCDC] flex justify-center items-start h-screen ">
      <div className="flex flex-col justify-start items-center mt-[97px]">
        <div className="relative">
          <img src={_404} alt="404 page" className="w-[350px] h-[350px]" />
          <motion.img
            src={lens}
            alt="404 page"
            className="absolute top-[80px] left-[80px] w-[100px] h-[100px]"
            animate={{
              y: ["0%", "100%", "100%", "0%", "0%"],
              x: ["0%", "0%", "100%", "100%", "0%"],
            }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              repeatType: "loop",
              repeatDelay: 0.5,
              yoyo: true,
            }}
          />
        </div>
        <div className="mt-[15px] flex flex-col justify-center items-center text-center">
          <h3 className="font-bold text-4xl text-[#13141B]">Page Not Found</h3>
          <p className="text-lg text-[#13141B]">
            We are sorry, but the page you were looking <br></br>for doesn’t
            exist.
          </p>
          <Link to={appConfig.nav.routes.dashboard}>
            <button className="mt-[41px] w-[234px] rounded-[16px] text-[#FFFFFF] h-[64px] bg-[#0060FF]">
              Go back to home
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
