import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { ErrorText } from "../../components/layouts/Auth";
import { ReactComponent as DropDown } from "../../assets/svg/icons/select-arrow.svg";
import { alertSuccess, NETWORK_STATE } from "../../utils";
import { sendMessage } from "../../services/SupportService";
import { useAuth } from "../../context/authContext";
import EmailSent from "../../assets/img/email_sent.png";
import { useNavigate } from "react-router-dom";
import { alertError } from "../../utils";

const SendMail = () => {
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [status, setStatus] = useState(NETWORK_STATE.IDLE);
  const auth = useAuth();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      category: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      category: Yup.string().required(
        <ErrorText text="Category is required." />
      ),
      message: Yup.string()
        .required(<ErrorText text="Message is required." />)
        .min(10, <ErrorText text="Minimum of 10 characters." />),
      email: Yup.string()
        .required(<ErrorText text="Email is required." />)
        .email(<ErrorText text="Invalid Email." />),
    }),
    onSubmit: async (values) => {
      const postData = {
        fullName: `${auth.user.firstName} ${auth.user.lastName}`,
        email: values.email,
        category: values.category,
        message: values.message,
      };

      setStatus(NETWORK_STATE.LOADING);
      try {
        const response = await sendMessage(postData);
        alertSuccess(response.data.message);
        setMessageSent(true);
        setStatus(NETWORK_STATE.SUCCESS);
      } catch (err) {
        if (err.response.status === 401) {
          navigate("/login");
          alertError("Session Expired. Please Login.");
        } else {
          alertError(
            err?.response?.data?.message ||
              "An error has occured. Please retry."
          );
          console.log(err);
          setStatus(NETWORK_STATE.ERROR);
          return;
        }
      }
    },
  });
  return (
    <div>
      {messageSent ? (
        <div className="w-[50%] mx-auto">
          <img
            src={EmailSent}
            alt="Email sent"
            width={150}
            className="mx-auto mt-20"
          />
          <h5 className="text-xl text-center md:text-[25px] mt-10 font-semibold">
            Your message has been recieved
          </h5>
          <p className="text-[14px] text-center text-[#13141B99] pb-5 mt-2">
            Please check for your mail for a response.
          </p>
          <button
            type="button"
            onClick={() => setMessageSent(false)}
            className="app_button full"
          >
            Done
          </button>
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full mb-5">
            <div className="app_form relative">
              <label htmlFor="Category">
                Category Issue <span>*</span>
              </label>
              <button
                type="button"
                className="dropdown__button"
                onClick={() => setDropDownOpen(!dropdownOpen)}
              >
                <p>{formik.values.category || "Select Category"}</p>
                <DropDown />
              </button>
              {dropdownOpen && (
                <div className="dropdown__options">
                  <button
                    type="button"
                    className="option"
                    onClick={() => {
                      formik.setFieldValue("category", "API Services");
                      setDropDownOpen(false);
                    }}
                  >
                    <p>API Services</p>
                  </button>
                  <button
                    type="button"
                    className="option"
                    onClick={() => {
                      formik.setFieldValue("category", "Data Services");
                      setDropDownOpen(false);
                    }}
                  >
                    <p>Data Services</p>
                  </button>
                  <button
                    type="button"
                    className="option cursor-not-allowedopacity-80"
                    onClick={() => {
                      formik.setFieldValue("category", "Dedicated Nodes");
                      setDropDownOpen(false);
                    }}
                  >
                    <p>Dedicated Nodes</p>
                  </button>
                  {/* <button
                    type="button"
                    className="option cursor-not-allowed opacity-80"
                  >
                    <p>Validator Nodes</p>
                  </button> */}
                </div>
              )}
              <div
                className={`${
                  formik.touched.category && formik.errors.category
                    ? "inline-block"
                    : "hidden"
                } app_form-error`}
              >
                {formik.touched.category &&
                  formik.errors.category &&
                  formik.errors.category}
              </div>
            </div>
          </div>

          <div className="w-full mb-5">
            <div className="app_form">
              <label htmlFor="email">
                Your email <span>*</span>
              </label>
              <input
                id="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter email address"
                type="text"
                name="email"
                className={`border-[1px] focus:outline-none ticket-input
                  ${
                    !formik.errors?.email && formik.touched.email
                      ? "focus:border-blue"
                      : ""
                  }
                  ${
                    formik.errors?.email && formik.touched.email
                      ? "border-[#C63737] border-[1.5px]"
                      : "border-[rgba(18,18,18,0.3)]"
                  }
                `}
              />
              <div
                className={`${
                  formik.touched.email && formik.errors.email
                    ? "inline-block"
                    : "hidden"
                } app_form-error`}
              >
                {formik.touched.email &&
                  formik.errors.email &&
                  formik.errors.email}
              </div>
            </div>
          </div>

          <div className="w-full mb-5">
            <div className="app_form">
              <label htmlFor="messsage">
                Your Message <span>*</span>
              </label>
              <textarea
                id="message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Describe the problem you need support with"
                type="text"
                name="message"
                className={`border-[1px] focus:outline-none textarea h-[200px]
                  ${
                    !formik.errors?.message && formik.touched.message
                      ? "focus:border-blue"
                      : ""
                  }
                  ${
                    formik.errors?.message && formik.touched.message
                      ? "border-[#C63737] border-[1.5px]"
                      : "border-[rgba(18,18,18,0.3)]"
                  }
                `}
              />
              <div
                className={`${
                  formik.touched.message && formik.errors.message
                    ? "inline-block"
                    : "hidden"
                } app_form-error`}
              >
                {formik.touched.message &&
                  formik.errors.message &&
                  formik.errors.message}
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="app_button full"
            disabled={status === NETWORK_STATE.LOADING}
          >
            {status === NETWORK_STATE.LOADING ? "Sending..." : "Send message"}
          </button>
        </form>
      )}
    </div>
  );
};

export default SendMail;
