import { useState, useContext, createContext, useEffect } from "react";
import { ApiPromise, WsProvider } from "@polkadot/api";

const RPCContext = createContext();

export const RPCProvider = ({ children }) => {
  const [kusama, setKusama] = useState({
    imageVersion: "0.9.36",
    blockHeight: "...",
  });

  const getKusama = async () => {
    // Construct
    //const wsProvider = new WsProvider(
    //"wss://kusama-public-rpc.blockops.network/ws"
    //);
    //const api = await ApiPromise.create({ provider: wsProvider });
    //await api.isReady;
    //let count = 0;
    // Set Image version
    /*const version = await api.rpc.system.version(); //.toHuman().split("-")[0];

    // Get block height in real time
    const unsubscribe = await api.rpc.chain.subscribeNewHeads((header) => {
      //const height = header?.number.toHuman();
      console.log(header, version);
      //setKusama({ imageVersion: version, blockHeight: height });

      if (++count === 50) {
        unsubscribe();
      }
    }); */
  };

  return (
    <RPCContext.Provider
      value={{
        RPCNetworks: {
          kusama: { data: kusama, getData: getKusama },
          polkadot: "polkadot",
        },
      }}
    >
      {children}
    </RPCContext.Provider>
  );
};

export const useRPC = () => useContext(RPCContext);
