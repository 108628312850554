import { ReactComponent as Search } from "../../assets/svg/icons/input-search.svg";
import { ReactComponent as ClearInput } from "../../assets/svg/icons/clear-input.svg";
import { useState } from "react";

const InputText = ({
  ref,
  value,
  setValue,
  errorText,
  onChange,
  placeholder,
  isInvalid,
  type,
  name,
  label,
  id,
  className,
  inputClassName,
}) => {
  const [inputFocus, setInputFocus] = useState(false);
  return (
    <div className="standard-input-1">
      <p className={`label ${isInvalid ? "text-red-error" : "text-[#686A6D]"}`}>
        {label}
      </p>
      <div
        className={`input-box ${className} ${
          !isInvalid &&
          (inputFocus
            ? "border-[2px] border-blue"
            : "border-[1px] border-[#8B8D90]")
        } ${isInvalid && "border-[2px] border-red-error"}
      ${!isInvalid && "hover:border-blue"}`}
      >
        {type === "search" && <Search />}
        <input
          ref={ref}
          name={name}
          id={id}
          type="text"
          placeholder={placeholder}
          className={`input ${inputClassName}`}
          value={value}
          onChange={onChange}
          onFocus={() => setInputFocus(true)}
          onBlur={() => setInputFocus(false)}
        />
        {value.length > 0 && type === "search" && (
          <button
            onClick={() => {
              setValue("");
            }}
          >
            <ClearInput className="self-end" />
          </button>
        )}
      </div>
      {errorText && (
        <div className="error">
          <div className="err-icon">!</div>
          <p>{errorText}</p>
        </div>
      )}
    </div>
  );
};

export default InputText;
