import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "../../context/authContext";
import { verifyCode } from "../../services/AuthService";
import { alertError, alertSuccess, NETWORK_STATE } from "../../utils";

export default function Verification() {
  const [status, setStatus] = useState(NETWORK_STATE.IDLE);
  const { search } = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();

  const verifyAuthCode = async () => {
    try {
      const code = new URLSearchParams(search).get("code");
      if (code) {
        setStatus(NETWORK_STATE.LOADING);
        const response = await verifyCode({ token: code });
        setStatus(NETWORK_STATE.SUCCESS);
        alertSuccess("Account verified successfully.");
        navigate("/login");
      }
    } catch (err) {
      alertError(err.response.data.message);
      setStatus(NETWORK_STATE.ERROR);
    }
  };
  useEffect(() => {
    verifyAuthCode();
  }, []);
  document.title = "Blockops | Verifying account";
  return (
    <div className="mt-[10%]">
      <div className="form-body">
        <div className="form">
          {status === NETWORK_STATE.LOADING ? (
            <div className="text-black text-center ">Verifying account...</div>
          ) : status === NETWORK_STATE.ERROR ? (
            <div>
              <h1 className="form-header">Account verification</h1>
              <p className="form-subtext">
                If you are seeing this page, your request might have failed.
              </p>
              <button
                type="button"
                onClick={() => navigate("/login")}
                className="app_button full"
              >
                login
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
