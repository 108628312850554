import axios from "../axios";

export const getAnalytics = (ordId) => {
  return axios.get("dashboard/analytics");
};

export const getNetworks = (ordId) => {
  return axios.get("projects/networks/", {});
};

export const getNetwork = (ordId, networkId) => {
  return axios.get(`projects/networks/${networkId}`);
};

// Methods for API Projects
export const getProjects = () => {
  return axios.get(`projects/apiservices`);
};

export const getProject = (projectId) => {
  return axios.get(`projects/apiservices/${projectId}`);
};

export const createProject = (payload) => {
  return axios.post("projects/apiservices/", payload);
};

export const deleteProject = (projectId) => {
  return axios.delete(`projects/apiservices/${projectId}`);
};

export const changeApiKey = (projectId, payload) => {
  return axios.patch(`projects/apiservices/${projectId}`, payload);
};

// Methods for Validator Node Projects
export const createValidatorProject = (payload) => {
  return axios.post("projects/validator-nodes/", payload);
};

// Methods for Dedicated nodes Service

export const createDedicatedProject = (payload) => {
  return axios.post("node", payload);
};

export const getDedicatedProjects = () => {
  return axios.get("node");
};

export const getDedicatedProject = (projectId) => {
  return axios.get(`node/${projectId}`);
};

export const pauseNode = (projectId) => {
  return axios.patch(`node/${projectId}`, { status: "pause" });
};

export const resumeNode = (projectId) => {
  return axios.patch(`node/${projectId}`, { status: "active" });
};

export const terminateNode = (projectId) => {
  return axios.patch(`node/${projectId}`, { status: "terminate" });
};

// Methods for Parachain nodes Service

export const createParachainProject = (payload) => {
  return axios.post("node/projectV2", payload);
};

export const getParachainProjects = () => {
  return axios.get(`node/projectV2`);
};

export const getParachainProject = (id) => {
  return axios.get(`node/projectV2/${id}`, id);
};

export const deleteParachainProject = (id, projectName) => {
  return axios.delete(`node/projectV2/${id}?projectName=${projectName}`, id);
};

export const getPendingParachainProjects = () => {
  return axios.get(`node/projectV2/pending`);
};
