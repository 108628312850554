import { appConfig } from "../../config";
import { ReactComponent as Composable } from "../../assets/svg/networks/Composable.svg";
import { ReactComponent as Flow } from "../../assets/svg/networks/Flow.svg";
import { ReactComponent as Kusama } from "../../assets/svg/networks/Kusama.svg";
import { ReactComponent as Moonriver } from "../../assets/svg/networks/Moonriver.svg";
import { ReactComponent as Polkadot } from "../../assets/svg/networks/Polkadot.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CopyEndpoints from "./Copy";
import Loader from "../../assets/img/loader.gif";
import Moonbeam from "../../assets/img/Moonbeam.png";
import Substrate from "../../assets/img/substrate-icon.png";

export const networkIcons = {
  Polkadot: <Polkadot />,
  Kusama: <Kusama />,
  Substrate: <img src={Substrate} alt="Moonbeam" width={92} />,
  Composable: <Composable />,
  Moonbeam: <img src={Moonbeam} alt="Moonbeam" width={92} />,
  Moonriver: <Moonriver />,
  Flow: <Flow />,
  Rococo: "",
  Subspace: "",
};

const goto = {
  "API Services": appConfig.nav.routes.api,
  "Validator Nodes": appConfig.nav.routes.validator,
  "Dedicated Nodes": appConfig.nav.routes.nodes,
  "Data Services": "/",
};

const Networks = ({
  connectAction,
  setModalNetwork,
  type,
  config,
  setConfig,
}) => {
  const [networks, setNetworks] = useState(null);
  const [projectType] = useState("API Services");
  const navigate = useNavigate();

  const networkDescriptions = {
    Polkadot:
      "Using a sharded architecture, Polkadot can process transactions and data in parallel.",
    Kusama:
      "A scalable and interoperable network for developers to build decentralized applications (dApps).",
    Composable:
      "Easily assemble different components or modules to create customized blockchain networks.",
    Moonbeam: "An Ethereum-compatible smart contract parachain on Polkadot.",
    Moonriver:
      "Built on the Kusama network, designed for high-speed and low-cost transactions.",
    Flow: "Flow supports multi-role smart contracts to define complex business logic for dApps.",
    Rococo:
      "Utilizing a parachain architecture that enables seamless integration with other blockchain networks within the Polkadot ecosystem.",
    Subspace:
      "A unique sharding mechanism to increase output and reduce latency, making it ideal for decentralized applications.",
  };

  const fetchNetworks = async () => {
    const networksArray = Object.keys(appConfig.networks).map((key) => {
      if (key !== "3") {
        return appConfig.networks[key];
      } else if (key === "3" && type === "parachain") {
        return appConfig.networks[key];
      }
    });
    if (type !== "parachain") {
      networksArray.pop();
      setNetworks(networksArray);
    } else {
      const parachainArray = networksArray.filter((network) => {
        return network.id !== 2;
      });
      setNetworks(parachainArray);
    }
  };

  useEffect(() => {
    fetchNetworks();
  }, []);

  return (
    <div className="w-full relative">
      {networks ? (
        type === "api" ? (
          <div className="api-networks">
            {networks.map((network, index) => {
              return (
                <div key={index} className="api-networks__card gap-6 group">
                  <div className="api-networks__card-header">
                    <div>{networkIcons[network.name]}</div>
                    <h5>{network.name}</h5>
                  </div>
                  <div className="flex flex-col gap-6">
                    <CopyEndpoints
                      query={network}
                      https={network.https}
                      tps="https://......"
                      copy={false}
                    >
                      {network.name}
                    </CopyEndpoints>
                    <CopyEndpoints
                      query={network}
                      websocket={network.websocket}
                      wss="wss://......"
                      copy={false}
                    >
                      Websocket Polkadot
                    </CopyEndpoints>
                  </div>

                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        connectAction();
                        setModalNetwork(network.name);
                      }}
                      className={`app_btn-responsive full group-hover:bg-blue`}
                    >
                      connect
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="api-networks">
            {networks.map((network, index) => {
              return (
                <div key={index} className={`api-networks__card group`}>
                  <div className="network-icon">
                    {networkIcons[network.name]}
                  </div>
                  <h2>{network.name}</h2>
                  <div className="description-text">
                    <p>
                      {network.description || networkDescriptions[network.name]}
                    </p>
                  </div>
                  <button
                    className="group-hover:bg-blue app_btn-responsive full"
                    onClick={() => {
                      if (type === "dashboard") {
                        navigate(goto[projectType], {
                          state: {
                            modalOpen: true,
                            network: network.name,
                          },
                        });
                      } else if (type === "dedicated" || type === "parachain") {
                        connectAction();
                        if (config.networkId === network.id) {
                          setConfig({ ...config, networkId: network.id });
                        } else {
                          setConfig({ networkId: network.id });
                        }
                      }
                    }}
                  >
                    Connect
                  </button>
                </div>
              );
            })}
          </div>
        )
      ) : (
        <div className="relative h-[200px] w-full">
          <img
            src={Loader}
            alt="loading networks..."
            className="absolute left-[50%] top-[50%]"
          />
        </div>
      )}
    </div>
  );
};

export default Networks;
