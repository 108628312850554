export const openTickets = [
  /* {
    id: '1000012',
    category: 'API Services',
    date: '24/04/2023',
    time: '11:23am',
    status: 'open',
    messages: [
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
    ]
  },
  {
    id: '1000013',
    category: 'Validator Nodes',
    date: '24/04/2023',
    time: '11:23am',
    status: 'open',
    messages: [
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
    ]
  },
  {
    id: '1000014',
    category: 'Snapshots',
    date: '24/04/2023',
    time: '11:23am',
    status: 'open',
    messages: [
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
    ]
  },
  {
    id: '1000015',
    category: 'Validator Nodes',
    date: '24/04/2023',
    time: '11:23am',
    status: 'open',
    messages: [
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
    ]
  },
  {
    id: '1000016',
    category: 'Dedicated Nodes',
    date: '24/04/2023',
    time: '11:23am',
    status: 'open',
    messages: [
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
    ]
  },
  {
    id: '1000017',
    category: 'Dedicated Nodes',
    date: '24/04/2023',
    time: '11:23am',
    status: 'open',
    messages: [
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
    ]
  },
  {
    id: '1000018',
    category: 'Dedicated Nodes',
    date: '24/04/2023',
    time: '11:23am',
    status: 'open',
    messages: [
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
    ]
  },
  {
    id: '1000019',
    category: 'Dedicated Nodes',
    date: '24/04/2023',
    time: '11:23am',
    status: 'open',
    messages: [
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
    ]
  }, */
]

export const closedTickets = [
  /* {
    id: '2000012',
    category: 'API Services',
    date: '24/04/2023',
    time: '11:23am',
    status: 'closed',
    messages: [
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
    ]
  },
  {
    id: '2000013',
    category: 'Validator Nodes',
    date: '24/04/2023',
    time: '11:23am',
    status: 'closed',
    messages: [
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
    ]
  },
  {
    id: '2000014',
    category: 'Snapshots',
    date: '24/04/2023',
    time: '11:23am',
    status: 'closed',
    messages: [
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
    ]
  },
  {
    id: '2000015',
    category: 'Validator Nodes',
    date: '24/04/2023',
    time: '11:23am',
    status: 'closed',
    messages: [
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
    ]
  },
  {
    id: '2000016',
    category: 'Dedicated Nodes',
    date: '24/04/2023',
    time: '11:23am',
    status: 'closed',
    messages: [
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
    ]
  },
  {
    id: '2000017',
    category: 'Dedicated Nodes',
    date: '24/04/2023',
    time: '11:23am',
    status: 'closed',
    messages: [
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
    ]
  },
  {
    id: '2000018',
    category: 'Dedicated Nodes',
    date: '24/04/2023',
    time: '11:23am',
    status: 'closed',
    messages: [
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
    ]
  },
  {
    id: '2000019',
    category: 'Dedicated Nodes',
    date: '24/04/2023',
    time: '11:23am',
    status: 'closed',
    messages: [
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
      { date: '', sender: '', message: '', },
    ]
  }, */
]
