import { ReactComponent as Copy } from "../../assets/svg/icons/content_copy.svg";
import { ReactComponent as DropDown } from "../../assets/svg/icons/arrow-down-1.svg";
import { useAuth } from "../../context/authContext";
import { useEffect, useState } from "react";
import { useHeader } from "../../context/headerContext";
import { useRPC } from "../../context/RPCContext";
import { ValidatedNetworks } from "./ValidatedNetworks";

const ValidatorNodes = () => {
  const auth = useAuth();
  const { minimizeNav } = useHeader();
  const [networkDropdown, setNetworkDropdown] = useState(false);
  const [activeNetwork, setActiveNetwork] = useState(ValidatedNetworks[0]);
  const [isCopied, setIsCopied] = useState({ copy: false, id: "" });
  const { RPCNetworks } = useRPC();

  const handleCopy = (id) => {
    setIsCopied({ copy: true, id: id });
    setTimeout(() => {
      setIsCopied({ copy: false, id: "" });
    }, 2000);
  };

  useEffect(() => {
    auth.setHeaderText(["Validator Nodes"]);
  }, []);

  useEffect(() => {
    const { getData } =
      RPCNetworks && RPCNetworks[activeNetwork.name.toLowerCase()];
    getData();
  }, [activeNetwork]);

  document.title = "Blockops | Validator Nodes";

  return (
    <div className="app-home validator">
      <div className="app-home__heading">
        <h2>Select Network</h2>
      </div>
      <div className={`app-home__hero shadow`}>
        <button
          className="network-button relative"
          onClick={() => {
            setNetworkDropdown(!networkDropdown);
          }}
          onBlur={() => setNetworkDropdown(false)}
        >
          <div className="validator__hero-btn">
            <div>{activeNetwork.icon}</div>
            <p className="cut-text">{activeNetwork?.name}</p>
          </div>
          <DropDown fill="white" width={24} />

          {networkDropdown && (
            <div className="standard-dropdown-1 button">
              <div className="button__options">
                {ValidatedNetworks.map((network, index) => {
                  return (
                    <div
                      key={index}
                      className="button__options-item"
                      onClick={() => {
                        setActiveNetwork(network);
                      }}
                    >
                      <div>{network?.icon}</div>
                      <p className="cut-text">{network?.name}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </button>

        <div className="validator__hero-content">
          <div className="w-[60%] flex flex-col gap-1">
            <p className="text-14">Node Address</p>
            <p className="text-20 font-[500] cut-text">
              {activeNetwork?.nodeAddresses[0]}
            </p>
          </div>
          <div className="w-[35%]">
            <p className="text-14">Stake Amount</p>
            <p>{activeNetwork?.stakeAmount}</p>
          </div>
        </div>
      </div>

      <div
        className={`${
          minimizeNav ? "min-h-[250px]" : "min-h-[200px]"
        } validator__main`}
      >
        <div className="validator__main-boxesContainer">
          <div className="validator__main-box">
            <p className="text-[#686A6D] text-14">Latest block height</p>
            <p className="text-24 font-[600] text-black">
              {RPCNetworks &&
                RPCNetworks[activeNetwork.name.toLowerCase()].data.blockHeight}
            </p>
          </div>
          <div className="validator__main-box">
            <p className="text-[#686A6D] text-14">Image version</p>
            <p className="text-24 font-[600] text-black">
              {RPCNetworks &&
                RPCNetworks[activeNetwork.name.toLowerCase()].data.imageVersion}
            </p>
          </div>
        </div>
        <div className="validator__main-addresses">
          <h1 className="font-[600] text-24 text-black">Addresses</h1>
          <div className="">
            {activeNetwork?.nodeAddresses.map((address, index) => {
              return (
                <div key={index} className="address">
                  <p className="title">Address {index + 1}</p>
                  <div className="addr-box">
                    <p className="addr cut-text">{address}</p>
                    <button
                      type="button"
                      className="relative ml-5"
                      onClick={() => {
                        navigator.clipboard.writeText(address);
                        handleCopy(address);
                      }}
                    >
                      <Copy />
                      {isCopied.id === address && (
                        <div className="copied-popup">Copied!</div>
                      )}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidatorNodes;
