import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../context/authContext";
import { ReactComponent as OpenEyeIcon } from "../../assets/svg/icons/open_eye.svg";
import { ReactComponent as CloseEyeIcon } from "../../assets/svg/icons/close_eye.svg";
import { signin } from "../../services/AuthService";
import { alertError, alertSuccess, NETWORK_STATE } from "../../utils";
import { ErrorText } from "../../components/layouts/Auth";
import { appConfig } from "../../config";

export default function AppLogin() {
  const [showPassword, setShowPassword] = useState(false);
  const [status, setStatus] = useState(NETWORK_STATE.IDLE);
  const [checkingAuth, setCheckingAuth] = useState(true);
  const navigate = useNavigate();

  const auth = useAuth();
  const handleLogin = async (values, methods) => {
    try {
      setStatus(NETWORK_STATE.LOADING);
      const response = await signin(values);
      setStatus(NETWORK_STATE.SUCCESS);
      if (response.data.success && !response.data.data) {
        alertError(response.data.message);
        return;
      }
      auth.initUser(response.data.data);
      navigate(appConfig.nav.routes.dashboard);
      alertSuccess("Logged in successfully");
    } catch (err) {
      alertError(
        err?.response?.data?.message || "Something went wrong, try again"
      );
      setStatus(NETWORK_STATE.ERROR);
    }
  };

  /* useEffect(() => {
    if (auth.user) {
      navigate(appConfig.nav.routes.dashboard);
    } else {
      setCheckingAuth(false);
    }
  }, []); */

  document.title = "Blockops | Login";
  return (
    <div className="form-body">
      <div className="form">
        <h6 className="form-header">Welcome back.</h6>
        <p className="form-subtext">Welcome back! Please enter your details</p>
        <div className="w-full">
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email(<ErrorText text="Invalid email address" />)
                .required(<ErrorText text="Email is required" />),
              password: Yup.string()
                .min(3, <ErrorText text="Minimum of 3" />)
                .required(<ErrorText text="Password is required" />),
            })}
            onSubmit={(values, methods) => {
              handleLogin(values, methods);
            }}
          >
            {({ isSubmitting, errors, ...rest }) => (
              <Form>
                <div className="mb-6">
                  <div className="app_form">
                    <label htmlFor="name">
                      Email address <span>*</span>
                    </label>
                    <Field
                      placeholder="Enter your email address"
                      type="email"
                      name="email"
                      className={`border-[1px] focus:outline-none signup-input
                        ${
                          !errors?.email && rest.touched?.email
                            ? "focus:border-blue"
                            : ""
                        }
                        ${
                          errors?.email && rest.touched?.email
                            ? "border-[#C63737] border-[1.5px]"
                            : "border-[rgba(18,18,18,0.3)]"
                        }
                      `}
                    />
                    <ErrorMessage
                      name="email"
                      component="span"
                      className="app_form-error"
                    />
                  </div>
                </div>
                <div className="mb-6">
                  <div className="app_form">
                    <label htmlFor="name">
                      Password <span>*</span>
                    </label>
                    <div className="app_form-password">
                      <Field
                        placeholder="Enter password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        className={`border-[1px] focus:outline-none signup-input
                          ${
                            !errors?.password && rest.touched?.password
                              ? "focus:border-blue"
                              : ""
                          }
                          ${
                            errors?.password && rest.touched?.password
                              ? "border-[#C63737] border-[1.5px]"
                              : "border-[rgba(18,18,18,0.3)]"
                          }
                        `}
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <CloseEyeIcon /> : <OpenEyeIcon />}
                      </button>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="span"
                      className="app_form-error"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={status == NETWORK_STATE.LOADING}
                  className="app_button full"
                >
                  {status == NETWORK_STATE.LOADING ? "Logging in..." : " Login"}
                </button>
              </Form>
            )}
          </Formik>{" "}
        </div>
      </div>

      <div>
        <p className="bottom-text">
          New to Blockops?{" "}
          <Link to="/signup" className="" href="#">
            <span>Sign up</span>
          </Link>{" "}
        </p>

        <div className="bottom-text">
          <Link to="/forgot-password" href="#">
            <span className="mt-10">Forgot your password?</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
