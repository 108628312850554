import { NavLink } from "react-router-dom";
import { ReactComponent as Dashboard } from "../../assets/svg/nav/dashboard_fill.svg";
import { ReactComponent as DataServices } from "../../assets/svg/nav/data-svc.svg";
import { ReactComponent as DedicatedIconFill } from "../../assets/svg/nav/dedicated-fill.svg";
import { ReactComponent as DedicatedIconVoid } from "../../assets/svg/nav/dedicated-void.svg";
import { ReactComponent as Documentation } from "../../assets/svg/nav/docs.svg";
import { ReactComponent as Logout } from "../../assets/svg/nav/logout.svg";
import { ReactComponent as PolygonPointer } from "../../assets/svg/nav/sidenav-polygon.svg";
import { ReactComponent as Service } from "../../assets/svg/nav/service_fill.svg";
import { ReactComponent as Settings } from "../../assets/svg/nav/settings_fill.svg";
import { ReactComponent as SupportFill } from "../../assets/svg/nav/help_fill.svg";
import { ReactComponent as SupportVoid } from "../../assets/svg/nav/support-void.svg";
import { ReactComponent as ValidatorIconFill } from "../../assets/svg/nav/validator-fill.svg";
import { ReactComponent as ValidatorIconVoid } from "../../assets/svg/nav/validator-void.svg";
import { useAuth } from "../../context/authContext";
import { useState, useEffect } from "react";
import Menu from "../../assets/img/menu.png";
import { appConfig } from "../../config";

export default function Mininav({ resizeNav }) {
  const { logout } = useAuth();
  const [currentPath, setCurrentPath] = useState("dashboard");

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, []);

  return (
    <nav className={`min-nav`}>
      <div className="min-nav__header">
        <button
          onClick={() => {
            resizeNav();
          }}
        >
          <img src={Menu} alt="" width={24} />
        </button>
      </div>

      <div className="min-nav__cont">
        <div className="min-nav__cont-top">
          <div className="h-[42px] flex items-center justify-center group">
            <NavLink
              className="min_link "
              to={appConfig.nav.routes.dashboard}
              onClick={() => {
                setCurrentPath(appConfig.nav.routes.dashboard);
              }}
            >
              <Dashboard width={18} />
            </NavLink>
            <div className="group-hover:flex nav-name">
              <PolygonPointer />
              <span>Dashboard</span>
            </div>
          </div>

          <div className="h-[42px] flex items-center justify-center group">
            <NavLink
              className="min_link"
              to={appConfig.nav.routes.api}
              onClick={() => {
                setCurrentPath(appConfig.nav.routes.api);
              }}
            >
              <Service width={18} />
            </NavLink>
            <div className="group-hover:flex nav-name">
              <PolygonPointer />
              <span className="group-hover:flex">API Services</span>
            </div>
          </div>

          <div className="h-[42px] flex items-center justify-center group">
            <NavLink
              className="min_link"
              to={appConfig.nav.routes.validator}
              onClick={() => {
                setCurrentPath(appConfig.nav.routes.validator);
              }}
            >
              {currentPath.startsWith(appConfig.nav.routes.validator) && (
                <ValidatorIconFill />
              )}
              {!currentPath.startsWith(appConfig.nav.routes.validator) && (
                <ValidatorIconVoid />
              )}
            </NavLink>
            <div className="group-hover:flex nav-name">
              <PolygonPointer />
              <span className="group-hover:flex">Validator Nodes</span>
            </div>
          </div>

          <div className="h-[42px] flex items-center justify-center group">
            <NavLink
              className="min_link"
              to={appConfig.nav.routes.nodes}
              onClick={() => {
                setCurrentPath(appConfig.nav.routes.nodes);
              }}
            >
              {currentPath.startsWith(appConfig.nav.routes.nodes) && (
                <DedicatedIconFill />
              )}
              {!currentPath.startsWith(appConfig.nav.routes.nodes) && (
                <DedicatedIconVoid />
              )}
            </NavLink>
            <div className="group-hover:flex nav-name">
              <PolygonPointer />
              <span className="group-hover:flex">Dedicated Nodes</span>
            </div>
          </div>

          <a
            href={appConfig.externalLinks.dataServices}
            target={"_blank"}
            className="h-[42px] flex items-center justify-center group"
          >
            <div className="group min_link">
              <DataServices width={18} />
            </div>
            <div className="group-hover:flex nav-name">
              <PolygonPointer />
              <span className="group-hover:flex">Data Services</span>
            </div>
          </a>
        </div>

        <div className="min-nav__cont-bottom">
          <div className="min-nav__cont-bottom-links">
            <div className="h-[42px] flex items-center justify-center group">
              <a
                className="min_link"
                href={appConfig.externalLinks.docs}
                target={"_blank"}
                rel="noreferrer"
              >
                <Documentation width={18} />
              </a>
              <div className="group-hover:flex nav-name">
                <PolygonPointer />
                <span className="group-hover:flex">Documentation</span>
              </div>
            </div>

            <div className="h-[42px] flex items-center justify-center group">
              <NavLink
                className="min_link"
                to={appConfig.nav.routes.settings}
                onClick={() => {
                  setCurrentPath(appConfig.nav.routes.settings);
                }}
              >
                <Settings width={18} />
              </NavLink>
              <div className="group-hover:flex nav-name">
                <PolygonPointer />
                <span className="group-hover:flex">settings</span>
              </div>
            </div>

            <div className="h-[42px] flex items-center justify-center group">
              <NavLink
                className="min_link"
                to={appConfig.nav.routes.support}
                onClick={() => {
                  setCurrentPath(appConfig.nav.routes.support);
                }}
              >
                {currentPath.startsWith(appConfig.nav.routes.support) && (
                  <SupportFill width={18} />
                )}
                {!currentPath.startsWith(appConfig.nav.routes.support) && (
                  <SupportVoid width={18} />
                )}
              </NavLink>
              <div className="group-hover:flex nav-name">
                <PolygonPointer />
                <span className="group-hover:flex">Support</span>
              </div>
            </div>

            <div className="h-[42px] flex items-center justify-center group">
              <div className="min_link cursor-pointer" onClick={() => logout()}>
                <Logout width={18} />
              </div>
              <div className="group-hover:flex nav-name">
                <PolygonPointer />
                <span className="group-hover:flex">Logout</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
