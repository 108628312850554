import { useEffect, useState } from "react";
import { NETWORK_STATE, alertError } from "../../../utils";
import Loader from "../../../assets/img/loader.gif";
import { ReactComponent as DedicatedIcon } from "../../../assets/svg/icons/dedicated.svg";
import { ReactComponent as Plus } from "../../../assets/svg/icons/plus.svg";
import { useAuth } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";
import ProjectRow from "../../../components/nodes/parachain-nodes/ProjectRow";
import { getParachainProjects } from "../../../services/ProjectsService";
import { parachainProjects } from "../../../components/nodes/DummyData";
import { useLiveUpdates } from "../../../context/liveUpdates";

const ParachainNodes = ({ setDeployedParaProjects }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [activeProjects, setActiveProjects] = useState([]);
  const [status, setStatus] = useState(NETWORK_STATE.LOADING);
  const { lastEvent, reloadParachainList, setReloadParachainList } =
    useLiveUpdates();

  const fetchParachainProjects = async () => {
    setStatus(NETWORK_STATE.LOADING);
    try {
      const response = await getParachainProjects();
      const userNodes = response.data?.data;
      //console.log(userNodes);
      //const userNodes = parachainProjects;
      const userProjects = [];
      userNodes.length > 0 &&
        userNodes.forEach((nodeArray) => {
          userProjects.push(nodeArray[0]);
        });
      setDeployedParaProjects(userProjects);
      userProjects && setActiveProjects(userProjects);
      //setActiveProjects([]);
      setStatus(NETWORK_STATE.SUCCESS);
    } catch (err) {
      console.log(err);
      if (err.response.status === 401) {
        alertError(err.response.data.message);
        auth.logout();
      }
      if (err.response.status === 404) {
        setActiveProjects([]);
      }
      setStatus(NETWORK_STATE.ERROR);
    }
    setReloadParachainList(false);
  };

  useEffect(() => {
    reloadParachainList &&
      setTimeout(() => {
        fetchParachainProjects();
      }, 1000);
  }, [reloadParachainList]);

  useEffect(() => {
    fetchParachainProjects();
  }, []);

  /* useEffect(() => {
    console.log(lastMessage);
    console.log(lastJsonMessage);
  }, [lastMessage, lastJsonMessage]); */

  return (
    <div className="dedicated">
      {/* <div className="dedicated__page">
        <div className="flex flex-col gap-5 md:flex-row items-start md:items-center justify-between mb-2 mt-10 pb-7">
          <h2>Parachain Projects ({activeProjects.length})</h2>
          <button
            type="button"
            className="app_button self-end"
            onClick={() => navigate("/parachain-nodes/create")}
          >
            <Plus />
            Create Project
          </button>
        </div>
      </div> */}
      {status === NETWORK_STATE.LOADING ? (
        <img src={Loader} alt="Loading..." className="self-center py-20" />
      ) : NETWORK_STATE.SUCCESS && activeProjects.length > 0 ? (
        <div className="dedicated__page">
          <div className="flex items-start pb-1">
            <h2>Parachain ({activeProjects.length})</h2>
          </div>
          <div className="flex flex-col gap-2">
            <ProjectRow header />
            <div className="flex flex-col gap-3">
              {activeProjects.map((project, index) => {
                return (
                  <div key={index}>
                    <ProjectRow project={project} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ParachainNodes;
