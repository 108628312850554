export const projects = [
  {
    id: 1,
    networkId: 2,
    projectName: "Test-1",
    nodeType: "Collator Node",
    imageVersion: "Polkadot-v0.9.31",
    cloudProvider: "gcp",
    cpu: 4,
    storageSize: 1000000000000,
    memorySize: 16000000000,
    priceEstimate: "150,000",
    ami: "blockops-v0.1.0",
    nodeKey: "khjgu3786g",
    argumentRules: "--rpc-external",
    status: "active",
    createdAt: new Date(),
  },
  {
    id: 2,
    networkId: 1,
    projectName: "Test-2",
    nodeType: "Collator (Archival) Node",
    imageVersion: "polkadot-v0.9.31",
    cloudProvider: "aws",
    cpu: "6",
    storageSize: "1800000000000",
    memorySize: "32000000000",
    priceEstimate: "150,000",
    ami: "blockops-v0.1.0",
    nodeKey: "khjgu3786g",
    argumentRules: "--rpc-external",
    status: "paused",
    createdAt: new Date(),
  },
  {
    id: 3,
    networkId: 2,
    projectName: "Test-1",
    nodeType: "Collator Node",
    imageVersion: "Polkadot-v0.9.31",
    cloudProvider: "gcp",
    cpu: 4,
    storageSize: 1000000000000,
    memorySize: 16000000000,
    priceEstimate: "150,000",
    ami: "blockops-v0.1.0",
    nodeKey: "khjgu3786g",
    argumentRules: "--rpc-external",
    status: "terminated",
    createdAt: new Date(),
  },
];

export const parachainProjects = [
  [
    {
      id: 3,
      parachainId: "237865221",
      networkId: 2,
      networkName: "Polkadot",
      projectName: "Test-1",
      nodeType: "collator",
      imageVersion: "Polkadot-v0.9.31",
      cloudProvider: "gcp",
      cpu: 4,
      storageSize: 1000000000000,
      memorySize: 16000000000,
      priceEstimate: "150,000",
      ami: "blockops-v0.1.0",
      nodeKey: "khjgu3786g",
      argumentRules: "--rpc-external",
      status: "active",
      createdAt: new Date(),
      referenceId: 12252576,
      apiKey: "23hglkhwegiuongknh2o3yubbgfb2kug2",
    },
    {
      id: 3,
      parachainId: "237865221",
      networkId: 2,
      networkName: "Polkadot",
      projectName: "Test-1",
      nodeType: "collator",
      imageVersion: "Polkadot-v0.9.31",
      cloudProvider: "gcp",
      cpu: 4,
      storageSize: 1000000000000,
      memorySize: 16000000000,
      priceEstimate: "150,000",
      ami: "blockops-v0.1.0",
      nodeKey: "khjgu3786g",
      argumentRules: "--rpc-external",
      status: "active",
      createdAt: new Date(),
      referenceId: 12669891,
      apiKey: "jyfwevbot7263tuj26t3ujv2tyfitfu63b2j",
    },
  ],
];
