import { useState } from "react";
import InputText from "../../components/input-text/InputText";
import PageSlider from "../../components/page-slider/PageSlider";
import ProjectRow from "../../components/api-services/ProjectRow";

const ProjectsList = ({
  projects,
  deleteProject,
  totalPages,
  currentPage,
  setCurrentPage,
  filterProjects,
  searchText,
  setSearchText,
}) => {
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  return (
    <div className="projects">
      <div className="projects__display">
        <div className="w-full">
          <div className="projects__display-header">
            <h3 className="self-start">Current Projects</h3>
            <InputText
              value={searchText}
              setValue={setSearchText}
              onChange={filterProjects}
              placeholder={"Search Projects"}
              type="search"
              className="w-[326px]"
              inputClassName="w-[210px]"
            />
          </div>

          <div className="mt-6">
            <ProjectRow header projectHeader />
            {projects.map((project, index) => {
              return (
                <div key={index}>
                  <ProjectRow
                    project={project}
                    selectItem={setSelectedProjects}
                    allSelectedItems={selectedProjects}
                    setSelectedOption={setSelectedOption}
                    selectedOption={selectedOption}
                    deleteProject={deleteProject}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="projects__display-footer">
          <PageSlider
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectsList;
