import { ReactComponent as ApiPlug } from "../../assets/svg/icons/api-plug.svg";
import { ReactComponent as Wave } from "../../assets/svg/icons/wave.svg";
import { useAuth } from "../../context/authContext";
import { useEffect } from "react";
import { useHeader } from "../../context/headerContext";
import Networks from "../../components/api-services/Networks";

function Home() {
  const auth = useAuth();
  const { minimizeNav } = useHeader();

  useEffect(() => {
    auth.setHeaderText(["Dashboard"]);
  }, []);

  document.title = "Blockops | Dashboard";

  return (
    <div className="app-home">
      <div className="app-home__heading">
        <h2>Welcome {auth.user.firstName}</h2>
        <Wave />
      </div>
      <div className={`${minimizeNav ? "app-home__hero" : "app-home__hero"}`}>
        <div
          className={`${
            minimizeNav ? "app-home__hero-header" : "app-home__hero-header"
          }`}
        >
          <ApiPlug />
          <h2>Api Service</h2>
        </div>
        <p className="text">
          Gain access and interact with blockchain networks and their data to
          create blockchain products quickly and efficiently. Blockops APIs
          provide a standardized interface for developers to interact with these
          nodes and access the network's data.
        </p>
        <button className="start-button">
          <a href="#Networks">Start Project</a>
        </button>
        <span id="Networks"></span>
      </div>
      <div className="app-home__networks">
        <div className="w-full">
          <Networks type="dashboard" />
        </div>
      </div>
    </div>
  );
}

export default Home;
