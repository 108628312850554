import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import Mininav from "./Mininav";
import { Fullnav } from "./Fullnav";
import { useHeader } from "../../context/headerContext";

export default function Sidenav() {
  const renderCount = useRef(1);
  const [userSettings, setUserSettings] = useState(
    JSON.parse(localStorage.getItem("userSettings"))
  );
  const minimizeTL = gsap.timeline({
    defaults: { ease: "linear", duration: 0.3 },
  });
  const mobileTL = gsap.timeline({
    defaults: { ease: "linear", duration: 0.3 },
  });
  const mediaQuery = gsap.matchMedia();
  const { minimizeNav, setMinimizeNav } = useHeader();

  // Function to resize the nav for desktop
  const resizeNav = () => {
    renderCount.current = renderCount.current + 1;
    const newDashboardSettings = {
      navBar: { minimize: !userSettings?.dashboard.navBar.minimize },
    };
    if (!userSettings?.dashboard.navBar.minimize) {
      localStorage.setItem(
        "userSettings",
        JSON.stringify({
          ...userSettings,
          dashboard: { ...newDashboardSettings },
        })
      );
      setUserSettings({
        ...userSettings,
        dashboard: { ...newDashboardSettings },
      });
      setMinimizeNav(true);
      minimizeTL
        .fromTo(
          ".min-nav",
          {
            opacity: 1,
            xPercent: -100,
          },
          {
            opacity: 1,
            xPercent: 0,
          }
        )
        .fromTo(
          ".side-nav",
          {
            xPercent: 0,
          },
          {
            xPercent: -100,
          },
          "=<"
        )
        .fromTo(
          ".block-app__view, .app-header",
          {},
          {
            width: "calc(100% - 92px)",
            duration: 0.2,
          },
          "=<"
        );
    } else {
      localStorage.setItem(
        "userSettings",
        JSON.stringify({
          ...userSettings,
          dashboard: { ...newDashboardSettings },
        })
      );
      setUserSettings({
        ...userSettings,
        dashboard: { ...newDashboardSettings },
      });
      setMinimizeNav(false);
      minimizeTL
        .fromTo(
          ".min-nav",
          {
            xPercent: 0,
          },
          {
            xPercent: -100,
          }
        )
        .fromTo(
          ".side-nav",
          {
            xPercent: -100,
          },
          {
            xPercent: 0,
            duration: 0.2,
          },
          "=<"
        )
        .fromTo(
          ".block-app__view, .app-header",
          {},
          {
            width: "calc(100% - 270px)",
            duration: 0.2,
          },
          "=<"
        );
    }
  };

  // Function to collapse mobile nav
  const collapseMobileNav = () => {
    mobileTL.fromTo(
      ".mobile-nav",
      {
        xPercent: 100,
      },
      {
        xPercent: 0,
      }
    );
  };

  // Resize the page for responsiveness using gsap
  useEffect(() => {
    mediaQuery.add("(max-width: 1280px)", () => {
      gsap.fromTo(".block-app__view, .app-header", {}, { width: "100%" });
    });
    mediaQuery.add("(min-width: 1280px)", () => {
      userSettings?.dashboard?.navBar?.minimize
        ? gsap.fromTo(
            ".block-app__view, .app-header",
            {},
            { width: "calc(100% - 92px)" }
          )
        : gsap.fromTo(
            ".block-app__view, .app-header",
            {},
            { width: "calc(100% - 270px)" }
          );
    });
  }, []);

  return (
    <div>
      {/* For mobile view */}
      <div className="xl:hidden">
        <Fullnav resizeNav={collapseMobileNav} mobile />
      </div>

      {/* For desktop view */}
      <div className="hidden xl:flex">
        <div
          className={`
          ${userSettings?.dashboard.navBar.minimize ? "flex" : "hidden"}
        `}
        >
          <Mininav resizeNav={resizeNav} />
        </div>
        <div>
          <Fullnav resizeNav={resizeNav} renderCount={renderCount} />
        </div>
      </div>
    </div>
  );
}
