import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useStepper } from "../../../context/stepperContext";
import { ReactComponent as AddIcon } from "../../../assets/svg/icons/add-icon.svg";
import { ReactComponent as Campaign } from "../../../assets/svg/icons/campaign.svg";
import { ReactComponent as AddNode } from "../../../assets/svg/icons/add-node.svg";
import { appConfig } from "../../../config";
import { ErrorText } from "../../layouts/Auth";
import * as Yup from "yup";
import { getUnit } from "./02-Step";
import { NodeForm } from "./Forms";
import ProgressBar from "./ProgressBar";
import { useNavigate } from "react-router-dom";
import { capitalizeString } from "../../../utils";

const Step3 = () => {
  const {
    chainCurrentStep,
    setChainCurrentStep,
    chainCurrentConfig,
    setChainCurrentConfig,
  } = useStepper();
  const [amis, setAmis] = useState(
    appConfig.networks[chainCurrentConfig.networkId].parameters.v1.amis
  );
  const [specifications, setSpecifications] = useState(
    appConfig.networks[chainCurrentConfig.networkId].specifications.v1
  );
  const [priceEstimate, setPriceEstimate] = useState("500");
  const [formDisplayQueue, setFormDisplayQueue] = useState(
    chainCurrentConfig?.metadata?.formDisplayQueue || [
      "f1",
      "f2",
      "f3",
      "f4",
      "f5",
    ]
  );
  const [formDisplayCount, setFormDisplayCount] = useState(
    chainCurrentConfig?.metadata?.formDisplayCount || 1
  );
  const [selectedNodeTypes, setSelectedNodeTypes] = useState(
    chainCurrentConfig.metadata?.selectedNodeTypes || {
      f1: 0,
      f2: 0,
      f3: 0,
      f4: 0,
      f5: 0,
    }
  );
  const [cloudProviders] = useState(
    appConfig.networks[chainCurrentConfig.networkId].parameters.v1
      .cloudProviders
  );
  const [nodeTypes, setNodeTypes] = useState(
    appConfig.networks[chainCurrentConfig.networkId].parameters.v1.nodeTypes
  );
  const [managementType, setManagementType] = useState(
    chainCurrentConfig.managementType || "fullyManaged"
  );
  const [activeForm, setActiveForm] = useState(formDisplayQueue[0] || "f1");
  const [formTitles, setFormTitles] = useState(
    chainCurrentConfig.metadata?.formTitles || {
      f1: "",
      f2: "",
      f3: "",
      f4: "",
      f5: "",
    }
  );
  const navigate = useNavigate();

  let form1, form2, form3, form4, form5;

  form1 = useFormik({
    initialValues: {
      nodeType:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f1 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f1
          ]?.nodeType) ||
        "",
      nodeCount:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f1 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f1
          ]?.nodeCount) ||
        0,
      ami:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f1 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f1
          ]?.ami) ||
        "",
      os:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f1 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f1
          ]?.os) ||
        "",
      cpu:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f1 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f1
          ]?.cpu) ||
        specifications.cpu.max,
      storageSize:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f1 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig.metadata.nodeFormMapping.f1
          ]?.storageSize) ||
        specifications.storage.default,
      memorySize:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f1 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f1
          ]?.memorySize) ||
        specifications.memory.default,
      loadBalancer:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f1 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f1
          ]?.loadBalancer) ||
        false,
    },
    validationSchema: Yup.object({
      nodeType: Yup.string()
        .required(<ErrorText text="Node type is required." />)
        .min(3, "Minimum of 3 characters"),
      nodeCount: Yup.number()
        .required(<ErrorText text="Node count is required." />)
        .min(1, "Minimum of 1 node")
        .max(5, "Maximum of 5 nodes"),
      /* ami: Yup.string().required(<ErrorText text="AMI is required." />), */
      cpu: Yup.number()
        .required(<ErrorText text="CPU count is required." />)
        .min(specifications.cpu.min, `Minimum of ${specifications.cpu.min}`)
        .max(specifications.cpu.max, `Maximum of ${specifications.cpu.max}`),
      os: Yup.string().required(<ErrorText text="Select Operating System." />),
      memorySize: Yup.number().required(
        <ErrorText text="Memory size is required." />
      ),
      storageSize: Yup.number()
        .required(<ErrorText text="Storage size is required." />)
        .min(
          specifications.storage.min,
          `Minimum of ${specifications.storage.min}`
        )
        .max(
          specifications.storage.max,
          `Maximum of ${specifications.storage.max}`
        ),
    }),
    onSubmit: async (values) => {},
  });

  form2 = useFormik({
    initialValues: {
      nodeType:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f2 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f2
          ]?.nodeType) ||
        "",
      nodeCount:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f2 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f2
          ]?.nodeCount) ||
        0,
      ami:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f2 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f2
          ]?.ami) ||
        "",
      os:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f2 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f2
          ]?.os) ||
        "",
      cpu:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f2 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f2
          ]?.cpu) ||
        specifications.cpu.max,
      storageSize:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f2 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig.metadata.nodeFormMapping.f2
          ]?.storageSize) ||
        specifications.storage.default,
      memorySize:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f2 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f2
          ]?.memorySize) ||
        specifications.memory.default,
      loadBalancer:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f2 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f2
          ]?.loadBalancer) ||
        false,
    },
    validationSchema: Yup.object({
      nodeType: Yup.string()
        .required(<ErrorText text="Node type is required." />)
        .min(3, "Minimum of 3 characters"),
      nodeCount: Yup.number()
        .required(<ErrorText text="Node count is required." />)
        .min(1, "Minimum of 1 node")
        .max(5, "Maximum of 5 nodes"),
      /* ami: Yup.string().required(<ErrorText text="AMI is required." />), */
      os: Yup.string().required(<ErrorText text="Select Operating System." />),
      cpu: Yup.number()
        .required(<ErrorText text="CPU count is required." />)
        .min(specifications.cpu.min, `Minimum of ${specifications.cpu.min}`)
        .max(specifications.cpu.max, `Maximum of ${specifications.cpu.max}`),
      memorySize: Yup.number().required(
        <ErrorText text="Memory size is required." />
      ),
      storageSize: Yup.number()
        .required(<ErrorText text="Storage size is required." />)
        .min(
          specifications.storage.min,
          `Minimum of ${specifications.storage.min}`
        )
        .max(
          specifications.storage.max,
          `Maximum of ${specifications.storage.max}`
        ),
    }),
    onSubmit: async (values) => {},
  });

  form3 = useFormik({
    initialValues: {
      nodeType:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f3 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f3
          ]?.nodeType) ||
        "",
      nodeCount:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f3 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f3
          ]?.nodeCount) ||
        0,
      ami:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f3 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f3
          ]?.ami) ||
        "",
      os:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f3 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f3
          ]?.os) ||
        "",
      cpu:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f3 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f3
          ]?.cpu) ||
        specifications.cpu.max,
      storageSize:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f3 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig.metadata?.nodeFormMapping?.f3
          ]?.storageSize) ||
        specifications.storage.default,
      memorySize:
        (chainCurrentConfig?.metadata?.nodeFormMapping &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f3
          ]?.memorySize) ||
        specifications.memory.default,
      loadBalancer:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f3 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f3
          ]?.loadBalancer) ||
        false,
    },
    validationSchema: Yup.object({
      nodeType: Yup.string()
        .required(<ErrorText text="Node type is required." />)
        .min(3, "Minimum of 3 characters"),
      nodeCount: Yup.number()
        .required(<ErrorText text="Node count is required." />)
        .min(1, "Minimum of 1 node")
        .max(5, "Maximum of 5 nodes"),
      /* ami: Yup.string().required(<ErrorText text="AMI is required." />), */
      os: Yup.string().required(<ErrorText text="Select Operating System." />),
      cpu: Yup.number()
        .required(<ErrorText text="CPU count is required." />)
        .min(specifications.cpu.min, `Minimum of ${specifications.cpu.min}`)
        .max(specifications.cpu.max, `Maximum of ${specifications.cpu.max}`),
      memorySize: Yup.number().required(
        <ErrorText text="Memory size is required." />
      ),
      storageSize: Yup.number()
        .required(<ErrorText text="Storage size is required." />)
        .min(
          specifications.storage.min,
          `Minimum of ${specifications.storage.min}`
        )
        .max(
          specifications.storage.max,
          `Maximum of ${specifications.storage.max}`
        ),
    }),
    onSubmit: async (values) => {},
  });

  form4 = useFormik({
    initialValues: {
      nodeType:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f4 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f4
          ]?.nodeType) ||
        "",
      nodeCount:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f4 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f4
          ]?.nodeCount) ||
        0,
      ami:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f4 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f4
          ]?.ami) ||
        "",
      os:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f4 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f4
          ]?.os) ||
        "",
      cpu:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f4 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f3
          ]?.cpu) ||
        specifications.cpu.max,
      storageSize:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f4 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig.metadata?.nodeFormMapping?.f4
          ]?.storageSize) ||
        specifications.storage.default,
      memorySize:
        (chainCurrentConfig?.metadata?.nodeFormMapping &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f4
          ]?.memorySize) ||
        specifications.memory.default,
      loadBalancer:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f4 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f4
          ]?.loadBalancer) ||
        false,
    },
    validationSchema: Yup.object({
      nodeType: Yup.string()
        .required(<ErrorText text="Node type is required." />)
        .min(3, "Minimum of 3 characters"),
      nodeCount: Yup.number()
        .required(<ErrorText text="Node count is required." />)
        .min(1, "Minimum of 1 node")
        .max(5, "Maximum of 5 nodes"),
      /* ami: Yup.string().required(<ErrorText text="AMI is required." />), */
      os: Yup.string().required(<ErrorText text="Select Operating System." />),
      cpu: Yup.number()
        .required(<ErrorText text="CPU count is required." />)
        .min(specifications.cpu.min, `Minimum of ${specifications.cpu.min}`)
        .max(specifications.cpu.max, `Maximum of ${specifications.cpu.max}`),
      memorySize: Yup.number().required(
        <ErrorText text="Memory size is required." />
      ),
      storageSize: Yup.number()
        .required(<ErrorText text="Storage size is required." />)
        .min(
          specifications.storage.min,
          `Minimum of ${specifications.storage.min}`
        )
        .max(
          specifications.storage.max,
          `Maximum of ${specifications.storage.max}`
        ),
    }),
    onSubmit: async (values) => {},
  });

  form5 = useFormik({
    initialValues: {
      nodeType:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f5 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f5
          ]?.nodeType) ||
        "",
      nodeCount:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f5 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f5
          ]?.nodeCount) ||
        0,
      ami:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f5 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f5
          ]?.ami) ||
        "",
      os:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f5 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f5
          ]?.os) ||
        "",
      cpu:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f5 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f3
          ]?.cpu) ||
        specifications.cpu.max,
      storageSize:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f5 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig.metadata?.nodeFormMapping?.f5
          ]?.storageSize) ||
        specifications.storage.default,
      memorySize:
        (chainCurrentConfig?.metadata?.nodeFormMapping &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f5
          ]?.memorySize) ||
        specifications.memory.default,
      loadBalancer:
        (chainCurrentConfig?.metadata?.nodeFormMapping?.f5 &&
          chainCurrentConfig?.nodes[
            chainCurrentConfig?.metadata?.nodeFormMapping?.f5
          ]?.loadBalancer) ||
        false,
    },
    validationSchema: Yup.object({
      nodeType: Yup.string()
        .required(<ErrorText text="Node type is required." />)
        .min(3, "Minimum of 3 characters"),
      nodeCount: Yup.number()
        .required(<ErrorText text="Node count is required." />)
        .min(1, "Minimum of 1 node")
        .max(5, "Maximum of 5 nodes"),
      /* ami: Yup.string().required(<ErrorText text="AMI is required." />), */
      os: Yup.string().required(<ErrorText text="Select Operating System." />),
      cpu: Yup.number()
        .required(<ErrorText text="CPU count is required." />)
        .min(specifications.cpu.min, `Minimum of ${specifications.cpu.min}`)
        .max(specifications.cpu.max, `Maximum of ${specifications.cpu.max}`),
      memorySize: Yup.number().required(
        <ErrorText text="Memory size is required." />
      ),
      storageSize: Yup.number()
        .required(<ErrorText text="Storage size is required." />)
        .min(
          specifications.storage.min,
          `Minimum of ${specifications.storage.min}`
        )
        .max(
          specifications.storage.max,
          `Maximum of ${specifications.storage.max}`
        ),
    }),
    onSubmit: async (values) => {},
  });

  const formik = useFormik({
    initialValues: {
      cloudProvider: chainCurrentConfig.cloudProvider || "",
      region: chainCurrentConfig.region || "",
      managementType: chainCurrentConfig.managementType || "fullyManaged",
    },
    validationSchema: Yup.object({
      cloudProvider: Yup.string().required(
        <ErrorText text="Cloud Provider is required." />
      ),
      region: Yup.string().required(<ErrorText text="Select region." />),
    }),
    onSubmit: async (values) => {
      const nodeSettings = {};
      const nodeFormMapping = {};
      if (form1.values.nodeType) {
        nodeSettings[form1.values.nodeType.toLowerCase()] = form1.values;
        nodeFormMapping.f1 = form1.values.nodeType.toLowerCase();
      }
      if (form2.values.nodeType) {
        nodeSettings[form2.values.nodeType.toLowerCase()] = form2.values;
        nodeFormMapping.f2 = form2.values.nodeType.toLowerCase();
      }
      if (form3.values.nodeType) {
        nodeSettings[form3.values.nodeType.toLowerCase()] = form3.values;
        nodeFormMapping.f3 = form3.values.nodeType.toLowerCase();
      }
      if (form4.values.nodeType) {
        nodeSettings[form4.values.nodeType.toLowerCase()] = form4.values;
        nodeFormMapping.f4 = form4.values.nodeType.toLowerCase();
      }

      setChainCurrentConfig({
        ...chainCurrentConfig,
        nodes: nodeSettings,
        priceEstimate: priceEstimate,
        cloudProvider: values.cloudProvider,
        region: values.region,
        metadata: {
          ...chainCurrentConfig.metadata,
          nodeFormMapping,
          formDisplayQueue,
          formDisplayCount,
          selectedNodeTypes: selectedNodeTypes,
          formTitles: formTitles,
        },
      });

      setChainCurrentStep(chainCurrentStep + 1);
    },
  });

  const removeForm = (id, nodeType) => {
    const newOrder = [...formDisplayQueue];
    const poppedIndex = formDisplayQueue.indexOf(id);
    const poppedItem = formDisplayQueue[poppedIndex];
    newOrder.splice(poppedIndex, 1);
    newOrder.push(poppedItem);
    setFormDisplayQueue(newOrder);
    if (formDisplayCount > 1) {
      setFormDisplayCount(formDisplayCount - 1);
    }

    const updatedNodeTypes = { ...selectedNodeTypes };
    updatedNodeTypes[id] = "";
    setSelectedNodeTypes(updatedNodeTypes);
    if (formDisplayCount > 1) {
      setActiveForm(newOrder[formDisplayCount - 2]);
    }
  };

  const ErrorDisplay = ({ name }) => {
    return (
      <div
        className={`${
          formik.touched[name] && formik.errors[name]
            ? "inline-block"
            : "hidden"
        } app_form-error`}
      >
        {formik.touched[name] && formik.errors[name] && formik.errors[name]}
      </div>
    );
  };

  const handleRadioChange = (value, type) => {
    if (type === "cloudProvider") {
      const radio1 = document.querySelector("#gcp-radio");
      const radio2 = document.querySelector("#aws-radio");
      if (value === "gcp") {
        radio1.checked = true;
        //radio2.checked = false;
      }
      /* if (value === "aws") {
        radio2.checked = true;
        radio1.checked = false;
      } */
      formik.setFieldValue("cloudProvider", value);
    } else if (type === "region") {
      const radio1 = document.querySelector("#us-central1-radio");
      const radio2 = document.querySelector("#europe-west2-radio");
      const radio3 = document.querySelector("#asia-east2-radio");
      if (value === "us-central1") {
        radio1.checked = true;
        radio2.checked = false;
        radio3.checked = false;
      }
      if (value === "europe-west2") {
        radio2.checked = true;
        radio1.checked = false;
        radio3.checked = false;
      }
      if (value === "asia-east2") {
        radio3.checked = true;
        radio1.checked = false;
        radio2.checked = false;
      }
      formik.setFieldValue("region", value);
    }
  };

  const f1 = (
    <NodeForm
      form={form1}
      id="f1"
      removeForm={removeForm}
      displayCount={formDisplayCount}
      selectedNodeTypes={selectedNodeTypes}
      setSelectedNodeTypes={setSelectedNodeTypes}
      formTitles={formTitles}
      setFormTitles={setFormTitles}
      activeForm={activeForm}
    />
  );
  const f2 = (
    <NodeForm
      form={form2}
      id="f2"
      removeForm={removeForm}
      displayCount={formDisplayCount}
      selectedNodeTypes={selectedNodeTypes}
      setSelectedNodeTypes={setSelectedNodeTypes}
      formTitles={formTitles}
      setFormTitles={setFormTitles}
      activeForm={activeForm}
    />
  );
  const f3 = (
    <NodeForm
      form={form3}
      id="f3"
      removeForm={removeForm}
      displayCount={formDisplayCount}
      selectedNodeTypes={selectedNodeTypes}
      setSelectedNodeTypes={setSelectedNodeTypes}
      formTitles={formTitles}
      setFormTitles={setFormTitles}
      activeForm={activeForm}
    />
  );
  const f4 = (
    <NodeForm
      form={form4}
      id="f4"
      removeForm={removeForm}
      displayCount={formDisplayCount}
      selectedNodeTypes={selectedNodeTypes}
      setSelectedNodeTypes={setSelectedNodeTypes}
      formTitles={formTitles}
      setFormTitles={setFormTitles}
      activeForm={activeForm}
    />
  );
  const f5 = (
    <NodeForm
      form={form5}
      id="f5"
      removeForm={removeForm}
      displayCount={formDisplayCount}
      selectedNodeTypes={selectedNodeTypes}
      setSelectedNodeTypes={setSelectedNodeTypes}
      formTitles={formTitles}
      setFormTitles={setFormTitles}
      activeForm={activeForm}
    />
  );

  useEffect(() => {
    if (chainCurrentConfig.cloudProvider) {
      handleRadioChange(chainCurrentConfig.cloudProvider, "cloudProvider");
    }
    if (chainCurrentConfig.region) {
      handleRadioChange(chainCurrentConfig.region, "region");
    }
  }, []);

  return (
    <div className="step">
      <ProgressBar />
      <h2 className="mb-5 mt-2 self-start">Provider Configuration</h2>
      <div className="w-full">
        <div className="slider mb-8">
          <div className="slider__header">
            <button
              type="button"
              className="slider__header-button"
              onClick={() => {
                setManagementType("fullyManaged");
              }}
            >
              Fully Managed Provider
            </button>
            <button
              type="button"
              className="slider__header-button"
              onClick={() => {
                setManagementType("selfManaged");
              }}
            >
              Self-managed Provider
            </button>
          </div>
          <div className="slider__border">
            <div
              className={`w-[50%] h-full ${
                managementType === "fullyManaged" ? "bg-blue rounded-[5px]" : ""
              }`}
            ></div>
            <div
              className={`w-[50%] h-full ${
                managementType === "selfManaged" ? "bg-blue rounded-[5px]" : ""
              }`}
            ></div>
          </div>
        </div>
        {managementType === "fullyManaged" && (
          <div>
            <div className="w-full mb-5">
              <div className="app_form">
                <label htmlFor="cloudProvider">Select Cloud Provider</label>
                <div className="grid sm:grid-cols-2 grid-cols-1 gap-3 md:gap-8">
                  {cloudProviders.map((provider, index) => {
                    return (
                      <button
                        key={index}
                        type="button"
                        className={`
                          flex items-center gap-2 pl-5 pr-2 h-[50px] border-[1px] border-[#B8B8B8] rounded-[8px]
                          ${
                            formik.values.cloudProvider === provider.slug
                              ? "border-[#2180FF] bg-[#E9F1FF]"
                              : ""
                          }`}
                        onClick={() => {
                          if (provider.slug === "gcp") {
                            handleRadioChange(provider.slug, "cloudProvider");
                            setChainCurrentConfig({
                              ...chainCurrentConfig,
                              metadata: {
                                cloudProvider: provider,
                              },
                            });
                          }
                        }}
                      >
                        {provider.slug === "gcp" && (
                          <input
                            type={"radio"}
                            name={provider.slug}
                            id={`${provider.slug}-radio`}
                            onChange={() => {}}
                            value={provider.slug}
                          />
                        )}
                        <div className={`text-[#717171]`}>
                          <div className="flex items-center gap-2 text-[14px] text-black font-[600]">
                            <img
                              src={provider.icon}
                              alt={provider.slug}
                              width={30}
                            />
                            <p>{provider.label}</p>
                          </div>
                        </div>
                      </button>
                    );
                  })}
                </div>
                <ErrorDisplay name={"cloudProvider"} />
              </div>
            </div>

            <div className="w-full lg:w-[50%] mb-10">
              <div className="w-full mb-5">
                <div className="app_form">
                  <label htmlFor="region">Select Region</label>
                  <div className="grid sm:grid-cols-3 grid-cols-1 gap-3 md:gap-4">
                    {appConfig.regions.map((region, index) => {
                      return (
                        <button
                          key={index}
                          type="button"
                          className={`
                          flex items-center gap-2 pl-5 pr-2 h-[50px] border-[1px] border-[#B8B8B8] rounded-[8px]
                          ${
                            formik.values.region === region.slug
                              ? "border-[#2180FF] bg-[#E9F1FF]"
                              : ""
                          }`}
                          onClick={() => {
                            handleRadioChange(region.slug, "region");
                          }}
                        >
                          <input
                            type={"radio"}
                            name={region.slug}
                            id={`${region.slug}-radio`}
                            onChange={() => {}}
                            value={region.slug}
                          />
                          <div className={`text-[#717171]`}>
                            <div className="flex items-center gap-2 text-[14px] text-black font-[600]">
                              <img
                                src={region.icon}
                                alt={region.slug}
                                width={20}
                              />
                              <p>{region.label}</p>
                            </div>
                          </div>
                        </button>
                      );
                    })}
                  </div>
                  <ErrorDisplay name={"region"} />
                </div>
              </div>
            </div>
          </div>
        )}
        {managementType === "selfManaged" && (
          <div className="w-full flex justify-center">
            <div className="w-[250px] py-3 px-5 flex items-center gap-3 rounded-lg bg-[#EFF5FF] text-[13px]">
              <Campaign />
              <p>Self managed service is coming soon</p>
            </div>
          </div>
        )}
      </div>

      <div className="w-full flex flex-col lg:flex-row lg:items-center lg:justify-between">
        <div
          id="nodeSelection"
          className="w-full lg:w-[85%] self-start flex items-center"
        >
          <div className="slider mb-7">
            <div className="slider__header">
              <button
                type="button"
                className="slider__header-button4 w-[20%]"
                onClick={() => {
                  setActiveForm(formDisplayQueue[0]);
                }}
              >
                {formTitles[formDisplayQueue[0]]}
              </button>
              {formDisplayCount > 1 && (
                <button
                  type="button"
                  className="slider__header-button4 w-[20%]"
                  onClick={() => {
                    setActiveForm(formDisplayQueue[1]);
                  }}
                >
                  {formTitles[formDisplayQueue[1]]}
                </button>
              )}
              {formDisplayCount > 2 && (
                <button
                  type="button"
                  className="slider__header-button4 w-[20%]"
                  onClick={() => {
                    setActiveForm(formDisplayQueue[2]);
                  }}
                >
                  {formTitles[formDisplayQueue[2]]}
                </button>
              )}
              {formDisplayCount > 3 && (
                <button
                  type="button"
                  className="slider__header-button4 w-[20%]"
                  onClick={() => {
                    setActiveForm(formDisplayQueue[3]);
                  }}
                >
                  {formTitles[formDisplayQueue[3]]}
                </button>
              )}
              {formDisplayCount > 4 && (
                <button
                  type="button"
                  className="slider__header-button4 w-[20%]"
                  onClick={() => {
                    setActiveForm(formDisplayQueue[4]);
                  }}
                >
                  {formTitles[formDisplayQueue[4]]}
                </button>
              )}
            </div>
            <div className="slider__border">
              <div
                className={`w-[20%] h-full ${
                  activeForm === formDisplayQueue[0]
                    ? "bg-blue rounded-[5px]"
                    : ""
                }`}
              ></div>
              {formDisplayCount > 1 && (
                <div
                  className={`w-[20%] h-full ${
                    activeForm === formDisplayQueue[1]
                      ? "bg-blue rounded-[5px]"
                      : ""
                  }`}
                ></div>
              )}
              {formDisplayCount > 2 && (
                <div
                  className={`w-[20%] h-full ${
                    activeForm === formDisplayQueue[2]
                      ? "bg-blue rounded-[5px]"
                      : ""
                  }`}
                ></div>
              )}
              {formDisplayCount > 3 && (
                <div
                  className={`w-[20%] h-full ${
                    activeForm === formDisplayQueue[3]
                      ? "bg-blue rounded-[5px]"
                      : ""
                  }`}
                ></div>
              )}
              {formDisplayCount > 4 && (
                <div
                  className={`w-[20%] h-full ${
                    activeForm === formDisplayQueue[4]
                      ? "bg-blue rounded-[5px]"
                      : ""
                  }`}
                ></div>
              )}
            </div>
          </div>
        </div>

        {formDisplayCount < nodeTypes.length && (
          <button
            className="flex flex-col items-center text-sm gap-2"
            onClick={() => {
              if (formDisplayCount < formDisplayQueue.length) {
                setActiveForm(formDisplayQueue[formDisplayCount]);
                setFormDisplayCount(formDisplayCount + 1);
              }
            }}
          >
            <AddNode className="scale-[1.4]" />
            <p>Add a node type</p>
          </button>
        )}
      </div>

      {activeForm === "f1"
        ? f1
        : activeForm === "f2"
        ? f2
        : activeForm === "f3"
        ? f3
        : activeForm === "f4"
        ? f4
        : f5}

      {formDisplayCount < nodeTypes.length && (
        <button
          className="flex flex-col items-center justify-center mt-10 gap-2"
          onClick={() => {
            if (formDisplayCount < formDisplayQueue.length) {
              setActiveForm(formDisplayQueue[formDisplayCount]);
              setFormDisplayCount(formDisplayCount + 1);
            }
          }}
        >
          <AddIcon className="scale-[0.8]" />
          <p className="font-[600] text-[14px]">Add another node type</p>
        </button>
      )}

      <div className="w-full flex flex-col md:flex-row gap-5 justify-between md:items-end mt-10 text-[13px]">
        <div>
          <p>
            Estimated Price:{" "}
            <span className="font-[600]">${priceEstimate}</span>
          </p>
        </div>
        <div className="flex flex-col md:flex-row">
          {formDisplayQueue.map((formId, index) => {
            let currentForm;
            if (index < formDisplayCount) {
              if (formId === "f1") {
                currentForm = form1;
              } else if (formId === "f2") {
                currentForm = form2;
              } else if (formId === "f3") {
                currentForm = form3;
              } else if (formId === "f4") {
                currentForm = form4;
              } else {
                currentForm = form5;
              }
              return (
                <div
                  key={index}
                  className="flex px-4 border-r border-r-[0.5px]"
                >
                  <div className="flex flex-col items-end">
                    <div className="flex flex-col items-end mb-1 text-[#13141B] text-[10px]">
                      <div className="text-white bg-blue rounded-full w-4 h-4 flex">
                        <p className="m-auto mt-[1px]">
                          {currentForm.values.nodeCount}
                        </p>
                      </div>
                      {capitalizeString(currentForm.values.nodeType)} Node
                    </div>
                    <p>
                      CPU:{" "}
                      <span className="font-[600]">
                        {currentForm.values.cpu} cores
                      </span>
                    </p>
                    <p>
                      Storage:{" "}
                      <span className="font-[600]">
                        {getUnit(currentForm.values.storageSize, "byte")}
                      </span>
                    </p>
                    <p>
                      Memory:{" "}
                      <span className="font-[600]">
                        {getUnit(currentForm.values.memorySize, "byte")}
                      </span>
                    </p>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>

      <button
        type="submit"
        className="app_button bottom-10 mt-8 full"
        disabled={managementType === "selfManaged"}
        onClick={() => {
          let error = false;
          let formError = "";
          for (let i = 0; i < formDisplayQueue.length; i++) {
            if (i < formDisplayCount) {
              const formId = formDisplayQueue[i];
              if (formId === "f1") {
                if (Object.keys(form1.errors).length > 0) {
                  error = true;
                  formError = "f1";
                  form1.handleSubmit();
                }
                form1.handleSubmit();
              } else if (formId === "f2") {
                if (Object.keys(form2.errors).length > 0) {
                  error = true;
                  formError = "f2";
                  form2.handleSubmit();
                }
                form2.handleSubmit();
              } else if (formId === "f3") {
                if (Object.keys(form3.errors).length > 0) {
                  error = true;
                  formError = "f3";
                  form3.handleSubmit();
                }
                form3.handleSubmit();
              } else if (formId === "f4") {
                if (Object.keys(form4.errors).length > 0) {
                  error = true;
                  formError = "f4";
                  form4.handleSubmit();
                }
                form4.handleSubmit();
              } else {
                if (Object.keys(form5.errors).length > 0) {
                  error = true;
                  formError = "f5";
                  form5.handleSubmit();
                }
                form5.handleSubmit();
              }
            }
          }

          if (!error) {
            formik.handleSubmit();
          } else {
            setActiveForm(formError);
            window.scrollTo(200, 200);
          }
        }}
      >
        Continue
      </button>
    </div>
  );
};

export default Step3;
