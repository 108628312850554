import AWS from "./assets/img/aws.png";
import GCP from "./assets/img/gcp.png";
import America from "./assets/img/america.png";
import Europe from "./assets/img/europe.png";
import Asia from "./assets/img/asia.png";
import Ubuntu from "./assets/img/ubuntu.png";
import Debian from "./assets/img/debian.png";
import CentOS from "./assets/img/centos.png";

export const appConfig = {
  networks: {
    1: {
      logo: "https://cryptologos.cc/logos/polkadot-new-dot-logo.svg?v=023",
      id: 1,
      name: "Polkadot",
      description:
        "Using a sharded architecture, Polkadot can process transactions and data in parallel.",
      parameters: {
        v1: {
          amis: [
            /* {
              slug: "blockops-v0.1.0",
              label: "blockops-v0.1.0",
            }, */
            {
              slug: "blockops",
              label: "Blockops Hardened AMI",
              active: false,
            },
            {
              slug: "default",
              label: "Default AMI",
              active: false,
            },
          ],
          zones: [
            {
              slug: "Asia-East1",
              label: "Taiwan",
            },
            {
              slug: "Asia-East2",
              label: "Hong Kong",
            },
            {
              slug: "Asia-Southeast2",
              label: "Singapore",
            },
            {
              slug: "Europe-North2",
              label: "Finland",
            },
            {
              slug: "Europe-West2",
              label: "London",
            },
            {
              slug: "Northamerica-Northeast2",
              label: "Ontario",
            },
            {
              slug: "Us-East4",
              label: "Virginia",
            },
          ],
          nodeTypes: [
            {
              slug: "boot",
              label: "Boot Node",
            },
            {
              slug: "full",
              label: "Full Node (Archival)",
            },
            {
              slug: "validator",
              label: "Validator Node",
            },
            {
              slug: "collator",
              label: "Collator Node",
            },
          ],
          chainTypes: [
            {
              slug: "relay",
              label: "Relaychain",
            },
            {
              slug: "para",
              label: "Parachain",
            },
          ],
          imageVersions: [
            {
              slug: "polkadot-v0.9.31",
              label: "polkadot-v0.9.31",
            },
            {
              slug: "polkadot-v0.9.40",
              label: "polkadot-v0.9.40",
            },
            {
              slug: "polkadot-v0.9.41",
              label: "polkadot-v0.9.41",
            },
            {
              slug: "polkadot-v0.9.42",
              label: "polkadot-v0.9.42",
            },
          ],
          cloudProviders: [
            {
              slug: "gcp",
              label: "Google Cloud Service",
              icon: GCP,
            },
            {
              slug: "aws",
              label: "Amazon Web Service",
              icon: AWS,
            },
            /* {
              slug: "maz",
              label: "Microsoft Azure",
            }, */
          ],
        },
        rpc: {
          https: "https://polkadot-rpc.blockops.network/rpc",
          websocket: "wss://polkadot-rpc.blockops.network/ws",
        },
      },
      specifications: {
        v1: {
          cpu: {
            max: 8,
            min: 2,
            unit: "core",
            default: 4,
            available: [2, 4, 8],
          },
          ref: "https://wiki.polkadot.network/docs/maintain-guides-how-to-validate-polkadot#reference-hardware",
          memory: {
            max: 64000000000,
            min: 1000000000,
            unit: "byte",
            default: 16000000000,
          },
          storage: {
            max: 5000000000000,
            min: 50000000000,
            unit: "byte",
            default: 1000000000000,
          },
        },
      },
      isActive: 1,
      createdAt: "2023-04-07T13:58:32.000Z",
      updatedAt: "2023-04-07T13:58:32.000Z",
    },
    2: {
      logo: "https://cryptologos.cc/logos/kusama-ksm-logo.svg?v=023",
      id: 2,
      name: "Kusama",
      description:
        "A scalable and interoperable network for developers to build decentralized applications (dApps).",
      parameters: {
        v1: {
          amis: [
            /* {
              slug: "blockops-v0.1.0",
              label: "blockops-v0.1.0",
            }, */
            {
              slug: "blockops",
              label: "Blockops Hardened AMI",
              active: false,
            },
            {
              slug: "default",
              label: "Default AMI",
              active: false,
            },
          ],
          zones: [
            {
              slug: "Asia-East1",
              label: "Taiwan",
            },
            {
              slug: "Asia-East2",
              label: "Hong Kong",
            },
            {
              slug: "Asia-Southeast2",
              label: "Singapore",
            },
            {
              slug: "Europe-North2",
              label: "Finland",
            },
            {
              slug: "Europe-West2",
              label: "London",
            },
            {
              slug: "Northamerica-Northeast2",
              label: "Ontario",
            },
            {
              slug: "Us-East4",
              label: "Virginia",
            },
          ],
          /* nodeTypes: [
            {
              slug: "archival",
              label: "Archival Node",
            },
            {
              slug: "boot",
              label: "Boot Node",
            },
            {
              slug: "rpc",
              label: "RPC Node",
            },
            {
              slug: "validator",
              label: "Validator Node",
            },
          ], */
          nodeTypes: [
            {
              slug: "boot",
              label: "Boot Node",
            },
            {
              slug: "full",
              label: "Full Node (Archival)",
            },
            {
              slug: "validator",
              label: "Validator Node",
            },
            {
              slug: "collator",
              label: "Collator Node",
            },
          ],
          chainTypes: [
            {
              slug: "relay",
              label: "Relaychain",
            },
            {
              slug: "para",
              label: "Parachain",
            },
          ],
          imageVersions: [
            {
              slug: "Polkadot-v0.9.31",
              label: "Polkadot-v0.9.31",
            },
            {
              slug: "polkadot-v0.9.40",
              label: "polkadot-v0.9.40",
            },
            {
              slug: "polkadot-v0.9.41",
              label: "polkadot-v0.9.41",
            },
            {
              slug: "polkadot-v0.9.42",
              label: "polkadot-v0.9.42",
            },
          ],
          cloudProviders: [
            {
              slug: "gcp",
              label: "Google Cloud Service",
              icon: GCP,
            },
            {
              slug: "aws",
              label: "Amazon Web Service",
              icon: AWS,
            },
            /* {
              slug: "maz",
              label: "Microsoft Azure",
            }, */
          ],
        },
        rpc: {
          https: "https://kusama-rpc.blockops.network/rpc",
          websocket: "wss://kusama-rpc.blockops.network/ws",
        },
      },
      specifications: {
        v1: {
          cpu: {
            max: 8,
            min: 2,
            unit: "core",
            default: 4,
          },
          ref: "https://wiki.kusama.network/docs/maintain-guides-how-to-validate-kusam#reference-hardware",
          memory: {
            max: 64000000000,
            min: 1000000000,
            unit: "byte",
            default: 16000000000,
          },
          storage: {
            max: 5000000000000,
            min: 50000000000,
            unit: "byte",
            default: 1000000000000,
          },
        },
      },
      isActive: 1,
      createdAt: "2023-04-07T13:58:32.000Z",
      updatedAt: "2023-04-07T13:58:32.000Z",
    },
    3: {
      logo: "https://cryptologos.cc/logos/kusama-ksm-logo.svg?v=023",
      id: 3,
      name: "Substrate",
      description:
        "A scalable and interoperable network for developers to build decentralized applications (dApps).",
      parameters: {
        v1: {
          amis: [
            /* {
              slug: "blockops-v0.1.0",
              label: "blockops-v0.1.0",
            }, */
            {
              slug: "blockops",
              label: "Blockops Hardened AMI",
              active: false,
            },
            {
              slug: "default",
              label: "Default AMI",
              active: false,
            },
          ],
          zones: [
            {
              slug: "Asia-East1",
              label: "Taiwan",
            },
            {
              slug: "Asia-East2",
              label: "Hong Kong",
            },
            {
              slug: "Asia-Southeast2",
              label: "Singapore",
            },
            {
              slug: "Europe-North2",
              label: "Finland",
            },
            {
              slug: "Europe-West2",
              label: "London",
            },
            {
              slug: "Northamerica-Northeast2",
              label: "Ontario",
            },
            {
              slug: "Us-East4",
              label: "Virginia",
            },
          ],
          nodeTypes: [
            {
              slug: "boot",
              label: "Boot Node",
            },
            {
              slug: "full",
              label: "Full Node (Archival)",
            },
            {
              slug: "validator",
              label: "Validator Node",
            },
            {
              slug: "collator",
              label: "Collator Node",
            },
          ],
          chainTypes: [
            {
              slug: "relay",
              label: "Relaychain",
            },
            {
              slug: "para",
              label: "Parachain",
            },
          ],
          imageVersions: [
            {
              slug: "Polkadot-v0.9.31",
              label: "Polkadot-v0.9.31",
            },
            {
              slug: "polkadot-v0.9.40",
              label: "polkadot-v0.9.40",
            },
            {
              slug: "polkadot-v0.9.41",
              label: "polkadot-v0.9.41",
            },
            {
              slug: "polkadot-v0.9.42",
              label: "polkadot-v0.9.42",
            },
          ],
          cloudProviders: [
            {
              slug: "gcp",
              label: "Google Cloud Service",
              icon: GCP,
            },
            {
              slug: "aws",
              label: "Amazon Web Service",
              icon: AWS,
            },
            /* {
              slug: "maz",
              label: "Microsoft Azure",
            }, */
          ],
        },
        rpc: {
          https: "https://kusama-rpc.blockops.network/rpc",
          websocket: "wss://kusama-rpc.blockops.network/ws",
        },
      },
      specifications: {
        v1: {
          cpu: {
            max: 8,
            min: 2,
            unit: "core",
            default: 4,
          },
          ref: "https://wiki.kusama.network/docs/maintain-guides-how-to-validate-kusam#reference-hardware",
          memory: {
            max: 64000000000,
            min: 1000000000,
            unit: "byte",
            default: 16000000000,
          },
          storage: {
            max: 5000000000000,
            min: 50000000000,
            unit: "byte",
            default: 1000000000000,
          },
        },
      },
      isActive: 1,
      createdAt: "2023-04-07T13:58:32.000Z",
      updatedAt: "2023-04-07T13:58:32.000Z",
    },
  },
  cloudProviders: [
    {
      slug: "aws",
      label: "Amazon Web Service",
      icon: AWS,
    },
    {
      slug: "gcp",
      label: "Google Cloud Service",
      icon: GCP,
    },
    {
      slug: "maz",
      label: "Microsoft Azure",
    },
  ],
  cloudSpecs: {
    aws: {
      availableCpus: [2, 4, 8, 16],
      cpuToMem: {
        2: [1, 2, 4, 8],
        4: [8, 16],
        8: [16, 32],
        16: [32, 64],
      },
    },
    gcp: {
      availableCpus: [2, 4, 8, 16],
      cpuToMem: {
        2: [1, 2, 4, 8],
        4: [8, 16],
        8: [16, 32],
        16: [32, 64],
      },
    },
    maz: {
      availableCpus: [2, 4, 8, 16],
      cpuToMem: {
        2: [1, 2, 4, 8],
        4: [8, 16],
        8: [16, 32],
        16: [32, 64],
      },
    },
    min: {
      availableCpus: [2, 4, 6, 8],
      cpuToMem: {
        2: 4,
        4: 8,
        6: 10,
        8: 16,
      },
    },
  },
  regions: [
    {
      slug: "us-central1",
      label: "America",
      icon: America,
    },
    {
      slug: "europe-west2",
      label: "Europe",
      icon: Europe,
    },
    {
      slug: "asia-east2",
      label: "Asia",
      icon: Asia,
    },
  ],
  operatingSystems: [
    {
      slug: "Ubuntu",
      label: "Ubuntu",
      icon: Ubuntu,
      active: true,
    },
    {
      slug: "Debian",
      label: "Debian",
      icon: Debian,
      active: false,
    },
    {
      slug: "CentOS",
      label: "CentOS",
      icon: CentOS,
      active: false,
    },
  ],
  argumentRules: [
    {
      type: "bool",
      key: "--ws-external",
      values: [],
    },
    {
      type: "bool",
      key: "--rpc-external",
      values: [],
    },
    {
      type: "string",
      key: "--rpc-cors",
      values: [],
    },
    {
      type: "string",
      key: "--rpc-methods",
      values: [],
    },
    {
      type: "string",
      key: "--name",
      values: [],
    },
  ],
  nav: {
    routes: {
      dashboard: "/dashboard",
      api: "/api-services",
      validator: "/validator-nodes",
      nodes: "/nodes",
      settings: "/settings",
      support: "/support",
    },
  },
  externalLinks: {
    docs: "https://docs.blockops.network/",
    dataServices: "https://data.blockops.network/",
  },
};
