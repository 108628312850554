import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ReactComponent as OpenEyeIcon } from "../../assets/svg/icons/open_eye.svg";
import { ReactComponent as CloseEyeIcon } from "../../assets/svg/icons/close_eye.svg";
import CheckedBox from "../../assets/img/check_box.png";
import EmptyBox from "../../assets/img/empty_box.png";
import { signup } from "../../services/AuthService";
import { ErrorText, PasswordCheck } from "../../components/layouts/Auth";
import {
  alertError,
  alertSuccess,
  containsSpecialChars,
  NETWORK_STATE,
} from "../../utils";

export default function AppSignup() {
  const [showPassword, setShowPassword] = useState(false);
  const [allPassed, setAllPassed] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);

  const [status, setStatus] = useState(NETWORK_STATE.IDLE);

  const navigate = useNavigate();

  const handleSignup = async (values, methods) => {
    try {
      setStatus(NETWORK_STATE.LOADING);
      const obj = {
        user: {
          firstName: formik.values.first_name,
          lastName: formik.values.last_name,
          email: formik.values.email,
          password: formik.values.password,
        },
        organization: {
          name: formik.values.business_name,
          description: formik.values.description,
        },
      };
      const response = await signup(obj);
      setStatus(NETWORK_STATE.SUCCESS);
      alertSuccess(response.data.message);
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (err) {
      if (err.response.status === 409) {
        formik.errors.email = <ErrorText text={err.response?.data?.message} />
      } else alertError(err.response?.data?.message || "An Error has occured. Please try again.");
      setStatus(NETWORK_STATE.ERROR);
    }
  };

  const formik= useFormik({
    enableReinitialize: true,
    initialValues: {
      business_name: "",
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(<ErrorText text="Invalid email address." />)
        .required(<ErrorText text="Email is required." />),
      business_name: Yup.string()
        .min(3, <ErrorText text="Minimum of 3." />)
        .required(<ErrorText text="Business name is required." />),
      first_name: Yup.string()
        .min(3, <ErrorText text="Minimum of 3." />)
        .required(<ErrorText text="Firstname is required." />),
      last_name: Yup.string()
        .min(3, <ErrorText text="Minimum of 3." />)
        .required(<ErrorText text="Lastname is required." />),
    }),
    onSubmit: (values, methods) => {
      handleSignup(values, methods);
    }
  });

  useEffect(() => {
    setAllPassed(false);
    formik.values.password
    && /[a-z]/.test(formik.values.password)
    && /[A-Z]/.test(formik.values.password)
    && /[0-9]/.test(formik.values.password)
    && containsSpecialChars(formik.values.password)
    && formik.values.password.length > 7
    && (setAllPassed(true))
  }, [formik?.values?.password]);

  document.title = "Blockops | Signup";

  return (
    <div className="form-body">
      <form
        onSubmit={formik.handleSubmit}
        onChange={formik.handleChange}
        className="form"
      >
        <h2 className="form-header">Create Account</h2>
        <p className="form-subtext">This information would be used to create your account.</p>
        <div className="w-full flex flex-col md:flex-row justify-between md:gap-5">
          <div className="mb-5 w-full">
            <div className="app_form">
              <label htmlFor="first_name">First name <span>*</span></label>
              <input
                id="first_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter your first name"
                type="text"
                name="first_name"
                className={`border-[1px] focus:outline-none signup-input
                  ${!formik.errors?.first_name && formik.touched?.first_name ? 'focus:border-blue' : ''}
                  ${formik.errors?.first_name && formik.touched?.first_name ? 'border-[#C63737] border-[1.5px]' : 'border-[rgba(18,18,18,0.3)]'}
                `}
              />
              <div className={`${formik.touched.first_name && formik.errors.first_name ? 'inline-block' : 'hidden'} app_form-error`}>
                {formik.touched.first_name && formik.errors.first_name && (formik.errors.first_name)}
              </div>
            </div>
          </div>

          <div className="mb-5 w-full">
            <div className="app_form">
              <label htmlFor="last_name">Last name <span>*</span></label>
              <input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter your last name"
                type="text"
                name="last_name"
                id="last_name"
                className={`border-[1px] focus:outline-none signup-input
                  ${!formik.errors?.last_name && formik.touched?.last_name ? 'focus:border-blue' : ''}
                  ${formik.errors?.last_name && formik.touched?.last_name ? 'border-[#C63737] border-[1.5px]' : 'border-[rgba(18,18,18,0.3)]'}
                `}
              />
              <div className={`${formik.touched.last_name && formik.errors.last_name ? 'inline-block' : 'hidden'} app_form-error`}>
                {formik.touched.last_name && formik.errors.last_name && (formik.errors.last_name)}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mb-5">
          <div className="app_form">
            <label htmlFor="email">Email address <span>*</span></label>
            <input
              onChange={(e) => {
                formik.setFieldValue('email', e.target.value);
                //formik.values.email = e.target.value;
                if (formik.errors.email?.props?.text === 'An account already exist with this email. Please use a different email.') {
                  formik.setFieldError('email', '');
                }
              }}
              onBlur={formik.handleBlur}
              placeholder="Enter your email address"
              type="email"
              name="email"
              id="email"
              className={`border-[1px] focus:outline-none signup-input
                ${!formik.errors?.email && formik.touched?.email ? 'focus:border-blue' : ''}
                ${formik.errors?.email && formik.touched?.email ? 'border-[#C63737] border-[1.5px]' : 'border-[rgba(18,18,18,0.3)]'}
              `}
            />
            <div className={`${formik.touched.email && formik.errors.email ? 'inline-block' : 'hidden'} app_form-error`}>
              {formik.touched.email && formik.errors.email && (formik.errors.email)}
            </div>
          </div>
        </div>
        <div className="w-full mb-5">
          <div className="app_form">
            <label htmlFor="business_name">Business name <span>*</span></label>
            <input
              id="business_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter your business name"
              type="text"
              name="business_name"
              className={`border-[1px] focus:outline-none signup-input
                ${!formik.errors?.business_name && formik.touched.business_name ? 'focus:border-blue' : ''}
                ${formik.errors?.business_name && formik.touched.business_name ? 'border-[#C63737] border-[1.5px]' : 'border-[rgba(18,18,18,0.3)]'}
              `}
            />
            <div className={`${formik.touched.business_name && formik.errors.business_name ? 'inline-block' : 'hidden'} app_form-error`}>
              {formik.touched.business_name && formik.errors.business_name && (formik.errors.business_name)}
            </div>
          </div>
        </div>
        <div className="w-full mb-5">
          <div className="app_form relative">
            <label htmlFor="password">Password <span>*</span></label>
            <div className="app_form-password">
              <input
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter password"
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                className={`w-full border-[1px] focus:outline-none signup-input
                ${allPassed && formik.touched?.password ? 'focus:border-blue' : ''}
                ${!allPassed && formik.touched?.password ? 'border-[#C63737] border-[1.5px]' : 'border-[rgba(18,18,18,0.3)]'}
              `}
              />
              <button
                className="focus:outline-none"
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {
                  !showPassword ? <OpenEyeIcon /> : <CloseEyeIcon />
                }
              </button>
            </div>

            {
              ((formik.touched.password && formik.values.password.length > 0) && !allPassed)
              && 
              <PasswordCheck password={formik.values.password} />
            }
          </div>
        </div>
        <div className={`mb-5 w-full`}>
          <div className="app_form">
            <label htmlFor="confirmPassword">Confirm password <span>*</span></label>
            <div className="app_form-password">
              <input
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Confirm password"
                type={showPassword ? "text" : "password"}
                name="confirmPassword"
                id="confirmPassword"
                className={`
                  app_form-password confirm-input border-[1.5px]
                  ${!formik.values.confirmPassword ? 'border-[rgba(18,18,18,0.3)]' : ''}
                  ${formik.values.confirmPassword && formik.values.password === formik.values.confirmPassword ? 'border-[#00e1b6]' : 'border-[#C63737]'}
                `}
              />
              <button
                className="focus:outline-none"
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {
                  !showPassword ? <OpenEyeIcon /> : <CloseEyeIcon />
                }
              </button>
            </div>
          </div>
        </div>
        <button
          type="submit"
          disabled={
            status == NETWORK_STATE.LOADING ||
            !/[a-z]/.test(formik.values.password) ||
            !/[A-Z]/.test(formik.values.password) ||
            !containsSpecialChars(formik.values.password) ||
            !/[0-9]/.test(formik.values.password) ||
            formik.values.password !== formik.values.confirmPassword ||
            !acceptTerms
          }
          className="app_button md:h-[60px] full"
        >
          {status == NETWORK_STATE.LOADING
            ? "Creating..."
            : "Create my account"}
        </button>
      </form>
      <div className="">
        <div className="text-[16px] app_auth-info">
          <img src={acceptTerms ? CheckedBox : EmptyBox} alt="" className="mr-5 w-5 inline" onClick={() => setAcceptTerms(!acceptTerms)} />
          <p className="inline">By signing up, you agree to our</p>
          <a className="inline text-sm" href="https://www.blockops.network/terms" target={'_blank'}>
            &nbsp;&nbsp;Terms & Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
};
