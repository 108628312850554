import { useEffect } from "react";
import { useStepper } from "../../../context/stepperContext";
import Networks from "../../api-services/Networks";

const Step1 = () => {
  const {
    dedicatedCurrentStep,
    setDedicatedCurrentStep,
    dedicatedCurrentConfig,
    setDedicatedCurrentConfig,
  } = useStepper();

  useEffect(() => {
    if (!dedicatedCurrentConfig.networkId) {
      setDedicatedCurrentConfig({});
    }
  }, []);

  return (
    <Networks
      connectAction={() => {
        setDedicatedCurrentStep(dedicatedCurrentStep + 1);
      }}
      type={"dedicated"}
      config={dedicatedCurrentConfig}
      setConfig={setDedicatedCurrentConfig}
    />
  );
};

export default Step1;
