import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import { ReactComponent as OpenEyeIcon } from "../../assets/svg/icons/open_eye.svg";
import { ReactComponent as CloseEyeIcon } from "../../assets/svg/icons/close_eye.svg";
import EmailSent from "../../assets/img/email_sent.png";
import Countdown from 'react-countdown';
import * as Yup from "yup";
import {
  alertError,
  alertSuccess,
  containsSpecialChars,
  NETWORK_STATE,
} from "../../utils";
import {
  resetPassword,
  validateResetPasswordToken,
} from "../../services/AuthService";
import { useFormik } from "formik";
import { ErrorText, PasswordCheck } from "../../components/layouts/Auth";

export default function AppLogin() {
  const [status, setStatus] = useState(NETWORK_STATE.IDLE);
  const [showPassword, setShowPassword] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [allPassed, setAllPassed] = useState(false);
  const [password, setPassword] = useState("");
  const [token, setToken] = useState(null);
  const { search } = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();

  const verifyAuthCode = async () => {
    try {
      const code = new URLSearchParams(search).get("code");
      if (code) {
        setToken(code);
        setStatus(NETWORK_STATE.LOADING);
        await validateResetPasswordToken({ token: code });
        setStatus(NETWORK_STATE.SUCCESS);
      } else {
        navigate("/login");
      }
    } catch (err) {
      alertError(err.response.data.message);
      setStatus(NETWORK_STATE.ERROR);
      navigate("/login");
    }
  };

  const handleReset = async (values) => {
    try {
      setStatus(NETWORK_STATE.LOADING);
      const response = await resetPassword({ password: values.password, token });
      setStatus(NETWORK_STATE.SUCCESS);

      auth.initUser(response.data.data);
      alertSuccess(response.data.message);
      setResetSuccess(true);
      setToken("");
      setTimeout(() => {window.location.pathname === '/reset-password' && navigate('/login')}, 9000);
      setPassword("");
    } catch (err) {
      alertError(err.response.data.message);
      setStatus(NETWORK_STATE.ERROR);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { password: "" },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(<ErrorText text="Password is required." />)
    }),
    onSubmit: (values) => { handleReset(values) }
  });

  useEffect(() => {
    setAllPassed(false);
    formik.values.password
    && /[a-z]/.test(formik.values.password)
    && /[A-Z]/.test(formik.values.password)
    && /[0-9]/.test(formik.values.password)
    && containsSpecialChars(formik.values.password)
    && formik.values.password.length > 7
    && (setAllPassed(true))
  }, [formik?.values?.password]);

  useEffect(() => {
    verifyAuthCode();
  }, []);
  document.title = "Blockops | Change Password";

  return (
    <div className="w-full">
      {
        !resetSuccess
        ?
        <div className="form-body">
          <div className="form">
            <h1 className="form-header">Request password reset</h1>
            <p className="form-subtext">New password must be different from previous password</p>
            <div className="w-full max-w-[500px]">
              <form onSubmit={formik.handleSubmit}>
                <div className="mb-5">
                  <div className="app_form relative">
                    <label htmlFor="password">Password <span>*</span></label>
                    <div className="app_form-password">
                      <input
                        required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        className={`w-full border-[1px] focus:outline-none signup-input
                        ${allPassed && formik.touched?.password ? 'focus:border-blue' : ''}
                        ${!allPassed && formik.touched?.password ? 'border-[#C63737] border-[1.5px]' : 'border-[rgba(18,18,18,0.3)]'}
                      `}
                      />
                      <button
                        className="focus:outline-none"
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {
                          !showPassword ? <OpenEyeIcon /> : <CloseEyeIcon />
                        }
                      </button>
                    </div>

                    {
                      ((formik.touched.password && formik.values.password.length > 0) && !allPassed)
                      &&
                      <PasswordCheck password={formik.values.password} />
                    }
                  </div>
                </div>
                <div className={`mb-5`}>
                  <div className="app_form">
                    <label htmlFor="confirmPassword">Confirm password <span>*</span></label>
                    <div className="app_form-password">
                      <input
                        required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Confirm password"
                        type={showPassword ? "text" : "password"}
                        name="confirmPassword"
                        id="confirmPassword"
                        className={`
                          app_form-password confirm-input border-[1.5px]
                          ${!formik.values.confirmPassword ? 'border-[rgba(18,18,18,0.3)]' : ''}
                          ${formik.values.confirmPassword && formik.values.password === formik.values.confirmPassword ? 'border-[#00e1b6]' : 'border-[#C63737]'}
                        `}
                      />
                      <button
                        className="focus:outline-none"
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {
                          !showPassword ? <OpenEyeIcon /> : <CloseEyeIcon />
                        }
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={
                    status == NETWORK_STATE.LOADING ||
                    !/[a-z]/.test(formik.values.password) ||
                    !/[A-Z]/.test(formik.values.password) ||
                    !containsSpecialChars(formik.values.password) ||
                    !/[0-9]/.test(formik.values.password) ||
                    formik.values.password !== formik.values.confirmPassword
                  }
                  className="app_button md:h-[60px] full"
                >
                  {status == NETWORK_STATE.LOADING
                    ? "Updating..."
                    : "Update password"}
                </button>
              </form>
            </div>
          </div>

          <p className="bottom-text">
            Rememeber your password?{" "}
            <Link to="/login" className="" href="#">
              <span>Login</span>
            </Link>{" "}
          </p>
        </div>
        :
        <div className="form-body">
          <div className="form">
            <img src={EmailSent} alt="" className="self-center w-[130px] mt-10" />
            <p className="form-header">Password succesfully reset</p>
            <p className="form-subtext">Your password has been sucessfully reset</p>
            <button
              type="button"
              onClick={() => navigate("/login")}
              className="app_button full"
            >
              login
            </button>
            <div className="bottom-text self-center">
              You will be redirected to login in
              <div className="inline">
                &nbsp;
                {
                  <Countdown
                    date={Date.now() + 9000}
                    renderer={({ minutes, seconds }) => <p className="text-black underline-none inline">{minutes}:{seconds}</p> }
                  />
                }
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
