import { toast } from "react-hot-toast";
export const NETWORK_STATE = {
  SUCCESS: "SUCCESS",
  LOADING: "LOADING",
  IDLE: "IDLE",
  ERROR: "ERROR",
};

export const alertSuccess = (message) => {
  toast.success(message);
};

export const alertError = (message) => {
  toast.error(message);
};

export const containsSpecialChars = (password) => {
  const specialChars = /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/;
  return specialChars.test(password);
};

export const convertToGigaByte = (value, unit = "byte") => {
  if (!value) return 0;

  switch (unit) {
    case "byte":
      return Math.ceil(value / 1e9);
    case "kilobyte":
      return Math.ceil(value / 1e6);
    default:
      return Math.ceil(value);
  }
};

export const convertToTeraByte = (value, unit = "gigabyte") => {
  if (!value) return 0;

  switch (unit) {
    case "gigabyte":
      return Math.ceil(value / 1000);
    default:
      return Math.ceil(value);
  }
};

export const getDateTime = (dateString) => {
  // Get year, month, and day part from the date
  const date = new Date(dateString);
  const year = date.toLocaleString("default", { year: "numeric" });
  const month = date.toLocaleString("default", { month: "2-digit" });
  const day = date.toLocaleString("default", { day: "2-digit" });
  const time = date.toLocaleString("default", { timeStyle: "short" });
  const ampm = date.getHours() >= 12 ? "pm" : "am";

  // Generate yyyy-mm-dd date string
  const formattedDate = day + "/" + month + "/" + year;
  return { date: formattedDate, time, ampm };
};

const capitalizeWord = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const capitalizeString = (stringToCap) => {
  if (!stringToCap) return "";
  let stringCap = "";
  const words = stringToCap.split();
  words.forEach((word, index) => {
    if (index === words.length - 1) {
      stringCap += capitalizeWord(word);
    } else {
      stringCap += capitalizeWord(word) + " ";
    }
  });
  return stringCap;
};

export const getTimeLength = (startDateString) => {
  const currentDate = new Date();
  const startDate = new Date(startDateString);

  let timeLeftInSeconds = Math.round(
    (currentDate.getTime() - startDate.getTime()) / 1000
  );

  const days = Math.floor(timeLeftInSeconds / (60 * 60 * 24));

  timeLeftInSeconds = timeLeftInSeconds - days * 60 * 60 * 24;

  const hours = Math.floor(timeLeftInSeconds / (60 * 60));

  timeLeftInSeconds = timeLeftInSeconds - hours * 60 * 60;

  const minutes = Math.floor(timeLeftInSeconds / 60);

  timeLeftInSeconds = timeLeftInSeconds - minutes * 60;

  return `${days}D ${hours}H ${minutes}M ${timeLeftInSeconds}S`;
};

export const isGitHubReleaseUrl = (url) => {
  // Regular expression pattern for matching GitHub release URLs
  const pattern =
    /^https?:\/\/github\.com\/[\w-]+\/[\w-]+\/releases\/download\/.+$/;

  // Check if the URL matches the pattern
  return pattern.test(url);
};
