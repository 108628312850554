import { appConfig } from "../../config";
import { Link } from "react-router-dom";
import { ReactComponent as Logout } from "../../assets/svg/icons/logout-2.svg";
import { ReactComponent as MenuIcon } from "../../assets/svg/icons/menu.svg";
import { ReactComponent as Person } from "../../assets/svg/icons/person.svg";
import { ReactComponent as UserDropdown } from "../../assets/svg/icons/user-dropdown.svg";
import { useAuth } from "../../context/authContext";
import { useOutsideAlerter } from "../../hooks/useClickOutside";
import { useRef, useState } from "react";
import gsap from "gsap";

export default function AppHeader() {
  const [showOptions, setShowOptions] = useState(false);
  const { headerText, logout } = useAuth();
  const wrapperRef = useRef(null);
  const handleClickOutside = () => {
    setShowOptions(false);
  };
  useOutsideAlerter(wrapperRef, handleClickOutside);
  const mobileTL = gsap.timeline({
    defaults: { ease: "linear", duration: 0.3 },
  });

  const extendMobileNav = () => {
    mobileTL.fromTo(
      ".mobile-nav",
      {
        xPercent: 0,
      },
      {
        xPercent: 100,
      }
    );
  };
  const userSettings = JSON.parse(localStorage.getItem("userSettings"));

  return (
    <div className="">
      <header
        className={`
      ${
        userSettings?.dashboard?.navBar?.minimize
          ? "w-full xl:w-[calc(100%-92px)]"
          : "w-full xl:w-[calc(100%-270px)]"
      }
        app-header`}
      >
        <div className="app-header__options">
          <button onClick={() => extendMobileNav()}>
            <MenuIcon />
          </button>
          <div className="app-header__path">
            <div>
              {headerText && headerText.length > 0
                ? headerText.map((head, i) =>
                    i == 0 ? (
                      <span
                        key={i}
                        className="font-bol text-16 app-header__path-main"
                      >
                        {head}
                      </span>
                    ) : (
                      <span key={i} className="app-header__path-sub text-16">
                        {" > "}
                        {head}
                      </span>
                    )
                  )
                : "Blockops"}
            </div>
          </div>
        </div>

        <div className="app-header__dropdown" ref={wrapperRef}>
          <button onClick={() => setShowOptions(!showOptions)}>
            <UserDropdown />
          </button>
          {showOptions && (
            <div className="app-header__dropdown_cont">
              <ul>
                <li>
                  <Link
                    onClick={() => handleClickOutside()}
                    to={appConfig.nav.routes.settings}
                  >
                    <Person />
                    Profile
                  </Link>
                </li>
                <li>
                  <button onClick={() => logout()}>
                    <Logout />
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </header>
    </div>
  );
}
