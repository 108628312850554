import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Back } from "../../../assets/svg/icons/back.svg";
import { ReactComponent as Pause } from "../../../assets/svg/icons/blue-pause.svg";
import { ReactComponent as Play } from "../../../assets/svg/icons/blue-play.svg";
import { ReactComponent as Delete } from "../../../assets/svg/icons/tiny-bin.svg";
import { ReactComponent as Copy } from "../../../assets/svg/icons/content_copy.svg";
import Loader from "../../../assets/img/loader.gif";
import { useEffect, useState } from "react";
import {
  PauseModal,
  ResumeModal,
  TerminateModal,
} from "../../../components/nodes/Modals";
import { projects } from "../../../components/nodes/DummyData";
import CPUChart from "../../../components/nodes/charts/CPUChart";
import { getUnit } from "../../../components/nodes/dedicated-nodes/02-step";
import {
  NETWORK_STATE,
  alertError,
  capitalizeString,
  getTimeLength,
} from "../../../utils";
import { useAuth } from "../../../context/authContext";
import { getDedicatedProject } from "../../../services/ProjectsService";
import { appConfig } from "../../../config";

const LiveUptime = ({ startDate }) => {
  const [uptime, setUptime] = useState("");

  useEffect(() => {
    setInterval(() => {
      setUptime(getTimeLength(startDate));
    }, 1000);
  }, []);

  return <>{uptime}</>;
};

const DedicatedProjectInsight = () => {
  const navigate = useNavigate();
  const [pauseModalIsOpen, setPauseModalIsOpen] = useState(false);
  const [resumeModalIsOpen, setResumeModalIsOpen] = useState(false);
  const [terminateModalIsOpen, setTerminateModalIsOpen] = useState(false);
  const [liveProjectStatus, setLiveProjectStatus] = useState("");
  const location = useLocation();
  const [project, setProject] = useState(null);
  const auth = useAuth();
  const [status, setStatus] = useState(NETWORK_STATE.LOADING);
  const [isCopied, setIsCopied] = useState(false);

  const fetchProject = async () => {
    const pathSplit = location.pathname.split("/");
    const projectId = pathSplit[pathSplit.length - 1];
    setStatus(NETWORK_STATE.LOADING);
    try {
      const response = await getDedicatedProject(projectId);
      const userProject = response.data.data?.data;
      setLiveProjectStatus(userProject?.status);
      userProject && setProject(userProject);
      auth.setHeaderText(["Dedicated Nodes", userProject?.projectName]);
      setStatus(NETWORK_STATE.SUCCESS);
    } catch (err) {
      console.log(err);
      if (err.response.status === 401) {
        alertError(err.response.data.message);
        auth.logout();
      }
      setStatus(NETWORK_STATE.ERROR);
    }
  };

  useEffect(() => {
    fetchProject();
  }, []);

  const handleCopy = (id, text) => {
    navigator.clipboard.writeText(text);
    setIsCopied({ copy: true, id: id });
    setTimeout(() => {
      setIsCopied({ copy: false, id: "" });
    }, 2000);
  };

  return (
    <div className="w-full">
      {status === NETWORK_STATE.LOADING ? (
        <img
          src={Loader}
          alt="Loading..."
          className="self-center mx-auto py-20"
        />
      ) : NETWORK_STATE.SUCCESS && project ? (
        <div className="dedicatedProject">
          <div className="dedicatedProject__header">
            <button
              className="create__back"
              onClick={() => {
                navigate(appConfig.nav.routes.nodes);
              }}
            >
              <Back className="scale-[0.9]" />
              <p>Go Back</p>
            </button>
            <div className="flex items-center gap-0">
              {/* <button
                className={`dedicatedProject__header-btn ${
                  liveProjectStatus !== "active" &&
                  "cursor-not-allowed hover:opacity-70"
                }`}
                onClick={() => setPauseModalIsOpen(!pauseModalIsOpen)}
                disabled={liveProjectStatus !== "active"}
              >
                <Pause />
                <p>Pause</p>
              </button>
              <button
                className={`dedicatedProject__header-btn ${
                  liveProjectStatus !== "pause" &&
                  "cursor-not-allowed hover:opacity-70"
                }`}
                onClick={() => setResumeModalIsOpen(!resumeModalIsOpen)}
                disabled={liveProjectStatus !== "pause"}
              >
                <Play />
                <p>Resume</p>
              </button> */}
              <button
                className={`dedicatedProject__header-btn ${
                  liveProjectStatus === "terminate" &&
                  "cursor-not-allowed hover:opacity-70"
                }`}
                onClick={() => setTerminateModalIsOpen(!terminateModalIsOpen)}
                disabled={liveProjectStatus === "terminate"}
              >
                <Delete />
              </button>
            </div>
          </div>

          <div className="project-insight__graph mt-10">
            {/* <div className="w-full flex items-center justify-between">
              <h2 className="project-insight__graph-text">CPU</h2>
            </div> */}
            {project ? (
              <CPUChart networkId={project?.id} />
            ) : (
              <img
                src={Loader}
                alt="Loading..."
                className="self-center py-20"
              />
            )}
          </div>

          <div className="dedicatedProject__dataBox">
            <div className="dedicatedProject__insight h-[120px]">
              <p className="dedicatedProject__insight-title">Storage size</p>
              <p className="dedicatedProject__insight-value">
                {project?.storageSize >= 1000
                  ? `${project?.storageSize / 1000} TB`
                  : `${project.storageSize} GB`}
              </p>
            </div>
            <div className="dedicatedProject__insight h-[120px]">
              <p className="dedicatedProject__insight-title">Memory</p>
              <p className="dedicatedProject__insight-value">
                {project?.memorySize} GB
              </p>
            </div>
            <div className="dedicatedProject__insight h-[120px]">
              <p className="dedicatedProject__insight-title">Uptime</p>
              <p className="dedicatedProject__insight-value">
                {liveProjectStatus === "active" ? (
                  <LiveUptime startDate={project?.createdAt} />
                ) : (
                  `${capitalizeString(liveProjectStatus)}d`
                )}
              </p>
            </div>
          </div>

          <div className="dedicatedProject__rpcApi">
            <div className="dedicatedProject__insight h-[80px]">
              <p className="dedicatedProject__insight-title">RPC Endpoint</p>
              <div className="dedicatedProject__rpcApi-value">
                <div className="w-full flex justify-between items-center">
                  <p className="w-full font-[200] text-[14px] text-ellipsis truncate">
                    {`https://${
                      project?.referenceId
                    }-node.${"blockops.network"}`}
                  </p>
                  <div
                    className="p-2 cursor-pointer hover:scale-[1.05]"
                    onClick={() =>
                      handleCopy(
                        "RPC",
                        `https://${
                          project?.referenceId
                        }-node.${"blockops.network"}`
                      )
                    }
                  >
                    <Copy />
                    {isCopied.id === "RPC" && (
                      <div className="absolute bg-[#6E6E6F] text-white text-[12px] font-bol p-1 rounded-lg">
                        Copied!
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="dedicatedProject__insight h-[80px]">
              <p className="dedicatedProject__insight-title">API Key</p>
              <div className="dedicatedProject__rpcApi-value">
                <div className="w-full flex justify-between items-center">
                  <p className="w-full font-[200] text-[14px] text-ellipsis truncate">
                    {project?.apiKey.slice(0, 10)}********
                  </p>
                  <div
                    className="p-2 cursor-pointer hover:scale-[1.05]"
                    onClick={() => handleCopy("RPC", `${project?.apiKey}`)}
                  >
                    <Copy />
                    {isCopied.id === "API" && (
                      <div className="absolute bg-[#6E6E6F] text-white text-[12px] font-bol p-1 rounded-lg">
                        Copied!
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <PauseModal
              pauseModalIsOpen={pauseModalIsOpen}
              setPauseModalIsOpen={setPauseModalIsOpen}
              project={project}
              updateProjectStatus={setLiveProjectStatus}
            />
          </div>

          <div>
            <ResumeModal
              resumeModalIsOpen={resumeModalIsOpen}
              setResumeModalIsOpen={setResumeModalIsOpen}
              project={project}
              updateProjectStatus={setLiveProjectStatus}
            />
          </div>

          <div>
            <TerminateModal
              terminateModalIsOpen={terminateModalIsOpen}
              setTerminateModalIsOpen={setTerminateModalIsOpen}
              project={project}
              updateProjectStatus={setLiveProjectStatus}
            />
          </div>
        </div>
      ) : (
        <div className="mt-5 text-[14px]">
          <p>An Error has occured</p>
          <p
            className="underline text-blue cursor-pointer"
            onClick={fetchProject}
          >
            Retry
          </p>
        </div>
      )}
    </div>
  );
};

export default DedicatedProjectInsight;
