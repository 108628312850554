import Modal from "react-modal";
import { ReactComponent as Pause } from "../../assets/svg/icons/blue-pause.svg";
import { ReactComponent as Play } from "../../assets/svg/icons/blue-play.svg";
import { ReactComponent as Delete } from "../../assets/svg/icons/bin.svg";
import CancelModal from "../../assets/img/cancel-modal.png";
import { useEffect, useState } from "react";
import { useHeader } from "../../context/headerContext";
import { NETWORK_STATE, alertError, alertSuccess } from "../../utils";
import { useAuth } from "../../context/authContext";
import {
  deleteParachainProject,
  pauseNode,
  resumeNode,
  terminateNode,
} from "../../services/ProjectsService";
import { useNavigate } from "react-router-dom";
import { useLiveUpdates } from "../../context/liveUpdates";

// Styles for modal
const customStyles = {
  overlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    opacity: "1",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
  },
};

export const PauseModal = ({
  pauseModalIsOpen,
  setPauseModalIsOpen,
  project,
  updateProjectStatus,
}) => {
  const { minimizeNav } = useHeader();
  const [pauseConfirmation, setPauseConfirmation] = useState("");
  const [status, setStatus] = useState(NETWORK_STATE.IDLE);
  const auth = useAuth();

  const pauseProject = async (projectId) => {
    setStatus(NETWORK_STATE.LOADING);
    try {
      const response = await pauseNode(projectId);
      setStatus(NETWORK_STATE.SUCCESS);
      updateProjectStatus("pause");
      alertSuccess(response.data?.message);
    } catch (err) {
      console.log(err);
      if (err.response.status === 401) {
        alertError(err.response.data.message);
        auth.logout();
      }
      setStatus(NETWORK_STATE.ERROR);
      alertError(err.response.data.message);
    }
  };

  return (
    <div>
      <Modal
        isOpen={pauseModalIsOpen}
        onRequestClose={() => setPauseModalIsOpen(false)}
        style={customStyles}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        contentLabel="Pause Project Modal"
        overlayClassName={`
    left-0
    ${minimizeNav ? "xl:left-[70px]" : "xl:left-[270px]"}`}
        className="absolute bg-white"
        parentSelector={() => document.querySelector(".block-app")}
      >
        <div className="w-full sm:w-[350px] flex flex-col px-5 py-7 gap-5">
          <div className="w-full flex justify-end items-center">
            <button onClick={() => setPauseModalIsOpen(false)}>
              <img src={CancelModal} alt="" width={22} />
            </button>
          </div>
          <div className="self-center my-5">
            <Pause className="scale-[3]" />
          </div>
          <div className="self-center">
            <h2 className="text-[18px] leading-[15px] text-center font-[600]">
              Are you sure?
            </h2>
            <p className="mt-1 text-[12px] text-[#1F1F1F99] text-center">
              Input "pause" to pause instance
            </p>
          </div>
          <div className="w-full">
            <input
              type={"text"}
              onChange={(e) => setPauseConfirmation(e.target.value)}
              className="w-full block h-[50px] px-4 rounded-[8px] text-[16px] border-[1px] border-[#1212124d]"
            />
            <button
              type="button"
              disabled={
                pauseConfirmation !== "pause" ||
                status === NETWORK_STATE.LOADING
              }
              onClick={() => {
                setPauseModalIsOpen(false);
                pauseProject(project?.id);
              }}
              className="app_button full mt-5"
            >
              {status === NETWORK_STATE.LOADING ? "Pausing" : "Pause project"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export const ResumeModal = ({
  resumeModalIsOpen,
  setResumeModalIsOpen,
  project,
  updateProjectStatus,
}) => {
  const { minimizeNav } = useHeader();
  const [resumeConfirmation, setResumeConfirmation] = useState("");
  const [status, setStatus] = useState(NETWORK_STATE.IDLE);
  const auth = useAuth();

  const resumeProject = async (projectId) => {
    setStatus(NETWORK_STATE.LOADING);
    try {
      const response = await resumeNode(projectId);
      setStatus(NETWORK_STATE.SUCCESS);
      updateProjectStatus("active");
      alertSuccess(response.data?.message);
    } catch (err) {
      console.log(err);
      if (err.response.status === 401) {
        alertError(err.response.data.message);
        auth.logout();
      }
      setStatus(NETWORK_STATE.ERROR);
      alertError(err.response.data.message);
    }
  };

  return (
    <div>
      <Modal
        isOpen={resumeModalIsOpen}
        onRequestClose={() => setResumeModalIsOpen(false)}
        style={customStyles}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        contentLabel="Resume Project Modal"
        overlayClassName={`
    left-0
    ${minimizeNav ? "xl:left-[70px]" : "xl:left-[270px]"}`}
        className="absolute bg-white"
        parentSelector={() => document.querySelector(".block-app")}
      >
        <div className="w-full sm:w-[350px] flex flex-col px-5 py-7 gap-5">
          <div className="w-full flex justify-end items-center">
            <button onClick={() => setResumeModalIsOpen(false)}>
              <img src={CancelModal} alt="" width={22} />
            </button>
          </div>
          <div className="self-center my-5">
            <Play className="scale-[3]" />
          </div>
          <div className="self-center">
            <h2 className="text-[18px] leading-[15px] text-center font-[600]">
              Are you sure?
            </h2>
            <p className="mt-1 text-[12px] text-[#1F1F1F99] text-center">
              Input "resume" to resume instance
            </p>
          </div>
          <div className="w-full">
            <input
              type={"text"}
              onChange={(e) => setResumeConfirmation(e.target.value)}
              className="w-full block h-[50px] px-4 rounded-[8px] text-[16px] border-[1px] border-[#1212124d]"
            />
            <button
              type="button"
              disabled={resumeConfirmation !== "resume"}
              onClick={() => {
                setResumeModalIsOpen(false);
                resumeProject(project?.id);
              }}
              className="app_button full mt-5"
            >
              {status === NETWORK_STATE.LOADING ? "Resuming" : "Resume project"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export const TerminateModal = ({
  terminateModalIsOpen,
  setTerminateModalIsOpen,
  project,
  updateProjectStatus,
  projectType,
}) => {
  const { minimizeNav } = useHeader();
  const [terminateConfirmation, setTerminateConfirmation] = useState("");
  const [status, setStatus] = useState(NETWORK_STATE.IDLE);
  const auth = useAuth();
  const navigate = useNavigate();
  const { setReloadNotifications } = useLiveUpdates();

  const terminateInstance = async (projectId) => {
    setStatus(NETWORK_STATE.LOADING);
    try {
      const response = await terminateNode(projectId);
      setStatus(NETWORK_STATE.SUCCESS);
      updateProjectStatus("terminate");
      alertSuccess(response.data?.message);
    } catch (err) {
      console.log(err);
      if (err.response.status === 401) {
        alertError(err.response.data.message);
        auth.logout();
      }
      setStatus(NETWORK_STATE.ERROR);
      alertError(err.response.data.message);
    }
  };

  const terminateParachain = async (parachainId, projectName) => {
    setStatus(NETWORK_STATE.LOADING);
    try {
      const response = deleteParachainProject(parachainId, projectName);
      setStatus(NETWORK_STATE.SUCCESS);
    } catch (err) {
      console.log(err);
      if (err.response.status === 401) {
        alertError(err.response.data.message);
        auth.logout();
      }
      setStatus(NETWORK_STATE.ERROR);
      alertError(err.response.data.message);
    }
    setTimeout(() => {
      setReloadNotifications(true);
    }, 1000);
  };

  return (
    <div>
      <Modal
        isOpen={terminateModalIsOpen}
        onRequestClose={() => setTerminateModalIsOpen(false)}
        style={customStyles}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        contentLabel="Terminate Project Modal"
        overlayClassName={`
    left-0
    ${minimizeNav ? "xl:left-[70px]" : "xl:left-[270px]"}`}
        className="absolute bg-white"
        parentSelector={() => document.querySelector(".block-app")}
      >
        <div className="w-full sm:w-[350px] flex flex-col px-5 py-7 gap-5">
          <div className="w-full flex justify-end items-center">
            <button onClick={() => setTerminateModalIsOpen(false)}>
              <img src={CancelModal} alt="" width={22} />
            </button>
          </div>
          <div className="h-[130px] w-[130px] flex items-center justify-center self-center mb-5 bg-[#D9E7FF] rounded-full">
            <Delete className="scale-[0.9]" />
          </div>
          <div className="self-center">
            <h2 className="text-[18px] leading-[15px] text-center font-[600]">
              Are you sure?
            </h2>
            <p className="mt-1 text-[12px] text-[#1F1F1F99] text-center">
              {projectType === "parachain"
                ? `Please input "terminate" if you are sure you want to delete this project.`
                : `Please input "terminate" if you are sure you want to delete this instance.`}
            </p>
          </div>
          <div className="w-full">
            <input
              type={"text"}
              onChange={(e) => setTerminateConfirmation(e.target.value)}
              className="w-full block h-[50px] px-4 rounded-[8px] text-[16px] border-[1px] border-[#1212124d] outline-none"
            />
            <button
              type="button"
              disabled={terminateConfirmation.toLowerCase() !== "terminate"}
              onClick={() => {
                setTerminateModalIsOpen(false);
                if (projectType === "parachain") {
                  terminateParachain(
                    project?.parachainId,
                    project?.projectName
                  );
                } else {
                  terminateInstance(project?.id);
                }
              }}
              className="app_button full mt-5"
            >
              {status === NETWORK_STATE.LOADING
                ? "Terminating"
                : "Terminate project"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
