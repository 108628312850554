import { ReactComponent as DropDown } from "../../../assets/svg/icons/select-arrow.svg";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { ErrorText } from "../../layouts/Auth";
import * as Yup from "yup";
import { useStepper } from "../../../context/stepperContext";
import { appConfig } from "../../../config";
import Pen from "../../../assets/img/pen.png";
import GrafanaIcon from "../../../assets/img/grafana-icon.png";
import BlockopsIcon from "../../../assets/img/tiny-logo.png";
import { capitalizeString } from "../../../utils";
import ProgressBar from "./ProgressBar";

const ArgumentInput = ({ keyName, value, header }) => {
  return (
    <div
      className={`w-full lg:w-[50%] flex items-center gap-5 text-[15px] ${
        header ? "text-[14px] mb-3" : "text-[16px]"
      }`}
    >
      <div className="w-[30%] text-right">
        {keyName === "Key name" ? keyName : `${keyName}=`}
      </div>
      <div className="w-[70%]">{value}</div>
    </div>
  );
};

const Step4 = () => {
  const {
    chainCurrentStep,
    setChainCurrentStep,
    chainCurrentConfig,
    setChainCurrentConfig,
  } = useStepper();
  const [networkId] = useState(chainCurrentConfig.networkId);
  const [nodeKey] = useState("hehcuyqtey");
  const [monitoring, setMonitoring] = useState(false);
  const [activeNodeType, setActiveNodeType] = useState(
    Object.keys(chainCurrentConfig.nodes)[0]
  );
  const defaultVars = {
    "--rpc-cors": "all",
    "--ws-external": true,
    "--rpc-external": true,
    "--rpc-methods": "Unsafe",
    "--name": chainCurrentConfig.projectName,
    extraArgs: "",
  };
  const [launchVars, setLaunchVars] = useState(
    chainCurrentConfig.launchVars || {
      validator: { ...defaultVars },
      full: { ...defaultVars },
      boot: { ...defaultVars },
      archival: { ...defaultVars },
      collator: { ...defaultVars },
    }
  );

  const formik = useFormik({
    initialValues: {
      monitoring: chainCurrentConfig.monitoring || false,
      monitoringDashboard: chainCurrentConfig.monitoringDashboard || "grafana",
    },
    validationSchema: Yup.object({
      monitoringDashboard: Yup.string().required(
        <ErrorText text="Select dashboard type." />
      ),
    }),
    onSubmit: async (values) => {
      const argumentRules = {};

      Object.keys(chainCurrentConfig.nodes).map((key) => {
        const extraArgs = launchVars[key]["extraArgs"]?.split("\n");
        argumentRules[key] = [
          `--rpc-cors=${launchVars[key]["--rpc-cors"]}`,
          `--ws-external=${launchVars[key]["--ws-external"]}`,
          `--rpc-external=${launchVars[key]["--rpc-external"]}`,
          `--rpc-methods=${launchVars[key]["--rpc-methods"]}`,
          `--name=${launchVars[key]["--name"]}`,
          ...extraArgs,
        ];
      });
      setChainCurrentConfig({
        ...chainCurrentConfig,
        monitoring: values.monitoring,
        monitoringDashboard: values.monitoringDashboard,
        argumentRules: argumentRules,
        launchVars: launchVars,
      });

      setChainCurrentStep(chainCurrentStep + 1);
    },
  });

  useEffect(() => {
    setActiveNodeType(Object.keys(chainCurrentConfig.nodes)[0]);
    if (chainCurrentConfig.monitoringDashboard) {
      handleRadioChange(chainCurrentConfig.monitoringDashboard, "monitoring");
    }
  }, []);

  const ErrorDisplay = ({ name }) => {
    return (
      <div
        className={`${
          formik.touched[name] && formik.errors[name]
            ? "inline-block"
            : "hidden"
        } app_form-error`}
      >
        {formik.touched[name] && formik.errors[name] && formik.errors[name]}
      </div>
    );
  };

  const handleRadioChange = (value, type) => {
    if (type === "monitoring") {
      const radio1 = document.querySelector("#grafana-radio");
      const radio2 = document.querySelector("#blockops-radio");
      if (radio1 && radio2) {
        if (value === "grafana") {
          radio1.checked = true;
          radio2.checked = false;
        }
        if (value === "blockops") {
          radio2.checked = true;
          radio1.checked = false;
        }
        formik.setFieldValue("monitoringDashboard", value);
      }
    }
  };

  return (
    <div className="step">
      <ProgressBar />
      <h2 className="self-start pb-5">Launch Configuration</h2>
      <form onSubmit={formik.handleSubmit} className="w-full">
        <div className="flex items-center mb-10 gap-5">
          <label className="switch">
            <input
              className="radio-slider-input"
              type="checkbox"
              onChange={(e) => {
                formik.setFieldValue("monitoring", e.target.checked);
                setMonitoring(e.target.value);
              }}
              defaultChecked={chainCurrentConfig.monitoring}
            />
            <span className="radio-slider round"></span>
          </label>
          <p>Enable monitoring</p>
        </div>

        <div className="w-full lg:w-[60%]">
          {formik.values.monitoring && (
            <div>
              <div className="w-full mb-5">
                <div className="app_form">
                  <div className="grid sm:grid-cols-2 grid-cols-1 gap-3 md:gap-8">
                    <button
                      type="button"
                      className={`
                          flex items-center gap-2 pl-5 pr-2 h-[50px] border-[1px] border-[#B8B8B8] rounded-[8px]
                          ${
                            formik.values.monitoringDashboard === "grafana"
                              ? "border-[#2180FF] bg-[#E9F1FF]"
                              : ""
                          }`}
                      onClick={() => {
                        handleRadioChange("grafana", "monitoring");
                      }}
                    >
                      <input
                        type={"radio"}
                        name={"grafana"}
                        id={`${"grafana"}-radio`}
                        value={"grafana"}
                      />
                      <div className={`text-[#717171]`}>
                        <div className="flex items-center gap-2 text-[14px] text-black font-[600]">
                          <img src={GrafanaIcon} alt={""} width={20} />
                          <p>Grafana dashboard</p>
                        </div>
                      </div>
                    </button>
                    <button
                      type="button"
                      className={`
                          flex items-center gap-2 pl-5 pr-2 h-[50px] border-[1px] border-[#B8B8B8] rounded-[8px]
                          ${
                            formik.values.monitoringDashboard === "blockops"
                              ? "border-[#2180FF] bg-[#E9F1FF]"
                              : ""
                          }`}
                      onClick={() => {
                        handleRadioChange("blockops", "monitoring");
                      }}
                    >
                      <input
                        type={"radio"}
                        name={"blockops"}
                        id={`${"blockops"}-radio`}
                        value={"blockops"}
                      />
                      <div className={`text-[#717171]`}>
                        <div className="flex items-center gap-2 text-[14px] text-black font-[600]">
                          <img src={BlockopsIcon} alt={""} width={20} />
                          <p>Blockops dashboard</p>
                        </div>
                      </div>
                    </button>
                  </div>
                  <ErrorDisplay name={"monitoringDashboard"} />
                </div>
              </div>
            </div>
          )}
        </div>

        <h2 className="self-start pb-5 pt-5">Network specifications</h2>

        <div className="w-fit bg-[#F1F6FF] p-3 flex items-center rounded-[8px] gap-5">
          {Object.keys(chainCurrentConfig?.nodes).map((key, index) => {
            return (
              <button
                key={index}
                className={`py-2 px-3 rounded-[8px] text-[16px] ${
                  activeNodeType === key
                    ? "bg-blue text-white"
                    : "bg-transparent"
                }`}
                type="button"
                onClick={() => setActiveNodeType(key)}
              >
                {key === "rpc" ? `RPC` : `${capitalizeString(key)}`} Node
              </button>
            );
          })}
        </div>

        <div className="w-full mb-5 mt-5">
          <div className="app_form">
            <div className="w-full flex flex-wrapjustify-center md:gridmd:grid-cols-2lg:grid-cols-3gap-5 mt-2">
              <div className="relative w-full">
                <div className="w-full min-h-[350px] pt-20 bg-[#292936] text-white text-[13px] px-5 py-7 rounded-[8px] overflow-scroll">
                  <p>{`--base-path=/chain-data`}</p>
                  <p>{`--rpc-cors=${
                    launchVars[activeNodeType.toLowerCase()]["--rpc-cors"]
                  }`}</p>
                  <p>{`--port=<system generated>`}</p>
                  <p>{`--rpc-port=9933`}</p>
                  <p>{`--ws-port=9944`}</p>
                  {launchVars[activeNodeType.toLowerCase()][
                    "--ws-external"
                  ] && <p>--ws-external</p>}
                  {launchVars[activeNodeType.toLowerCase()][
                    "--rpc-external"
                  ] && <p>--rpc-external</p>}
                  <p>{`--node-key=<system generated>`}</p>
                  <p>{`--rpc-methods=${
                    launchVars[activeNodeType.toLowerCase()]["--rpc-methods"]
                  }`}</p>
                  <p>{`--name=${
                    launchVars[activeNodeType.toLowerCase()]["--name"]
                  }`}</p>
                  <p>{`--chain=${
                    appConfig.networks[chainCurrentConfig.networkId].name
                  }`}</p>
                  {launchVars[activeNodeType.toLowerCase()]["extraArgs"]
                    ?.split("\n")
                    ?.map((arg, index) => {
                      return <p key={index}>{arg}</p>;
                    })}
                </div>
                <div className="w-full h-20 absolute top-0 left-0 bg-[#292936] rounded-t-[8px] flex justify-between px-5 pt-5">
                  <div className="flex items-center justify-center p-2 h-10 bg-[#383847] text-[14px] text-white font-[500] rounded-full">
                    Argument rules
                  </div>
                  <button
                    type="button"
                    className="flex items-center justify-center px-2 py-1 h-7 bg-[#383847] text-[10px] text-white font-[500] rounded-full"
                    onClick={() => {
                      const newVars = { ...launchVars };
                      newVars[activeNodeType.toLowerCase()] = defaultVars;
                      setLaunchVars(newVars);
                      const radio1 = document.querySelector(
                        `#${activeNodeType}---ws-external`
                      );
                      const radio2 = document.querySelector(
                        `#${activeNodeType}---rpc-external`
                      );
                      if (radio1) radio1.checked = true;
                      if (radio2) radio2.checked = true;
                    }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            <ErrorDisplay name={"argumentRules"} />
          </div>
        </div>

        <div className="flex flex-col gap-2 app_form py-5">
          <ArgumentInput keyName={"Key name"} value={"Value"} header />
          {appConfig.argumentRules.map((arg, index) => {
            let argInput;
            if (arg.type === "string") {
              argInput = (
                <input
                  key={`${activeNodeType}-${arg.key}`}
                  type="text"
                  id={`argrule-${index}`}
                  className={`border-[1px] border-[#B8B8B8] focus:outline-none ticket-input`}
                  defaultValue={
                    launchVars[activeNodeType.toLowerCase()][arg.key]
                  }
                  onChange={(e) => {
                    const newVars = { ...launchVars };
                    newVars[activeNodeType.toLowerCase()][arg.key] =
                      e.target.value;
                    setLaunchVars(newVars);
                  }}
                />
              );
            } else {
              argInput = (
                <div className="flex items-center">
                  <label className="switch">
                    <input
                      id={`${activeNodeType}-${arg.key}`}
                      key={`${activeNodeType}-${arg.key}`}
                      className="radio-slider-input"
                      type="checkbox"
                      onChange={(e) => {
                        const newVars = { ...launchVars };
                        newVars[activeNodeType.toLowerCase()][arg.key] =
                          e.target.checked;
                        setLaunchVars(newVars);
                      }}
                      defaultChecked={
                        launchVars[activeNodeType.toLowerCase()][arg.key]
                      }
                    />
                    <span className="radio-slider round"></span>
                  </label>
                </div>
              );
            }

            return (
              <div key={index}>
                <ArgumentInput keyName={arg.key} value={argInput} />
              </div>
            );
          })}
          <ArgumentInput
            keyName={"Extra Args"}
            value={
              <textarea
                className="w-full textarea-input outline-none"
                placeholder="--argName=config"
                defaultValue={
                  launchVars[activeNodeType.toLowerCase()]["extraArgs"]
                }
                onChange={(e) => {
                  const newVars = { ...launchVars };
                  newVars[activeNodeType.toLowerCase()]["extraArgs"] =
                    e.target.value;
                  setLaunchVars(newVars);
                }}
              />
            }
          />
        </div>

        <button type="submit" className="app_button bottom-10 mt-8 full">
          Continue
        </button>
      </form>
    </div>
  );
};

export default Step4;
