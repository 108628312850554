import { appConfig } from "../../../config";
import { ReactComponent as DedicatedProject } from "../../../assets/svg/icons/dedicated-project.svg";
import { ReactComponent as Options } from "../../../assets/svg/icons/options.svg";
import { ReactComponent as PauseIcon } from "../../../assets/svg/icons/pause.svg";
import { ReactComponent as ResumeIcon } from "../../../assets/svg/icons/play_arrow.svg";
import { ReactComponent as Delete } from "../../../assets/svg/icons/tiny-bin-grey.svg";
import { networkIcons } from "../../api-services/Networks";
import { useEffect, useState } from "react";
import { capitalizeString, getDateTime } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { PauseModal, ResumeModal, TerminateModal } from "../Modals";

const StatusDiv = ({ status }) => {
  const colorMapping = {
    active: {
      style: "bg-[#ECFBEF] text-[#2C9067] border-[#2C9067]",
      bg: "bg-[#2C9067]",
    },
    pause: {
      style: "bg-[#FFEADB] text-[#C06C30] border-[#C06C30]",
      bg: "bg-[#C06C30]",
    },
    terminate: {
      style: "bg-[#FFDBDB] text-[#C03030] border-[#C03030]",
      bg: "bg-[#C03030]",
    },
  };
  return (
    <div
      className={`${colorMapping[status].style} border-[0.5px] rounded-[85px] px-2 py-[2px] flex items-center gap-1 capitalize font-[400]`}
    >
      <div className={`w-2 h-2 rounded-full ${colorMapping[status].bg}`}></div>
      <p>
        {status === "pause" || status === "terminate" ? `${status}d` : status}
      </p>
    </div>
  );
};

const ProjectRow = ({ project, header }) => {
  const navigate = useNavigate();
  const [cloudProvider, setCloudProvider] = useState({});
  const [selectedOption, setSelectedOption] = useState("");
  const [pauseModalIsOpen, setPauseModalIsOpen] = useState(false);
  const [resumeModalIsOpen, setResumeModalIsOpen] = useState(false);
  const [terminateModalIsOpen, setTerminateModalIsOpen] = useState(false);
  const [liveProjectStatus, setLiveProjectStatus] = useState(project?.status);
  const [projectNetwork, setProjectNetwork] = useState("");

  useEffect(() => {
    if (project) {
      const cProvider = appConfig.cloudProviders.filter((provider) => {
        return provider.slug === project.cloudProvider;
      });
      setLiveProjectStatus(project?.status);
      setCloudProvider(cProvider[0]);
    }
  }, [project]);

  useEffect(() => {
    setLiveProjectStatus(project?.status);
  }, [pauseModalIsOpen, terminateModalIsOpen, resumeModalIsOpen]);

  return (
    <div className="w-full">
      {header ? (
        <div className="flex items-center text-[12px] text-[#12121299] pl-5 pb-4">
          <div className="w-[55%] md:w-[35%] lg:w-[20%] h-full flex items-center gap-2">
            Project Name
          </div>
          <div className="hidden md:w-[30%] lg:w-[15%] h-full md:flex items-center ml-4">
            Network
          </div>
          <div className="hidden lg:w-[25%] h-full lg:flex items-center gap-1">
            Cloud Provider
          </div>
          <div className="w-[40%] md:w-[30%] lg:w-[15%] h-full flex items-center">
            Status
          </div>
          <div className="hidden w-[20%] h-full lg:flex items-center">
            Date Created
          </div>
        </div>
      ) : (
        <div
          className="relative bg-white w-full cursor-pointer h-[70px] rounded-[16px] pl-5 flex items-center border-[1px] border-white hover:border-blue text-[13px] font-[600]"
          onClick={() => navigate(`/nodes/parachain/${project.parachainId}`)}
        >
          <div className="w-[55%] md:w-[35%] lg:w-[20%] h-full flex items-center gap-2">
            <DedicatedProject />
            <p className="truncate overflow-ellipsis pr-2">{`${project.projectName}`}</p>
          </div>
          <div className="hidden md:w-[30%] lg:w-[15%] h-full md:flex items-center">
            <div className="scale-[0.5]">
              <p>{networkIcons[capitalizeString(project.networkName)]}</p>
            </div>
            <p className="-ml-2 truncate overflow-ellipsis pr-2">
              {capitalizeString(projectNetwork)}
            </p>
          </div>
          <div className="hidden lg:w-[25%] h-full lg:flex items-center gap-1">
            {cloudProvider && (
              <img src={cloudProvider.icon} alt="" width={25} />
            )}
            <p className="truncate overflow-ellipsis pr-2">
              {cloudProvider?.label}
            </p>
          </div>
          <div className="w-[40%] md:w-[30%] lg:w-[15%] h-full flex items-center">
            {liveProjectStatus && <StatusDiv status={liveProjectStatus} />}
          </div>
          <div className="hidden w-[20%] h-full lg:flex items-center">
            <p className="font-[400]">{getDateTime(project.createdAt).date}</p>
            <p className="text-[11px] text-[#12121299] ml-2 truncate overflow-ellipsis pr-2">
              {getDateTime(project.createdAt).time}
            </p>
          </div>
          <button
            type="button"
            className="w-[5%] flex items-center justify-center cursor-auto"
            onClick={(e) => {
              e.stopPropagation();
              if (selectedOption === project.id) {
                setSelectedOption("");
              } else {
                setSelectedOption(project.id);
              }
            }}
            onBlur={() => setSelectedOption("")}
          >
            <Options />
            {selectedOption === project?.id && (
              <div className="absolute z-[9999] right-0 top-[75px] h-[55px] w-[180px] text-[14px] font-[200] text-[#717171] flex flex-col justify-center items-center bg-white px-5 pb-0 rounded-[8px] shadow-md">
                <div
                  onClick={() => {
                    if (liveProjectStatus === "active") {
                      setTerminateModalIsOpen(!terminateModalIsOpen);
                    }
                  }}
                  className={`flex items-center gap-2 py-3 cursor-pointer ${
                    liveProjectStatus === "terminate" &&
                    "cursor-not-allowed opacity-70"
                  }`}
                >
                  <Delete />
                  <p>Terminate</p>
                </div>
              </div>
            )}
          </button>
        </div>
      )}
      <div>
        <TerminateModal
          terminateModalIsOpen={terminateModalIsOpen}
          setTerminateModalIsOpen={setTerminateModalIsOpen}
          project={project}
          updateProjectStatus={setLiveProjectStatus}
          projectType={"parachain"}
        />
      </div>
    </div>
  );
};

export default ProjectRow;
