import { useEffect, useRef, useState } from "react";
import { NETWORK_STATE, alertError, alertSuccess } from "../../../utils";
import Loader from "../../../assets/img/loader.gif";
import { ReactComponent as DedicatedIcon } from "../../../assets/svg/icons/dedicated.svg";
import { ReactComponent as Plus } from "../../../assets/svg/icons/add-nodes.svg";
import { useAuth } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";
import ProjectRow from "../../../components/nodes/dedicated-nodes/ProjectRow";
import {
  getDedicatedProjects,
  getPendingParachainProjects,
} from "../../../services/ProjectsService";
import ParachainNodes from "../parachain-nodes/ParachainNodes";
import { useLiveUpdates } from "../../../context/liveUpdates";
import Modal from "react-modal";
import { useHeader } from "../../../context/headerContext";
import CancelModal from "../../../assets/img/cancel-modal.png";

// Styles for modal
const customStyles = {
  overlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    opacity: "1",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "12px",
  },
};

const DedicatedNodes = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [activeProjects, setActiveProjects] = useState([]);
  const [status, setStatus] = useState(NETWORK_STATE.LOADING);
  const [deployedParaProjects, setDeployedParaProjects] = useState([]);
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const { minimizeNav } = useHeader();
  const [allPendingProjects, setAllPendingProjects] = useState({
    creating: [],
    deleting: [],
  });
  const {
    lastEvent,
    setLastEvent,
    reloadNotifications,
    setReloadNotifications,
  } = useLiveUpdates();

  const closeCreateModal = () => {
    setCreateModalIsOpen(false);
  };

  const fetchDedicatedProjects = async () => {
    setStatus(NETWORK_STATE.LOADING);
    try {
      const response = await getDedicatedProjects();
      const userProjects = response.data.data?.rows;
      const sortedProjects = userProjects?.sort((a, b) => {
        if (a.status === "active") return -1;
        else return 1;
      });
      userProjects && setActiveProjects(sortedProjects);
      setStatus(NETWORK_STATE.SUCCESS);
    } catch (err) {
      if (err.response.status === 401) {
        alertError(err.response.data.message);
        auth.logout();
      }
      setStatus(NETWORK_STATE.ERROR);
    }
  };

  const resetPendingProjects = (newState) => {
    setAllPendingProjects(newState);
  };

  const fetchParaPendingProjects = async () => {
    setTimeout(async () => {
      try {
        const response = await getPendingParachainProjects();
        const pendingProjects = response.data?.data;
        const newState = {
          creating: [],
          deleting: [],
        };
        pendingProjects.forEach((operation) => {
          const operationName = Object.keys(operation)[0];
          if (operationName.split(":")[0] === "createParachain") {
            newState.creating.push(operation[operationName]);
          } else {
            newState.deleting.push(operation[operationName]);
          }
        });
        resetPendingProjects(newState);
      } catch (err) {
        console.log(err);
      }
      setReloadNotifications(false);
    }, 2000);
  };

  useEffect(() => {
    auth.setHeaderText(["Dedicated Nodes"]);
    fetchDedicatedProjects();
    fetchParaPendingProjects();
  }, []);

  useEffect(() => {
    if (lastEvent.event) {
      if (lastEvent.event === "deleteParachain") {
        alertSuccess("Parachain project terminated!");
      } else if (lastEvent.event === "createParachain") {
        alertSuccess("Parachain project created!");
      }
      setLastEvent({});
    }
  }, [lastEvent]);

  useEffect(() => {
    reloadNotifications && fetchParaPendingProjects();
  }, [reloadNotifications]);

  document.title = "Blockops | Dedicated Nodes";

  return (
    <div className="w-full">
      <div className="dedicated">
        {(allPendingProjects.deleting.length > 0 ||
          allPendingProjects.creating.length > 0) && (
          <div className="flex flex-col gap-5 md:flex-row items-start md:items-center justify-end mt-3 pb-5">
            {allPendingProjects.deleting.length > 0 && (
              <div className="relative text-[#C03030] font-[500] text-sm shadow-md rounded-[85px] px-4 py-3 bg-white flex items-center gap-2">
                <div className="w-2 h-2 bg-[#C03030] rounded-full"></div>
                <p>Deleting in progress</p>
                <p className="absolute h-5 w-5 -top-[10px] flex items-center justify-center right-1 bg-[#C03030] rounded-full text-white text-sm">
                  {allPendingProjects.deleting.length}
                </p>
              </div>
            )}
            {allPendingProjects.creating.length > 0 && (
              <div className="relative text-[#2C9067] font-[500] text-sm shadow-md rounded-[85px] px-4 py-3 bg-white flex items-center gap-2">
                <div className="w-2 h-2 bg-[#2C9067] rounded-full"></div>
                <p>Creating in progress</p>
                <p className="absolute h-5 w-5 -top-[10px] flex items-center justify-center right-1 bg-[#2C9067] rounded-full text-white text-sm">
                  {allPendingProjects.creating.length}
                </p>
              </div>
            )}
          </div>
        )}
        {status === NETWORK_STATE.LOADING ? (
          <img src={Loader} alt="Loading..." className="self-center py-20" />
        ) : NETWORK_STATE.SUCCESS && activeProjects.length === 0 ? (
          deployedParaProjects.length === 0 && (
            <div className="dedicated__page">
              <h2>Connect to Network</h2>
              <div className="dedicated__page-empty">
                <DedicatedIcon className="scale-50 md:scale-[0.8]" />
                <div className="flex gap-3">
                  <button
                    className="button button__blue"
                    onClick={() => navigate("/nodes/dedicated/create")}
                  >
                    <p>Deploy dedicated node</p>
                  </button>
                  <button
                    className="button button__white"
                    onClick={() => navigate("/nodes/parachain/create")}
                  >
                    <p>Deploy Parachain node</p>
                  </button>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="dedicated__page">
            <div className="flex flex-col gap-5 md:flex-row items-start md:items-center justify-between">
              <h2>Single Nodes ({activeProjects.length})</h2>
              {/* <button
                type="button"
                className="app_button self-end"
                onClick={() => navigate("/dedicated-nodes/create")}
              >
                <Plus />
                Create Project
              </button> */}
            </div>
            <div className="flex flex-col gap-2 pb-10">
              <ProjectRow header />
              <div className={`flex flex-col gap-3`}>
                {activeProjects.map((project, index) => {
                  return (
                    <div key={index}>
                      <ProjectRow project={project} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
      <ParachainNodes setDeployedParaProjects={setDeployedParaProjects} />
      <button
        className="fixed bottom-4 right-[40px] w-20 h-20 bg-blue rounded-full flex items-center justify-center"
        onClick={() => {
          setCreateModalIsOpen(true);
        }}
      >
        <Plus />
      </button>

      <Modal
        isOpen={createModalIsOpen}
        onRequestClose={closeCreateModal}
        style={customStyles}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        contentLabel="Create Project Modal"
        overlayClassName={`
          left-0
          ${minimizeNav ? "xl:left-[70px]" : "xl:left-[270px]"}`}
        className="absolute bg-white"
        parentSelector={() => document.querySelector(".block-app")}
      >
        <div className="relative w-full h-[515px] lg:w-[800px] flex flex-col items-center justify-center bg-white p-6 rounded-[12px]">
          <div className="w-full absolute top-6 right-6 flex justify-end items-center self-start">
            <button onClick={() => closeCreateModal()}>
              <img src={CancelModal} alt="" width={22} />
            </button>
          </div>

          <DedicatedIcon className="h-[206px]" />
          <div className="flex gap-3">
            <button
              className="node-button node-button__blue"
              onClick={() => navigate("/nodes/dedicated/create")}
            >
              <p>Deploy dedicated node</p>
            </button>
            <button
              className="node-button node-button__white"
              onClick={() => navigate("/nodes/parachain/create")}
            >
              <p>Deploy Parachain node</p>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DedicatedNodes;
