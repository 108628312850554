import { ActiveStatus } from "../status/Status";
import { appConfig } from "../../config";
import { getDateTime } from "../../utils";
import { getNetworkStatus } from "../../services/NetworkService";
import { networkIcons } from "./Networks";
import { ReactComponent as Delete } from "../../assets/svg/icons/tiny-bin-grey.svg";
import { ReactComponent as Open } from "../../assets/svg/icons/file_open.svg";
import { ReactComponent as Options } from "../../assets/svg/icons/options.svg";
import { ReactComponent as TerminateBin } from "../../assets/svg/icons/bin.svg";
import { useEffect, useState } from "react";
import { useHeader } from "../../context/headerContext";
import { useNavigate } from "react-router-dom";
import CancelModal from "../../assets/img/cancel-modal.png";
import Modal from "react-modal";

const ProjectRow = ({
  project,
  header,
  selectItem,
  allSelectedItems,
  selectedOption,
  setSelectedOption,
  deleteProject,
}) => {
  const navigate = useNavigate();
  const dateTime = getDateTime(project?.createdAt);
  const [deleting, setDeleting] = useState(false);
  const { minimizeNav } = useHeader();

  const [networkStatus, setNetworkStatus] = useState("active");
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState("");

  const projectNetwork = appConfig.networks[project?.networkId];

  // Styles for modal
  const customStyles = {
    overlay: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      opacity: "1",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px",
    },
  };

  const fetchStatus = async (id) => {
    try {
      const response = await getNetworkStatus(id);
      if (response?.data?.data?.result[0]?.value[1] === "1") {
        setNetworkStatus("active");
      } else if (response?.data?.data?.result[0]?.value[1] === "0") {
        setNetworkStatus("Inactive");
      } else {
        setNetworkStatus("active");
      }
    } catch {
      setNetworkStatus("active");
    }
  };

  useEffect(() => {
    (project?.networkId === 1 || project?.networkId === 2) &&
      fetchStatus(project?.networkId);
  }, []);

  const handleOptionClick = () => {
    if (selectedOption !== project?.id) {
      setSelectedOption(project?.id);
    } else {
      setSelectedOption(null);
    }
  };

  const openDeleteModal = () => {
    setDeleteModalIsOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalIsOpen(false);
  };

  return (
    <div
      className={`
        ${
          header
            ? "bg-[#EFF5FF] cursor-default h-[80px]"
            : "bg-white border-b border-[#B9BBBE] cursor-pointer h-[88px]"
        }
         w-full flex items-center text-16 px-3 md:px-8
        ${deleting ? "opacity-[0.6]" : ""}`}
    >
      {header ? (
        <div className="w-full h-full flex items-center text-14 font-[500]">
          <div className="w-[60%] md:w-[35%] overflow-ellipses truncate pr-5">
            Project Name
          </div>
          <div className="w-[40%] md:w-[25%]">Network</div>
          <div className="w-[20%] md:w-[25%] hidden md:flex">Date created</div>
          <div className="hidden md:flex w-[10%]">Status</div>
        </div>
      ) : (
        <div
          onClick={() => navigate(`/api-services/${project.id}`)}
          className="w-full h-full flex items-center"
        >
          <div className="w-[60%] md:w-[35%] overflow-ellipses truncate pr-5">
            {project.projectName}
          </div>
          <div className="w-[40%] md:w-[25%] flex items-center gap-1 small-network-svg">
            {projectNetwork?.name && networkIcons[projectNetwork.name]}
            {projectNetwork?.name}
          </div>
          <div className="w-[20%] md:w-[25%] hidden md:flex">
            <p>
              {dateTime.date}{" "}
              <span className="text-[12px] text-[#686A6D]">
                {`${dateTime.time}${dateTime.ampm}`}
              </span>
            </p>
          </div>
          <div className="hidden md:flex w-[10%]">
            {networkStatus === "active" && <ActiveStatus text={"Active"} />}
          </div>
        </div>
      )}
      <div className="w-5 md:w-10 relative z-[999] flex justify-end">
        <button
          className={`${
            header
              ? "hidden"
              : "w-3 flex justify-center md:justify-ends cursor-default"
          }`}
          onClick={handleOptionClick}
          onBlur={() => setSelectedOption(null)}
        >
          <Options />
          {selectedOption === project?.id && (
            <div className="absolute right-10 top-0 w-[200px] text-[14px] font-[200] text-[#717171] flex flex-col bg-white p-5 pb-0 rounded-[8px] shadow-md">
              <div
                onClick={() => navigate(`/api-services/${project.id}`)}
                className="flex items-center gap-2 py-4 pl-5 border-b-[0.5px] border-b-[#D8D8D8] cursor-pointer"
              >
                <Open className="scale-90" />
                <p>Open</p>
              </div>
              <div
                onClick={() => {
                  openDeleteModal();
                }}
                className="flex items-center gap-2 py-4 pl-5 cursor-pointer"
              >
                <Delete className="scale-90" />
                <p>Terminate</p>
              </div>
            </div>
          )}
        </button>
      </div>

      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={closeDeleteModal}
        style={customStyles}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        contentLabel="Delete Project Modal"
        overlayClassName={`
          left-0
          ${minimizeNav ? "xl:left-[70px]" : "xl:left-[270px]"}`}
        className="absolute bg-white"
        parentSelector={() => document.querySelector(".block-app")}
      >
        <div className="w-full sm:w-[350px] flex flex-col px-5 py-7 gap-5">
          <div className="w-full flex justify-end items-center">
            <button onClick={() => setDeleteModalIsOpen(false)}>
              <img src={CancelModal} alt="" width={22} />
            </button>
          </div>
          <div className="h-[130px] w-[130px] flex items-center justify-center self-center mb-5 bg-[#D9E7FF] rounded-full">
            <TerminateBin className="scale-[0.9]" />
          </div>
          <div className="self-center">
            <h2 className="text-[18px] leading-[15px] text-center font-[600]">
              Are you sure?
            </h2>
            <p className="mt-1 text-[12px] text-[#1F1F1F99] text-center">
              Please input "terminate" if you are sure you want to delete this
              project.
            </p>
          </div>
          <div className="w-full">
            <input
              type={"text"}
              onChange={(e) => setDeleteConfirmation(e.target.value)}
              className="w-full block h-[50px] px-4 rounded-[8px] text-[16px] border-[1px] border-[#1212124d] outline-none"
            />
            <button
              type="button"
              disabled={deleteConfirmation.toLowerCase() !== "terminate"}
              onClick={() => {
                closeDeleteModal();
                deleteProject(project.id);
                setDeleting(true);
              }}
              className="app_button full mt-5"
            >
              Terminate project
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProjectRow;
