import { useEffect } from "react";
import { useAuth } from "../../../context/authContext";
import { ReactComponent as Back } from "../../../assets/svg/icons/back.svg";
import { useStepper } from "../../../context/stepperContext";
import { Steps } from "../../../components/nodes/dedicated-nodes/Steps";
import { useNavigate } from "react-router-dom";
import { appConfig } from "../../../config";

const CreateNode = () => {
  const { Step1, Step2, Step3, Step4 } = Steps;
  const auth = useAuth();
  const navigate = useNavigate();
  const {
    dedicatedCurrentStep,
    setDedicatedCurrentStep,
    dedicatedCurrentConfig,
    setDedicatedCurrentConfig,
  } = useStepper();

  useEffect(() => {
    auth.setHeaderText(["Dedicated Nodes", "Create"]);
    setDedicatedCurrentStep(1);
  }, []);

  useEffect(() => {
    document.querySelector("#createNode-top").scrollIntoView();
  }, [dedicatedCurrentStep]);

  return (
    <div className="create" id="createNode-top">
      <button
        className="create__back"
        onClick={() => {
          setDedicatedCurrentStep(dedicatedCurrentStep - 1);
          dedicatedCurrentStep === 1 && setDedicatedCurrentConfig({});
          dedicatedCurrentStep === 1 && navigate(appConfig.nav.routes.nodes);
        }}
      >
        <Back className="scale-[0.9]" />
        <p>{dedicatedCurrentStep === 1 ? "Cancel" : "Go Back"}</p>
      </button>
      {dedicatedCurrentStep === 1 ? (
        <Step1 />
      ) : dedicatedCurrentStep === 2 ? (
        <Step2 />
      ) : dedicatedCurrentStep === 3 ? (
        <Step3 />
      ) : dedicatedCurrentStep === 4 ? (
        <Step4 />
      ) : (
        <></>
      )}
    </div>
  );
};
export default CreateNode;
