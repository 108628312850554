import { ReactComponent as Plus } from "../../assets/svg/icons/plus.svg";
import { ReactComponent as Ticket } from "../../assets/svg/icons/ticket.svg";
import { useAuth } from "../../context/authContext";
import { useEffect, useState } from "react";
import Logo from "../../assets/img/app-logo.png";
import { openTickets, closedTickets } from "./data";
import CreateTicket from "./CreacteTicket";
import Tickets from "./Tickets";
import SendMail from "./SendMail";

const Support = () => {
  const auth = useAuth();
  const [openForm, setOpenForm] = useState(false);
  const [tickets, setTickets] = useState(openTickets);
  const [openTicketsActive, setOpenTicketsActive] = useState(true);
  const [closedTicketsActive, setClosedTicketsActive] = useState(false);
  const [currentPage, setCurrentPage] = useState({ open: 1, closed: 1});
  const [totalPages, setTotalPages] = useState(1);
  const ticketsPerPage = 5;

  useEffect(() => {
    auth.setHeaderText(["Support"])
  }, []);

  useEffect(() => {
    if (openTicketsActive) {
      const start = (currentPage.open - 1) * ticketsPerPage;
      const end = (ticketsPerPage * (currentPage.open - 1)) + ticketsPerPage ;
      setTickets(openTickets.slice(start, end));
      setTotalPages(Math.ceil(openTickets.length / ticketsPerPage));
    } else {
      const start = (currentPage.closed - 1) * ticketsPerPage;
      const end = (ticketsPerPage * (currentPage.closed - 1)) + ticketsPerPage ;
      setTickets(closedTickets.slice(start, end));
      setTotalPages(Math.ceil(closedTickets.length / ticketsPerPage));
    }
  }, [openTicketsActive, currentPage])

  return (
    <div className="support-page">
      <div className="support-page__header">
        <h2>Contact us</h2>
        {/* <h2>My Tickets</h2>
        <button className="create-ticket" onClick={() => setOpenForm(true)}>
          <Plus />
          Create Ticket
        </button> */}
      </div>
      <div className="support-page__body">
        <div className="support-page__body-sendMail">
          <SendMail />
        </div>
      </div>
      {/* <div className="support-page__body">
        {
          !openForm
          ?
          (
            tickets.length > 0
            ?
            <div className="support-page__body-tickets">
              <Tickets
                tickets={tickets}
                openTicketsActive={openTicketsActive}
                setOpenTicketsActive={setOpenTicketsActive}
                closedTicketsActive={closedTicketsActive}
                setClosedTicketsActive={setClosedTicketsActive}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
              />
            </div>
            :
            <div className="support-page__body-empty">
              <Ticket />
              <div className="flex items-center">
                <img src={Logo} alt="Blockops" width={200} />
                <h2 className="text-[22px] font-[700]">Support Center</h2>
              </div>
              <p className="text-[#535353] text-[14px] max-w-[350px] text-center">
                You currently have no tickets created, click link below to create a new ticket.
              </p>
              <button className="create-ticket mt-5" onClick={() => setOpenForm(true)}>
                <Plus />
                Create Ticket
              </button>
            </div>
          )
          :
          <CreateTicket setOpenForm={setOpenForm} />
        }
      </div> */}
    </div>
  )
}

export default Support;
