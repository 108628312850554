/* import { ReactComponent as Campaign } from "../../../assets/svg/icons/campaign.svg"; */
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { ErrorText } from "../../layouts/Auth";
import * as Yup from "yup";
import { useStepper } from "../../../context/stepperContext";
import { appConfig } from "../../../config";
import { convertToGigaByte } from "../../../utils";
import ProgressBar from "./ProgressBar";

export const getUnit = (value, unit) => {
  if (unit === "core") {
    return `${value} cores`;
  } else if (unit !== "byte") {
    return value;
  }

  const gbValue = convertToGigaByte(value, unit);
  if (gbValue >= 1000) {
    return `${gbValue / 1000}TB`;
  } else {
    return `${gbValue}GB`;
  }
};

const Step2 = () => {
  /* const [nodeSpecsMode, setNodeSpecsMode] = useState("default"); */
  /* const [managementType, setManagementType] = useState("fullyManaged"); */
  const {
    chainCurrentStep,
    setChainCurrentStep,
    chainCurrentConfig,
    setChainCurrentConfig,
  } = useStepper();
  const [networkId] = useState(chainCurrentConfig.networkId);
  /* const [cloudProviders] = useState(
    appConfig.networks[networkId].parameters.v1.cloudProviders
  ); */
  const [nodeSpecs] = useState(appConfig.networks[networkId].specifications.v1);

  const formik = useFormik({
    initialValues: {
      projectName: chainCurrentConfig.projectName || "",
      binaryLink: chainCurrentConfig.binaryLink || "",
      binaryName: chainCurrentConfig.binaryName || "",
      softwareVersion: chainCurrentConfig.softwareVersion || "",
    },
    validationSchema: Yup.object({
      projectName: Yup.string()
        .required(<ErrorText text="Project name is required." />)
        .min(3, "Minimum of 3 characters")
        .matches(
          /^(?:[a-z](?:[-a-z0-9]{0,61}[a-z0-9])?)$/,
          "Name must be small letters without a space or special characters."
        ),
      binaryLink: Yup.string()
        .required(<ErrorText text="Github link is required." />)
        .min(3, "Minimum of 3 characters")
        .matches(
          /^https?:\/\/github\.com\/[\w-]+\/[\w-]+\/releases\/download\/.+$/,
          "Please enter a valid github release link."
        ),
      binaryName: Yup.string()
        .required(<ErrorText text="Binary name is required." />)
        .min(3, "Minimum of 3 characters"),
      softwareVersion: Yup.string()
        .required(<ErrorText text="Software version is required." />)
        .min(2, "Minimum of 2 characters"),
    }),
    onSubmit: async (values) => {
      setChainCurrentConfig({
        ...chainCurrentConfig,
        projectName: values.projectName,
        binaryLink: values.binaryLink,
        binaryName: values.binaryName,
        softwareVersion: values.softwareVersion,
        metadata: { ...chainCurrentConfig.metadata },
      });

      setChainCurrentStep(chainCurrentStep + 1);
    },
  });

  const ErrorDisplay = ({ name }) => {
    return (
      <div
        className={`${
          formik.touched[name] && formik.errors[name]
            ? "inline-block"
            : "hidden"
        } app_form-error`}
      >
        {formik.touched[name] && formik.errors[name] && formik.errors[name]}
      </div>
    );
  };

  /* const handleRadioChange = (value) => {
    const radio1 = document.querySelector("#gcp-radio");
    const radio2 = document.querySelector("#aws-radio");
    if (value === "gcp") {
      radio1.checked = true;
      radio2.checked = false;
    }
    if (value === "aws") {
      radio2.checked = true;
      radio1.checked = false;
    }
    formik.setFieldValue("cloudProvider", value);
  }; */

  /* useEffect(() => {
    if (chainCurrentConfig.cloudProvider) {
      handleRadioChange(chainCurrentConfig.cloudProvider);
    }
  }, []); */

  /* useEffect(() => {
    if (nodeSpecsMode === "default") {
      formik.setFieldValue("cpu", nodeSpecs["cpu"].default);
      formik.setFieldValue("memory", nodeSpecs["memory"].default);
      formik.setFieldValue("storage", nodeSpecs["storage"].default);
    }
  }, [nodeSpecsMode]); */

  return (
    <div className="step">
      <ProgressBar />
      <h2 className="self-start pb-5">Create a new Project</h2>
      <form onSubmit={formik.handleSubmit} className="w-full">
        <div className="w-full mb-5">
          <div className="app_form">
            <label htmlFor="projectName">Project name</label>
            <input
              id="projectName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter project name"
              type="text"
              name="projectName"
              value={formik.values.projectName}
              className={`border-[1px] focus:outline-none ticket-input
                ${
                  !formik.errors?.projectName && formik.touched.projectName
                    ? "focus:border-blue"
                    : ""
                }
                ${
                  formik.errors?.projectName && formik.touched.projectName
                    ? "border-[#C63737] border-[1.5px]"
                    : "border-[rgba(18,18,18,0.3)]"
                }
              `}
            />
            <ErrorDisplay name={"projectName"} />
          </div>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-5">
          <div className="w-full mb-5">
            <div className="app_form">
              <label htmlFor="binaryLink">Github release link</label>
              <input
                id="binaryLink"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter Github link"
                type="text"
                name="binaryLink"
                value={formik.values.binaryLink}
                className={`border-[1px] focus:outline-none ticket-input
                ${
                  !formik.errors?.binaryLink && formik.touched.binaryLink
                    ? "focus:border-blue"
                    : ""
                }
                ${
                  formik.errors?.binaryLink && formik.touched.binaryLink
                    ? "border-[#C63737] border-[1.5px]"
                    : "border-[rgba(18,18,18,0.3)]"
                }
              `}
              />
              <ErrorDisplay name={"binaryLink"} />
            </div>
          </div>

          <div className="w-full mb-5">
            <div className="app_form">
              <label htmlFor="softwareVersion">Software version</label>
              <input
                id="softwareVersion"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter software version"
                type="text"
                name="softwareVersion"
                value={formik.values.softwareVersion}
                className={`border-[1px] focus:outline-none ticket-input
                ${
                  !formik.errors?.softwareVersion &&
                  formik.touched.softwareVersion
                    ? "focus:border-blue"
                    : ""
                }
                ${
                  formik.errors?.softwareVersion &&
                  formik.touched.softwareVersion
                    ? "border-[#C63737] border-[1.5px]"
                    : "border-[rgba(18,18,18,0.3)]"
                }
              `}
              />
              <ErrorDisplay name={"softwareVersion"} />
            </div>
          </div>

          <div className="w-full mb-5">
            <div className="app_form">
              <label htmlFor="binaryName">Binary name</label>
              <input
                id="binaryName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter binary name"
                type="text"
                name="binaryName"
                value={formik.values.binaryName}
                className={`border-[1px] focus:outline-none ticket-input
                ${
                  !formik.errors?.binaryName && formik.touched.binaryName
                    ? "focus:border-blue"
                    : ""
                }
                ${
                  formik.errors?.binaryName && formik.touched.binaryName
                    ? "border-[#C63737] border-[1.5px]"
                    : "border-[rgba(18,18,18,0.3)]"
                }
              `}
              />
              <ErrorDisplay name={"binaryName"} />
            </div>
          </div>
        </div>
        <button type="submit" className="app_button bottom-10 mt-8 full">
          Continue
        </button>
      </form>
    </div>
  );
};

export default Step2;
