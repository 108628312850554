import { ReactComponent as ArrowUp } from "../../assets/svg/icons/page-up-arrow.svg";
import { useState } from "react";

const PageSlider = ({ currentPage, setCurrentPage, totalPages }) => {
  const [btnFocus, setBtnFocus] = useState(false);
  return (
    <button
      type="button"
      className={`page-slider group ${
        btnFocus ? "border-[2px] border-blue" : "border border-[#686A6D]"
      }`}
      onFocus={() => setBtnFocus(true)}
      onBlur={() => setBtnFocus(false)}
    >
      <p>Page {currentPage}</p>
      <div className="page-controller">
        <button
          onClick={() => {
            currentPage < totalPages && setCurrentPage(currentPage + 1);
          }}
          className="change-btn group-hover:bg-[#D9E7FD]"
        >
          <ArrowUp fill={btnFocus ? "#045CF4" : "#515356"} />
        </button>
        <button
          onClick={() => {
            currentPage > 1 && setCurrentPage(currentPage - 1);
          }}
          className="change-btn group-hover:bg-[#D9E7FD]"
        >
          <ArrowUp
            fill={btnFocus ? "#045CF4" : "#515356"}
            className="rotate-[180deg]"
          />
        </button>
      </div>
    </button>
  );
};

export default PageSlider;
