import { Link, NavLink } from "react-router-dom";
import { ReactComponent as Dashboard } from "../../assets/svg/nav/dashboard_fill.svg";
import { ReactComponent as DataServices } from "../../assets/svg/nav/data-svc.svg";
import { ReactComponent as DedicatedIconFill } from "../../assets/svg/nav/dedicated-fill.svg";
import { ReactComponent as DedicatedIconVoid } from "../../assets/svg/nav/dedicated-void.svg";
import { ReactComponent as Documentation } from "../../assets/svg/nav/docs.svg";
import { ReactComponent as Logout } from "../../assets/svg/nav/logout.svg";
import { ReactComponent as Service } from "../../assets/svg/nav/service_fill.svg";
import { ReactComponent as Settings } from "../../assets/svg/nav/settings_fill.svg";
import { ReactComponent as SupportFill } from "../../assets/svg/nav/help_fill.svg";
import { ReactComponent as SupportVoid } from "../../assets/svg/nav/support-void.svg";
import { ReactComponent as ValidatorIconFill } from "../../assets/svg/nav/validator-fill.svg";
import { ReactComponent as ValidatorIconVoid } from "../../assets/svg/nav/validator-void.svg";
import { useAuth } from "../../context/authContext";
import Logo from "../../assets/img/app-logo.png";
import Menu from "../../assets/img/menu.png";
import React, { useEffect, useState } from "react";
import { appConfig } from "../../config";

export const Fullnav = ({ resizeNav, renderCount, mobile }) => {
  const [userSettings] = useState(
    JSON.parse(localStorage.getItem("userSettings"))
  );
  const { logout } = useAuth();
  const [currentPath, setCurrentPath] = useState("dashboard");

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, []);

  return (
    <nav
      className={`
      side-nav
      ${mobile ? "mobile-nav" : ""}
      ${
        userSettings?.dashboard?.navBar.minimize && renderCount?.current === 1
          ? "hidden"
          : "flex"
      }`}
    >
      <div className="side-nav__header">
        <Link to={appConfig.nav.routes.dashboard}>
          <img src={Logo} alt="logo" className="min-hide-logo w-[115px]" />
        </Link>
        <button
          className="navcontroller"
          onClick={() => {
            resizeNav();
          }}
        >
          <img src={Menu} alt="" width={24} />
        </button>
      </div>

      <p className="menu-text">Main menu</p>

      <div className="side-nav__cont">
        <div className="side-nav__cont-top">
          <NavLink
            className="nav_link "
            to={appConfig.nav.routes.dashboard}
            onClick={() => {
              setCurrentPath(appConfig.nav.routes.dashboard);
            }}
          >
            <Dashboard width={18} />
            <span>Dashboard</span>
          </NavLink>

          <NavLink
            className="nav_link "
            to={appConfig.nav.routes.api}
            onClick={() => {
              setCurrentPath(appConfig.nav.routes.api);
            }}
          >
            <Service width={18} />
            <span>API Services</span>
          </NavLink>

          <NavLink
            className="nav_link "
            to={appConfig.nav.routes.validator}
            onClick={() => {
              setCurrentPath(appConfig.nav.routes.validator);
            }}
          >
            {currentPath.startsWith(appConfig.nav.routes.validator) && (
              <ValidatorIconFill />
            )}
            {!currentPath.startsWith(appConfig.nav.routes.validator) && (
              <ValidatorIconVoid />
            )}
            <span>Validator Nodes</span>
          </NavLink>

          <NavLink
            className="nav_link "
            to={appConfig.nav.routes.nodes}
            onClick={() => {
              setCurrentPath(appConfig.nav.routes.nodes);
            }}
          >
            {currentPath.startsWith(appConfig.nav.routes.nodes) && (
              <DedicatedIconFill />
            )}
            {!currentPath.startsWith(appConfig.nav.routes.nodes) && (
              <DedicatedIconVoid />
            )}
            <span>Dedicated Nodes</span>
          </NavLink>

          <a
            href={appConfig.externalLinks.dataServices}
            target={"_blank"}
            className="nav_link relative"
          >
            <DataServices width={18} />
            <p className="text-white">Data Services</p>
          </a>
        </div>

        <div className="side-nav__cont-bottom">
          <p className="menu-text">Preferences</p>
          <div className="side-nav__cont-bottom-links">
            <a
              className="nav_link"
              href={appConfig.externalLinks.docs}
              target={"_blank"}
              rel="noreferrer"
            >
              <Documentation width={18} />
              <span>Documentation</span>
            </a>
            <NavLink
              className="nav_link"
              to={appConfig.nav.routes.settings}
              onClick={() => {
                setCurrentPath(appConfig.nav.routes.settings);
              }}
            >
              <Settings width={18} />
              <span>Settings</span>
            </NavLink>
            <NavLink
              className="nav_link"
              to={appConfig.nav.routes.support}
              onClick={() => {
                setCurrentPath(appConfig.nav.routes.support);
              }}
            >
              {currentPath.startsWith(appConfig.nav.routes.support) && (
                <SupportFill width={18} />
              )}
              {!currentPath.startsWith(appConfig.nav.routes.support) && (
                <SupportVoid width={18} />
              )}
              <span className="group-hover:flex">Support</span>
            </NavLink>
            <div className="nav_link cursor-pointer" onClick={() => logout()}>
              <Logout width={18} />
              <span>Logout</span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
