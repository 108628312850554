import "./App.css";
import { appConfig } from "./config";
import { Auth, ResetAuth } from "./components/layouts/Auth";
import { AuthProvider, useAuth } from "./context/authContext";
import { Dashboard } from "./components/layouts/Dashboard";
import { HeaderProvider } from "./context/headerContext";
import { LiveProvider } from "./context/liveUpdates";
import { ManualProvider } from "./context/manualContext";
import { ProjectProvider } from "./context/projectContext";
import { Route, Routes } from "react-router-dom";
import { RPCProvider } from "./context/RPCContext";
import { StepperProvider } from "./context/stepperContext";
import { Toaster } from "react-hot-toast";
import ApiServices from "./pages/api-services/ApiServices";
import AppLogin from "./pages/auth/AppLogin";
import AppSignup from "./pages/auth/AppSignup";
import ChangePassword from "./pages/auth/ChangePassword";
import CreateChainNodes from "./pages/nodes/parachain-nodes/CreateChainNodes";
import CreateNode from "./pages/nodes/dedicated-nodes/CreateNode";
import DedicatedNodes from "./pages/nodes/dedicated-nodes/DedicatedNodes";
import DedicatedProjectInsight from "./pages/nodes/dedicated-nodes/DedicatedProjectInsight";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Home from "./pages/home";
import NotFound from "./pages/others/notFound";
import ParachainProjectInsight from "./pages/nodes/parachain-nodes/ParachainProjectInsight";
import ProjectInsight from "./pages/api-services/ProjectInsight";
import React, { useEffect, useState } from "react";
import Settings from "./pages/settings";
import Support from "./pages/support";
import ValidatorNodes from "./pages/validator-nodes/ValidatorNodes";
import Verification from "./pages/auth/Verification";

const AppWrapper = () => {
  const auth = useAuth();
  const [fetchingUser, setFetchingUser] = useState(true);

  useEffect(() => {
    try {
      const userObj = JSON.parse(localStorage.getItem("user"));
      if (userObj) {
        let expiryDate = new Date(userObj.auth.expiryDate);
        let todaysDate = new Date();
        if (todaysDate > expiryDate) {
          auth.logout();
        } else {
          auth.initUser(userObj);
        }
      }
      setFetchingUser(false);
    } catch (err) {}
  }, []);

  return (
    <div className="">
      {fetchingUser ? (
        <></>
      ) : (
        <Routes>
          <Route
            path="/login"
            element={
              <Auth path="login">
                <AppLogin />
              </Auth>
            }
          ></Route>
          <Route
            path="/signup"
            element={
              <Auth path="signup">
                <AppSignup />
              </Auth>
            }
          ></Route>
          <Route
            path="/reset-password"
            element={
              <ResetAuth>
                <ChangePassword />
              </ResetAuth>
            }
          ></Route>
          <Route
            path="/forgot-password"
            element={
              <ResetAuth>
                <ForgotPassword />
              </ResetAuth>
            }
          ></Route>
          <Route
            path="/verify-account"
            element={
              <Auth>
                <Verification />
              </Auth>
            }
          ></Route>
          <Route
            path="/"
            element={
              <Auth>
                <AppLogin />
              </Auth>
            }
          ></Route>
          <Route
            path={appConfig.nav.routes.dashboard}
            element={
              <Dashboard>
                <Home />
              </Dashboard>
            }
          ></Route>
          <Route
            path={appConfig.nav.routes.api}
            element={
              <ManualProvider>
                <Dashboard>
                  <ApiServices />
                </Dashboard>
              </ManualProvider>
            }
          ></Route>
          <Route
            path={`/${appConfig.nav.routes.api}/:projectId`}
            element={
              <ManualProvider>
                <Dashboard>
                  <ProjectInsight />
                </Dashboard>
              </ManualProvider>
            }
          ></Route>
          <Route
            path={appConfig.nav.routes.validator}
            element={
              <ManualProvider>
                <Dashboard>
                  <ValidatorNodes />
                </Dashboard>
              </ManualProvider>
            }
          ></Route>
          <Route
            path={appConfig.nav.routes.nodes}
            element={
              <ManualProvider>
                <Dashboard>
                  <DedicatedNodes />
                </Dashboard>
              </ManualProvider>
            }
          ></Route>
          <Route
            path={`/${appConfig.nav.routes.nodes}/dedicated/create`}
            element={
              <ManualProvider>
                <Dashboard>
                  <CreateNode />
                </Dashboard>
              </ManualProvider>
            }
          ></Route>

          <Route
            path={`/${appConfig.nav.routes.nodes}/parachain/create`}
            element={
              <ManualProvider>
                <Dashboard>
                  <CreateChainNodes />
                </Dashboard>
              </ManualProvider>
            }
          ></Route>

          <Route
            path={`/${appConfig.nav.routes.nodes}/dedicated/:id`}
            element={
              <ManualProvider>
                <Dashboard>
                  <DedicatedProjectInsight />
                </Dashboard>
              </ManualProvider>
            }
          ></Route>
          <Route
            path={`/${appConfig.nav.routes.nodes}/parachain/:id`}
            element={
              <ManualProvider>
                <Dashboard>
                  <ParachainProjectInsight />
                </Dashboard>
              </ManualProvider>
            }
          ></Route>
          <Route
            path={appConfig.nav.routes.settings}
            element={
              <Dashboard>
                <Settings />
              </Dashboard>
            }
          ></Route>
          <Route
            path={appConfig.nav.routes.support}
            element={
              <Dashboard>
                <Support />
              </Dashboard>
            }
          ></Route>
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      )}
    </div>
  );
};
function App() {
  return (
    <HeaderProvider>
      <LiveProvider>
        <AuthProvider>
          <ProjectProvider>
            <ManualProvider>
              <RPCProvider>
                <StepperProvider>
                  <AppWrapper />
                  <Toaster position="top-right" />
                </StepperProvider>
              </RPCProvider>
            </ManualProvider>
          </ProjectProvider>
        </AuthProvider>
      </LiveProvider>
    </HeaderProvider>
  );
}

export default App;
