import { ReactComponent as DropDown } from "../../../assets/svg/icons/select-arrow.svg";
import { ReactComponent as MemoryIcon } from "../../../assets/svg/icons/memory-icon.svg";
import { ReactComponent as CPUIcon } from "../../../assets/svg/icons/cpu-icon.svg";
import { ReactComponent as StorageIcon } from "../../../assets/svg/icons/storage-icon.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/svg/icons/number-arrow-up.svg";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { ErrorText } from "../../../components/layouts/Auth";
import * as Yup from "yup";
import { useStepper } from "../../../context/stepperContext";
import { appConfig } from "../../../config";
import { convertToGigaByte } from "../../../utils";

export const getUnit = (value, unit) => {
  if (unit === "core") {
    return `${value} cores`;
  } else if (unit !== "byte") {
    return value;
  }

  const gbValue = convertToGigaByte(value, unit);
  if (gbValue >= 1000) {
    return `${gbValue / 1000} TB`;
  } else {
    return `${gbValue} GB`;
  }
};

const Step2 = () => {
  const [nodeTypeDropdown, setNodeTypeDropdown] = useState(false);
  const [imageVersionDropdown, setImageVersionDropdown] = useState(false);
  const [priceEstimate, setPriceEstimate] = useState("500");
  const {
    dedicatedCurrentStep,
    setDedicatedCurrentStep,
    dedicatedCurrentConfig,
    setDedicatedCurrentConfig,
  } = useStepper();
  const [nodeSpecsMode, setNodeSpecsMode] = useState(
    dedicatedCurrentConfig.cpu ? `custom` : `default`
  );
  const [networkId] = useState(dedicatedCurrentConfig.networkId);
  const [nodeTypes] = useState(
    appConfig.networks[networkId].parameters.v1.nodeTypes
  );
  const [imageVersions] = useState(
    appConfig.networks[networkId].parameters.v1.imageVersions
  );
  const [cloudProviders] = useState(
    appConfig.networks[networkId].parameters.v1.cloudProviders
  );
  const [cpuToMem, setCpuToMem] = useState(appConfig.cloudSpecs.min?.cpuToMem);
  const [nodeSpecs] = useState(appConfig.networks[networkId].specifications.v1);

  const formik = useFormik({
    initialValues: {
      projectName: dedicatedCurrentConfig.projectName || "",
      nodeType: dedicatedCurrentConfig.nodeType || "",
      imageVersion: dedicatedCurrentConfig.imageVersion || "",
      cloudProvider: dedicatedCurrentConfig.cloudProvider || "",
      cpu: dedicatedCurrentConfig.cpu || nodeSpecs.cpu.max,
      memory: dedicatedCurrentConfig.memorySize || nodeSpecs.memory.default,
      storage: dedicatedCurrentConfig.storageSize || nodeSpecs.storage.default,
    },
    validationSchema: Yup.object({
      projectName: Yup.string()
        .required(<ErrorText text="Project name is required." />)
        .min(3, "Minimum of 3 characters"),
      nodeType: Yup.string().required(
        <ErrorText text="Node type is required." />
      ),
      imageVersion: Yup.string().required(
        <ErrorText text="Image Version is required." />
      ),
      cloudProvider: Yup.string().required(
        <ErrorText text="Cloud Provider is required." />
      ),
    }),
    onSubmit: async (values) => {
      setDedicatedCurrentConfig({
        ...dedicatedCurrentConfig,
        projectName: values.projectName,
        nodeType: values.nodeType,
        imageVersion: values.imageVersion,
        cloudProvider: values.cloudProvider,
        cpu: values.cpu,
        storageSize: values.storage,
        memorySize: values.memory,
        priceEstimate: priceEstimate,
        metadata: { ...dedicatedCurrentConfig.metadata },
      });

      setDedicatedCurrentStep(dedicatedCurrentStep + 1);
    },
  });

  const ErrorDisplay = ({ name }) => {
    return (
      <div
        className={`${
          formik.touched[name] && formik.errors[name]
            ? "inline-block"
            : "hidden"
        } app_form-error`}
      >
        {formik.touched[name] && formik.errors[name] && formik.errors[name]}
      </div>
    );
  };

  const handleRadioChange = (value) => {
    const radio1 = document.querySelector("#gcp-radio");
    const radio2 = document.querySelector("#aws-radio");
    if (value === "gcp") {
      radio1.checked = true;
      radio2.checked = false;
    }
    if (value === "aws") {
      radio2.checked = true;
      radio1.checked = false;
    }
    formik.setFieldValue("cloudProvider", value);
  };

  useEffect(() => {
    if (dedicatedCurrentConfig.cloudProvider) {
      handleRadioChange(dedicatedCurrentConfig.cloudProvider);
    }
  }, []);

  useEffect(() => {
    if (nodeSpecsMode === "default") {
      formik.setFieldValue("cpu", nodeSpecs["cpu"].max);
      formik.setFieldValue("memory", nodeSpecs["memory"].default);
      formik.setFieldValue("storage", nodeSpecs["storage"].default);
    }
  }, [nodeSpecsMode]);

  return (
    <div className="step">
      <h2 className="self-start pb-5">Create a new Project</h2>
      <form onSubmit={formik.handleSubmit} className="w-full">
        <div className="w-full mb-5">
          <div className="app_form">
            <label htmlFor="projectName">Project name</label>
            <input
              id="projectName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter project name"
              type="text"
              name="projectName"
              value={formik.values.projectName}
              className={`border-[1px] focus:outline-none ticket-input
                ${
                  !formik.errors?.projectName && formik.touched.projectName
                    ? "focus:border-blue"
                    : ""
                }
                ${
                  formik.errors?.projectName && formik.touched.projectName
                    ? "border-[#C63737] border-[1.5px]"
                    : "border-[rgba(18,18,18,0.3)]"
                }
              `}
            />
            <ErrorDisplay name={"projectName"} />
          </div>
        </div>

        <div className="w-full mb-5">
          <div className="app_form">
            <label htmlFor="projectNetwork">Select Node Type</label>
            <button
              type="button"
              className="dropdown__button relative"
              onClick={() => {
                setNodeTypeDropdown(!nodeTypeDropdown);
                setImageVersionDropdown(false);
              }}
              onBlur={() => setNodeTypeDropdown(false)}
            >
              <div>
                {formik.values.nodeType ? (
                  <div className="flex items-center justify-start gap-0 h-[45px]">
                    <p>{formik.values.nodeType}</p>
                  </div>
                ) : (
                  "Select Node Type"
                )}
              </div>
              <DropDown />

              {nodeTypeDropdown && (
                <div className="dropdown__options">
                  {nodeTypes?.map((nodeType, index) => {
                    return (
                      <div
                        key={index}
                        className={`option ${
                          index === nodeTypes.length - 1
                            ? ""
                            : "option__apply-border"
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          formik.setFieldValue("nodeType", nodeType.label);
                          setNodeTypeDropdown(false);
                        }}
                      >
                        {nodeType.label}
                      </div>
                    );
                  })}
                </div>
              )}
            </button>
            <ErrorDisplay name="nodeType" />
          </div>
        </div>

        <div className="w-full mb-5">
          <div className="app_form">
            <label htmlFor="Category">Select Image Version</label>
            <button
              type="button"
              className="dropdown__button relative"
              onClick={() => {
                setImageVersionDropdown(!imageVersionDropdown);
                setNodeTypeDropdown(false);
              }}
              onBlur={() => setImageVersionDropdown(false)}
            >
              <p>{formik.values.imageVersion || "Select Image Version"}</p>
              <DropDown />

              {imageVersionDropdown && (
                <div className="dropdown__options">
                  {imageVersions?.map((version, index) => {
                    return (
                      <div
                        key={index}
                        className={`option ${
                          index === imageVersions.length - 1
                            ? ""
                            : "option__apply-border"
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          formik.setFieldValue("imageVersion", version.label);
                          setImageVersionDropdown(false);
                        }}
                      >
                        {version.label}
                      </div>
                    );
                  })}
                </div>
              )}
            </button>
            <ErrorDisplay name="imageVersion" />
          </div>
        </div>

        <h2 className="mb-5 mt-2">Provider Configuration</h2>

        <div className="w-full mb-5">
          <div className="app_form">
            <label htmlFor="cloudProvider">Select Cloud Provider</label>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-3 md:gap-8">
              {cloudProviders.map((provider, index) => {
                return (
                  <button
                    key={index}
                    type="button"
                    className={`
                  flex items-center gap-2 pl-5 pr-2 h-[50px] border-[1px] border-[#B8B8B8] rounded-[8px]
                  ${
                    formik.values.cloudProvider === provider.slug
                      ? "border-[#2180FF] bg-[#E9F1FF]"
                      : ""
                  }`}
                    onClick={() => {
                      handleRadioChange(provider.slug);
                      setDedicatedCurrentConfig({
                        ...dedicatedCurrentConfig,
                        metadata: {
                          cloudProvider: provider,
                        },
                      });
                    }}
                  >
                    <input
                      type={"radio"}
                      name={provider.slug}
                      id={`${provider.slug}-radio`}
                      onChange={() => {}}
                      value={provider.slug}
                    />
                    <div className={`text-[#717171]`}>
                      <div className="flex items-center gap-2 text-[14px] text-black font-[600]">
                        <img
                          src={provider.icon}
                          alt={provider.slug}
                          width={30}
                        />
                        <p>{provider.label}</p>
                      </div>
                    </div>
                  </button>
                );
              })}
            </div>
            <ErrorDisplay name={"cloudProvider"} />
          </div>
        </div>

        {formik.values.cloudProvider !== "" && (
          <div>
            <div className="slider">
              <div className="slider__header">
                <button
                  type="button"
                  className="slider__header-button"
                  onClick={() => {
                    setNodeSpecsMode("default");
                  }}
                >
                  Default{" "}
                  <span className="hidden md:inline">(Recommended)</span>
                </button>
                <button
                  type="button"
                  className="slider__header-button"
                  onClick={() => {
                    setNodeSpecsMode("custom");
                  }}
                >
                  Custom
                </button>
              </div>
              <div className="slider__border">
                <div
                  className={`w-[50%] h-full ${
                    nodeSpecsMode === "default" ? "bg-blue rounded-[5px]" : ""
                  }`}
                ></div>
                <div
                  className={`w-[50%] h-full ${
                    nodeSpecsMode === "custom" ? "bg-blue rounded-[5px]" : ""
                  }`}
                ></div>
              </div>
            </div>

            <div className="grid sm:grid-cols-2 grid-cols-1 gap-3 md:gap-8 mt-7">
              <div
                className={`
            flex items-center justify-between gap-2 pl-5 pr-2 h-[50px] border-[1px] rounded-[8px] border-[#2180FF] bg-[#E9F1FF]`}
              >
                <div className="flex items-center gap-3">
                  <StorageIcon />
                  <p>Storage size</p>
                </div>
                <div className="flex items-center gap-2">
                  <p className="text-sm">
                    {getUnit(formik.values.storage, "byte")}
                  </p>
                  <div
                    className={`flex flex-col gap-1 ${
                      nodeSpecsMode === "default" ? "hidden" : "flex"
                    }`}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        const newSize = formik.values.storage + 100000000000;
                        if (newSize <= 1000000000000) {
                          formik.setFieldValue("storage", newSize);
                        }
                      }}
                    >
                      <ArrowIcon />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        const newSize = formik.values.storage - 100000000000;
                        if (newSize >= 10000000000) {
                          formik.setFieldValue("storage", newSize);
                        }
                      }}
                    >
                      <ArrowIcon className="rotate-[180deg]" />
                    </button>
                  </div>
                </div>
              </div>

              <div
                className={`
            flex items-center justify-between gap-2 pl-5 pr-2 h-[50px] border-[1px] rounded-[8px] border-[#2180FF] bg-[#E9F1FF]`}
              >
                <div className="flex items-center gap-3">
                  <MemoryIcon />
                  <p>Memory</p>
                </div>
                <div className="flex items-center gap-2">
                  <p className="text-sm">
                    {getUnit(formik.values.memory, "byte")}
                  </p>
                </div>
              </div>

              {nodeSpecsMode === "default" && (
                <div
                  className={`
            flex items-center justify-between gap-2 pl-5 pr-2 h-[50px] border-[1px] rounded-[8px] border-[#2180FF] bg-[#E9F1FF]`}
                >
                  <div className="flex items-center gap-3">
                    <CPUIcon />
                    <p>CPU</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <p className="text-sm">{formik.values.cpu} cores</p>
                  </div>
                </div>
              )}
            </div>
            {nodeSpecsMode === "custom" && (
              <div className="w-full mt-5">
                <label htmlFor="cpu" className="mb-5">
                  CPU: {formik.values.cpu} cores
                </label>
                <input
                  key={`cpu`}
                  type="range"
                  id="cpu"
                  name="cpu"
                  step={2}
                  min={0}
                  max={8}
                  defaultValue={formik.values?.cpu}
                  className="w-full"
                  onChange={(e) => {
                    formik.setFieldValue("cpu", e.target.value);
                    formik.setFieldValue(
                      "memory",
                      cpuToMem[e.target.value] * 1000000000
                    );
                  }}
                />
                <ErrorDisplay name={"cpu"} />
              </div>
            )}

            {/* <div className="flex flex-col gap-14 mt-7 text-[15px]">
              {Object.keys(nodeSpecs)
                .filter((key) => key !== "ref")
                .map((key, index) => {
                  return (
                    <div key={index}>
                      <div className="flex flex-col gap-3" key={index}>
                        <p className="capitalize text-[14px]">
                          {key === "cpu" ? "CPU" : key} :{" "}
                          <span className="font-[600]">
                            {getUnit(formik.values[key], nodeSpecs[key].unit)}
                          </span>
                        </p>
                        {nodeSpecsMode === "default" ? (
                          <input
                            type="range"
                            id={`${key}-slider`}
                            className="input-slider"
                            value={nodeSpecs[key].default}
                            readOnly
                          />
                        ) : (
                          <input
                            type="range"
                            id={`${key}-slider`}
                            className="input-slider"
                            min={nodeSpecs[key].min}
                            max={nodeSpecs[key].max}
                            step={nodeSpecs[key].min}
                            onChange={(e) =>
                              formik.setFieldValue(key, e.target.value)
                            }
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
            </div> */}

            <div className="flex flex-col md:flex-row gap-5 justify-between md:items-end mt-20 text-[13px]">
              <div>
                <p>
                  Estimated Price:{" "}
                  <span className="font-[600]">${priceEstimate}</span>
                </p>
              </div>
              <div>
                <p>
                  CPU:{" "}
                  <span className="font-[600]">{formik.values.cpu} cores</span>
                </p>
                <p>
                  Storage:{" "}
                  <span className="font-[600]">
                    {getUnit(formik.values.storage, "byte")}
                  </span>
                </p>
                <p>
                  Memory:{" "}
                  <span className="font-[600]">
                    {getUnit(formik.values.memory, "byte")}
                  </span>
                </p>
              </div>
            </div>
          </div>
        )}

        <button type="submit" className="app_button bottom-10 mt-8 full">
          Continue
        </button>
      </form>
    </div>
  );
};

export default Step2;
