import { useEffect, useState } from "react";
import { appConfig } from "../../../config";
import { useStepper } from "../../../context/stepperContext";
import { getUnit } from "./02-Step";
import { ReactComponent as DropDown } from "../../../assets/svg/icons/select-arrow.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/svg/icons/delete.svg";
import { ReactComponent as MemoryIcon } from "../../../assets/svg/icons/memory-icon.svg";
import { ReactComponent as CPUIcon } from "../../../assets/svg/icons/cpu-icon.svg";
import { ReactComponent as StorageIcon } from "../../../assets/svg/icons/storage-icon.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/svg/icons/number-arrow-up.svg";
import { capitalizeString } from "../../../utils";

export const NodeForm = ({
  form,
  id,
  removeForm,
  displayCount,
  selectedNodeTypes,
  setSelectedNodeTypes,
  formTitles,
  setFormTitles,
  activeForm,
}) => {
  const { chainCurrentConfig, setChainCurrentConfig } = useStepper();
  const [nodeTypes, setNodeTypes] = useState(
    appConfig.networks[chainCurrentConfig.networkId].parameters.v1.nodeTypes
  );
  const [amis, setAmis] = useState(
    appConfig.networks[chainCurrentConfig.networkId].parameters.v1.amis
  );
  const [availableCpus, setavailableCpus] = useState(
    appConfig.cloudSpecs?.min?.availableCpus
  );
  const [nodeTypeDropdown, setNodeTypeDropdown] = useState(false);
  const [amiDropdown, setAmiDropdown] = useState(false);
  const [cpuDropdown, setCpuDropdown] = useState(false);
  const [memoryDropdown, setMemoryDropdown] = useState(false);
  const currentNodeType = chainCurrentConfig?.metadata?.nodeFormMapping?.[id];
  const [nodeSpecsMode, setNodeSpecsMode] = useState(
    currentNodeType ? `custom` : `default`
  );
  const [cpuToMem, setCpuToMem] = useState(appConfig.cloudSpecs.min?.cpuToMem);

  const [specifications, setSpecifications] = useState(
    appConfig.networks[chainCurrentConfig.networkId].specifications.v1
  );

  useEffect(() => {
    if (nodeSpecsMode === `default`) {
      form?.setFieldValue("cpu", specifications.cpu.max);
      form?.setFieldValue("memorySize", specifications.memory.default);
      form?.setFieldValue("storageSize", specifications.storage.default);
    }
  }, [nodeSpecsMode]);

  useEffect(() => {
    if (activeForm === id && form.values.os) {
      handleRadioChange(form.values.os, "os");
    }
  }, [activeForm]);

  useEffect(() => {
    if (
      chainCurrentConfig.metadata?.nodeFormMapping &&
      chainCurrentConfig.nodes
    ) {
      const currentNodeType = chainCurrentConfig?.metadata?.nodeFormMapping[id];
      if (chainCurrentConfig?.nodes[currentNodeType]?.os) {
        handleRadioChange(chainCurrentConfig.nodes[currentNodeType].os, "os");
      }
    }
  }, [chainCurrentConfig]);

  useEffect(() => {
    const newTitles = { ...formTitles };
    const title = form.values.nodeType
      ? `${
          form.values.nodeType === "rpc"
            ? "RPC"
            : capitalizeString(form.values.nodeType)
        } Node` //[${form.values.nodeCount || 0}]
      : "Select Nodetype";
    newTitles[id] = title;
    setFormTitles(newTitles);
  }, [form.values.nodeType, form.values.nodeCount, activeForm]);

  const ErrorDisplay = ({ name, form }) => {
    return (
      <div
        className={`${
          form.touched[name] && form.errors[name] ? "inline-block" : "hidden"
        } app_form-error`}
      >
        {form.touched[name] && form.errors[name] && form.errors[name]}
      </div>
    );
  };

  const handleRadioChange = (value, type) => {
    if (type === "os") {
      const radio1 = document.querySelector(`#${id}-Ubuntu-radio`);
      const radio2 = document.querySelector(`#${id}-Debian-radio`);
      const radio3 = document.querySelector(`#${id}-CentOS-radio`);
      if (value === "Ubuntu") {
        if (radio1) radio1.checked = true;
        if (radio2) radio2.checked = false;
        if (radio3) radio3.checked = false;
      }
      if (value === "Debian") {
        if (radio2) radio2.checked = true;
        if (radio1) radio1.checked = false;
        if (radio3) radio3.checked = false;
      }
      if (value === "CentOS") {
        if (radio3) radio3.checked = true;
        if (radio1) radio1.checked = false;
        if (radio2) radio2.checked = false;
      }
      form.setFieldValue("os", value);
    }
  };

  return (
    <form id={id} onSubmit={form.handleSubmit} className="w-full">
      <div className="flex items-center justify-center gap-10 mb-3">
        {displayCount > 1 && (
          <button
            type="button"
            className="underline text-[12px] text-blue"
            onClick={() => {
              if (chainCurrentConfig.nodes && form.values.nodeType) {
                const newConfig = { ...chainCurrentConfig };
                const newNodes = newConfig.nodes;
                delete newNodes[form.values.nodeType];
                console.log(newNodes);
                setChainCurrentConfig({ ...newConfig, nodes: newNodes });
              }
              removeForm(id, form.values.nodeType);
              setTimeout(() => {
                form.resetForm({
                  values: {
                    nodeType: "",
                    nodeCount: 0,
                    ami: "",
                    os: "",
                    cpu: "",
                    storageSize: "",
                    memorySize: "",
                    loadBalancer: false,
                  },
                });
                form.setValues({ values: {} });
              }, 100);
            }}
          >
            <DeleteIcon fill="#045cf4" />
          </button>
        )}
      </div>

      <div className="w-full mb-5">
        <div className="app_form">
          <label htmlFor={`${id}-projectNetwork`}>Select Node Type</label>
          <button
            type="button"
            className="dropdown__button relative"
            onClick={() => {
              setNodeTypeDropdown(!nodeTypeDropdown);
            }}
            onBlur={() => setNodeTypeDropdown(false)}
          >
            <div>
              {form.values.nodeType ? (
                <div className="flex items-center justify-start gap-0 h-[45px]">
                  <p>
                    {nodeTypes?.map((nodeType) => {
                      if (nodeType.slug === form.values.nodeType) {
                        return nodeType.label;
                      }
                    })}
                  </p>
                </div>
              ) : (
                "Select Node Type"
              )}
            </div>
            <DropDown />

            {nodeTypeDropdown && (
              <div className="dropdown__options">
                {nodeTypes?.map((nodeType, index) => {
                  if (
                    !Object.values(selectedNodeTypes).includes(nodeType.slug)
                  ) {
                    return (
                      <div
                        key={index}
                        className={`option ${
                          index === nodeTypes.length - 1
                            ? ""
                            : "option__apply-border"
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          form.setFieldValue("nodeType", nodeType.slug);
                          const updatedNodeTypes = { ...selectedNodeTypes };
                          updatedNodeTypes[id] = nodeType.slug;
                          setSelectedNodeTypes(updatedNodeTypes);
                          setNodeTypeDropdown(false);
                        }}
                      >
                        {nodeType.label}
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </button>
          <ErrorDisplay name="nodeType" form={form} />
        </div>
      </div>

      {form.values.nodeType === "rpc" && (
        <div className="flex items-center mb-10 gap-5">
          <label className="switch">
            <input
              className="radio-slider-input"
              type="checkbox"
              onChange={(e) => {
                form.setFieldValue("loadBalancer", e.target.checked);
              }}
              defaultChecked={form.values.loadBalancer}
            />
            <span className="radio-slider round"></span>
          </label>
          <p>Enable Load Balancer</p>
        </div>
      )}

      <div className="w-full mb-5">
        <div className="app_form">
          <label htmlFor="nodeCount">Node count</label>
          <input
            id="nodeCount"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={form.values.nodeCount}
            type="number"
            min={1}
            name="nodeCount"
            value={form.values.nodeCount}
            className={`border-[1px] focus:outline-none ticket-input
        ${
          !form.errors?.nodeCount && form.touched.nodeCount
            ? "focus:border-blue"
            : ""
        }
        ${
          form.errors?.nodeCount && form.touched.nodeCount
            ? "border-[#C63737] border-[1.5px]"
            : "border-[rgba(18,18,18,0.3)]"
        }
      `}
          />
          <ErrorDisplay name={"nodeCount"} form={form} />
        </div>
      </div>

      <div className="w-full mb-5">
        <div className="app_form">
          <label htmlFor={`ami`}>Select Ami</label>
          <button
            type="button"
            className="dropdown__button relative"
            onClick={() => {
              setAmiDropdown(!amiDropdown);
            }}
            onBlur={() => setAmiDropdown(false)}
          >
            <div>
              {form.values.ami ? (
                <div className="flex items-center justify-start gap-0 h-[45px]">
                  <p>
                    {amis?.map((ami) => {
                      if (ami.slug === form.values.ami) {
                        return ami.label;
                      }
                    })}
                  </p>
                </div>
              ) : (
                "Default"
              )}
            </div>
            <DropDown />

            {amiDropdown && (
              <div className="dropdown__options">
                {amis.map((ami, index) => {
                  return (
                    <div
                      key={index}
                      className={`option ${
                        index === amis.length - 1 ? "" : "option__apply-border"
                      }
                      ${ami.active ? "" : "opacity-70 cursor-not-allowed"}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (ami.active) {
                          form.setFieldValue("ami", ami.slug);
                          setAmiDropdown(false);
                        }
                      }}
                    >
                      {ami.label}
                    </div>
                  );
                })}
              </div>
            )}
          </button>
          <ErrorDisplay name="ami" form={form} />
        </div>
        <p className="text-[12px] text-blue underline cursor-pointer mt-3">
          View more information on available ami
        </p>
      </div>
      <div className="w-full lg:w-[50%] mb-10">
        <div className="w-full mb-5">
          <div className="app_form">
            <div className="grid sm:grid-cols-3 grid-cols-1 gap-3 md:gap-4">
              {appConfig.operatingSystems.map((os, index) => {
                return (
                  <button
                    key={index}
                    type="button"
                    className={`
                          flex items-center gap-2 pl-5 pr-2 h-[50px] border-[1px] border-[#B8B8B8] rounded-[8px]
                          ${
                            form.values.os === os.slug
                              ? "border-[#2180FF] bg-[#E9F1FF]"
                              : ""
                          } ${
                      os.active ? "" : "opacity-70 cursor-not-allowed"
                    }`}
                    onClick={() => {
                      os.active && handleRadioChange(os.slug, "os");
                    }}
                  >
                    {os.active && (
                      <input
                        type={"radio"}
                        name={`${id}-${os.slug}-radio`}
                        id={`${id}-${os.slug}-radio`}
                        key={`${id}-${os.slug}-radio`}
                        value={os.slug}
                      />
                    )}
                    <div className={`text-[#717171]`}>
                      <div className="flex items-center gap-2 text-[14px] text-black font-[600]">
                        <img src={os.icon} alt={os.slug} width={20} />
                        <p>{os.label}</p>
                      </div>
                    </div>
                  </button>
                );
              })}
            </div>
            <ErrorDisplay name={"os"} form={form} />
          </div>
        </div>
      </div>

      <div className="slider mb-7">
        <div className="slider__header">
          <button
            type="button"
            className="slider__header-button"
            onClick={() => {
              setNodeSpecsMode(`default`);
            }}
          >
            Default <span className="hidden md:inline">(Recommended)</span>
          </button>
          <button
            type="button"
            className="slider__header-button"
            onClick={() => {
              setNodeSpecsMode(`custom`);
            }}
          >
            Custom
          </button>
        </div>
        <div className="slider__border">
          <div
            className={`w-[50%] h-full ${
              nodeSpecsMode === `default` ? "bg-blue rounded-[5px]" : ""
            }`}
          ></div>
          <div
            className={`w-[50%] h-full ${
              nodeSpecsMode === `custom` ? "bg-blue rounded-[5px]" : ""
            }`}
          ></div>
        </div>
      </div>
      <div className="grid sm:grid-cols-2 grid-cols-1 gap-3 md:gap-8">
        <div
          className={`
            flex items-center justify-between gap-2 pl-5 pr-2 h-[50px] border-[1px] rounded-[8px] border-[#2180FF] bg-[#E9F1FF]`}
        >
          <div className="flex items-center gap-3">
            <StorageIcon />
            <p>Storage size</p>
          </div>
          <div className="flex items-center gap-2">
            <p className="text-sm">
              {getUnit(form.values.storageSize, "byte")}
            </p>
            <div
              className={`flex flex-col gap-1 ${
                nodeSpecsMode === "default" ? "hidden" : "flex"
              }`}
            >
              <button
                type="button"
                onClick={() => {
                  const newSize = form.values.storageSize + 100000000000;
                  if (newSize <= 1000000000000) {
                    form.setFieldValue("storageSize", newSize);
                  }
                }}
              >
                <ArrowIcon />
              </button>
              <button
                type="button"
                onClick={() => {
                  const newSize = form.values.storageSize - 100000000000;
                  if (newSize >= 10000000000) {
                    form.setFieldValue("storageSize", newSize);
                  }
                }}
              >
                <ArrowIcon className="rotate-[180deg]" />
              </button>
            </div>
          </div>
        </div>

        <div
          className={`
            flex items-center justify-between gap-2 pl-5 pr-2 h-[50px] border-[1px] rounded-[8px] border-[#2180FF] bg-[#E9F1FF]`}
        >
          <div className="flex items-center gap-3">
            <MemoryIcon />
            <p>Memory</p>
          </div>
          <div className="flex items-center gap-2">
            <p className="text-sm">{getUnit(form.values.memorySize, "byte")}</p>
          </div>
        </div>

        {nodeSpecsMode === "default" && (
          <div
            className={`
            flex items-center justify-between gap-2 pl-5 pr-2 h-[50px] border-[1px] rounded-[8px] border-[#2180FF] bg-[#E9F1FF]`}
          >
            <div className="flex items-center gap-3">
              <CPUIcon />
              <p>CPU</p>
            </div>
            <div className="flex items-center gap-2">
              <p className="text-sm">{form.values.cpu} cores</p>
            </div>
          </div>
        )}
      </div>
      {nodeSpecsMode === "custom" && (
        <div className="w-full mt-5">
          <label htmlFor="cpu" className="mb-5">
            CPU: {form.values.cpu} cores
          </label>
          <input
            key={`${id}-cpu`}
            type="range"
            id="cpu"
            name="cpu"
            step={2}
            min={0}
            max={8}
            defaultValue={form.values?.cpu}
            className="w-full"
            onChange={(e) => {
              form.setFieldValue("cpu", e.target.value);
              form.setFieldValue(
                "memorySize",
                cpuToMem[e.target.value] * 1000000000
              );
            }}
          />
          <ErrorDisplay name={"cpu"} form={form} />
        </div>
      )}

      {/* <div className="w-full mb-5">
        <div className="app_form">
          <label htmlFor="storageSize">Storage</label>
          <div className="relative">
            <input
              id="storageSize"
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              type="number"
              step={"1000000000"}
              name="storageSize"
              value={
                nodeSpecsMode === "default"
                  ? specifications.storage.default
                  : form.values.storageSize
              }
              readOnly={nodeSpecsMode === "default"}
              className={`border-[1px] focus:outline-none ticket-input text-whitee
        ${
          !form.errors?.storage && form.touched.storage
            ? "focus:border-blue"
            : ""
        }
        ${
          form.errors?.storage && form.touched.storage
            ? "border-[#C63737] border-[1.5px]"
            : "border-[rgba(18,18,18,0.3)]"
        }
      `}
            />
            <div className="absolute top-3 right-[60px]">
              {getUnit(form.values.storageSize, "byte")}
            </div>
          </div>
          <ErrorDisplay name={"storageSize"} form={form} />
        </div>
      </div>

      <div className="grid sm:grid-cols-2 grid-cols-1 gap-3 md:gap-8">
        <div className="w-full mb-5">
          <div className="app_form">
            <label htmlFor="cpu">Select CPU Cores</label>
            <button
              type="button"
              className="dropdown__button relative"
              onClick={() => {
                setCpuDropdown(!cpuDropdown);
              }}
              onBlur={() => setCpuDropdown(false)}
            >
              <div>
                {form.values.cpu ? (
                  <div className="flex items-center justify-start gap-0 h-[45px]">
                    <p>{form.values.cpu} Cores</p>
                  </div>
                ) : (
                  "Select CPU cores"
                )}
              </div>
              {nodeSpecsMode === "custom" && <DropDown />}

              {cpuDropdown && nodeSpecsMode === "custom" && (
                <div className="dropdown__options">
                  {availableCpus?.map((cpuCount, index) => {
                    return (
                      <div
                        key={index}
                        className={`option ${
                          index === availableCpus.length - 1
                            ? ""
                            : "option__apply-border"
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          form.setFieldValue("cpu", cpuCount);
                          setCpuDropdown(false);
                        }}
                      >
                        {cpuCount}
                      </div>
                    );
                  })}
                </div>
              )}
            </button>
            <ErrorDisplay name="cpu" form={form} />
          </div>
        </div>

        <div className="w-full mb-16">
          <div className="app_form">
            <label htmlFor="memorySize">Select Memory</label>
            <button
              type="button"
              className="dropdown__button relative"
              onClick={() => {
                setMemoryDropdown(!memoryDropdown);
              }}
              onBlur={() => setMemoryDropdown(false)}
            >
              <div>
                {form.values.memorySize ? (
                  <div className="flex items-center justify-start gap-0 h-[45px]">
                    <p>{form.values.memorySize}</p>
                  </div>
                ) : (
                  "Select Memory Size"
                )}
              </div>
              {nodeSpecsMode === "custom" && <DropDown />}

              {memoryDropdown && nodeSpecsMode === "custom" && (
                <div className="dropdown__options">
                  {cpuToMem[form.values.cpu]?.map((mem, index) => {
                    return (
                      <div
                        key={index}
                        className={`option ${
                          index === cpuToMem[form.values.cpu].length - 1
                            ? ""
                            : "option__apply-border"
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          form.setFieldValue("memorySize", `${mem} GB`);
                          setMemoryDropdown(false);
                        }}
                      >
                        {`${mem} GB`}
                      </div>
                    );
                  })}
                </div>
              )}
            </button>
            <ErrorDisplay name="memorySize" form={form} />
          </div>
        </div>
      </div> */}
    </form>
  );
};
