import { createContext, useContext, useState } from "react";

const StepperContext = createContext();

export const StepperProvider = ({ children }) => {
  const [dedicatedCurrentStep, setDedicatedCurrentStep] = useState(0);
  const [dedicatedCurrentConfig, setDedicatedCurrentConfig] = useState({});

  const [chainCurrentStep, setChainCurrentStep] = useState(0);
  const [chainCurrentConfig, setChainCurrentConfig] = useState({});

  //console.log(chainCurrentConfig);

  return (
    <StepperContext.Provider
      value={{
        dedicatedCurrentStep,
        setDedicatedCurrentStep,
        dedicatedCurrentConfig,
        setDedicatedCurrentConfig,
        chainCurrentStep,
        setChainCurrentStep,
        chainCurrentConfig,
        setChainCurrentConfig,
      }}
    >
      {children}
    </StepperContext.Provider>
  );
};

export const useStepper = () => useContext(StepperContext);
