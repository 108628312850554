import { alertError, alertSuccess, NETWORK_STATE } from "../../utils";
import { appConfig } from "../../config";
import { createProject } from "../../services/ProjectsService";
import { getProjects, deleteProject } from "../../services/ProjectsService";
import { ReactComponent as Wave } from "../../assets/svg/icons/wave.svg";
import { useAuth } from "../../context/authContext";
import { useFormik } from "formik";
import { useHeader } from "../../context/headerContext";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ApiIntegration from "../../assets/img/api-integration.png";
import CancelModal from "../../assets/img/cancel-modal.png";
import Dropdown from "../../components/dropdown/Dropdown";
import InputText from "../../components/input-text/InputText";
import Loader from "../../assets/img/loader.gif";
import Modal from "react-modal";
import Networks from "../../components/api-services/Networks";
import ProjectsList from "./ProjectsList";
import React, { useState, useEffect } from "react";
import Stats from "../../assets/img/statistics.png";

const ApiServices = () => {
  const auth = useAuth();
  const [status, setStatus] = useState(NETWORK_STATE.IDLE);
  const { minimizeNav } = useHeader();
  const { state } = useLocation();
  const [modalIsOpen, setIsOpen] = useState(state?.modalOpen);
  const [fetchedProjects, setFetchedProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [currentProjects, setCurrentProjects] = useState(filteredProjects);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [projectNetwork, setProjectNetwork] = useState(
    state?.network || "Select Network"
  );
  const [netDropdownOpen, setNetDropDownOpen] = useState(false);
  const networks = Object.values(appConfig.networks);
  const projectsPerPage = 5;
  const navigate = useNavigate();

  // Function to Fetch user projects from database
  const fetchUserProjects = async () => {
    setStatus(NETWORK_STATE.LOADING);
    try {
      const response = await getProjects();
      setFetchedProjects(response.data.data.records);
      setFilteredProjects(response.data.data.records);
      setStatus(NETWORK_STATE.SUCCESS);
    } catch (err) {
      if (err.response.status === 401) {
        navigate("/login");
        alertError("Session Expired. Please Login.");
      } else {
        alertError(err?.message);
        console.log(err);
        setStatus(NETWORK_STATE.ERROR);
        return;
      }
    }
  };

  // Delete a user project
  const deleteUserProject = async (projectId) => {
    try {
      const response = await deleteProject(projectId);
      alertSuccess(response.data.message);
      fetchUserProjects();
    } catch (err) {
      alertError(err?.response?.message);
      fetchUserProjects();
      return;
    }
  };

  // Break projects data into pages
  useEffect(() => {
    const start = (currentPage - 1) * projectsPerPage;
    const end = projectsPerPage * (currentPage - 1) + projectsPerPage;
    setCurrentProjects(filteredProjects.slice(start, end));
    setTotalPages(Math.ceil(filteredProjects.length / projectsPerPage));
  }, [filteredProjects.length, currentPage]);

  // Filter projects based on project name
  const filterProjects = (e) => {
    setSearchText(e.target.value);
    if (e.target.value.length > 0) {
      const f = fetchedProjects.filter((project) => {
        return project.projectName.toLowerCase().includes(e.target.value);
      });
      setFilteredProjects(f);
    } else {
      setFilteredProjects(fetchedProjects);
    }
  };

  // Fetch user projects on page load
  useEffect(() => {
    auth.setHeaderText(["API Services"]);
    fetchUserProjects();
  }, []);

  // Styles for modal
  const customStyles = {
    overlay: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      opacity: "1",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px",
    },
  };

  // Form handling
  const formik = useFormik({
    initialValues: {
      projectName: "",
      devEnvironment: "",
      projectNetwork: "",
    },
    validationSchema: Yup.object({
      projectName: Yup.string()
        .required("Project name is required.")
        .min(5, "Minimum of 5 characters"),
      projectNetwork: Yup.string().required("Network is required"),
    }),
    onSubmit: async (values) => {
      setStatus(NETWORK_STATE.LOADING);
      const selectedNetwork = networks.filter((network) => {
        return network.name === projectNetwork;
      });
      const networkId = selectedNetwork[0].id;
      const postData = {
        networkId,
        projectName: values.projectName,
        projectType: "API Service",
      };
      try {
        const response = await createProject(postData);
        setStatus(NETWORK_STATE.SUCCESS);
        alertSuccess(response.data.message);
        formik.resetForm();
        closeModal();
        setProjectNetwork("Select Network");
        fetchUserProjects();
      } catch (err) {
        setStatus(NETWORK_STATE.ERROR);
        if (err.response.status === 409) {
          alertError(err.response.data.message);
          fetchUserProjects();
        } else {
          console.log(err);
        }
      }
    },
  });

  // Function to open modal
  function openModal() {
    setIsOpen(true);
  }

  // Function to close modal
  function closeModal() {
    formik.resetForm();
    setProjectNetwork("Select Network");
    setIsOpen(false);
    window.history.replaceState({}, document.title);
  }

  // Set the form value when a network is selected
  useEffect(() => {
    if (!formik.values.projectNetwork && projectNetwork !== "Select Network") {
      formik.setFieldValue("projectNetwork", projectNetwork);
    }
  }, [projectNetwork]);

  document.title = "Blockops | API Services";

  return (
    <div className="api-services relative">
      <div className="api-services__heading">
        <div className="app-home__heading">
          <h2>Welcome {auth.user.firstName}</h2>
          <Wave />
        </div>
      </div>
      {status === NETWORK_STATE.LOADING ? (
        <img src={Loader} alt="Loading..." className="self-center py-20" />
      ) : status === NETWORK_STATE.SUCCESS ? (
        <div className="w-full">
          <div className={`api-services__hero`}>
            {fetchedProjects.length === 0 ? (
              <div className="left-hero">
                <div className={`${"api-services__hero-header1"}`}>
                  <h2>Connect to your first API network</h2>
                </div>
                <p className="api-services__hero-text mt-4">
                  Blockops APIs allow integration, customization, efficiency,
                  security, and scalability. By leveraging our APIs, you can
                  create robust and effective blockchain solutions that meet the
                  diverse needs of different industries and applications
                </p>
                <button
                  className="standard-btn-1 standard-w mt-6"
                  onClick={openModal}
                >
                  Create Project
                </button>
                <div id="API-Networks"></div>
              </div>
            ) : (
              <div className="left-hero">
                <div className={`api-services__hero-header2`}>
                  <h2>Create new projects</h2>
                </div>
                <button
                  className="standard-btn-1 standard-w mt-4"
                  onClick={openModal}
                >
                  Create project
                </button>
              </div>
            )}
            <div className="right-hero">
              {fetchedProjects.length === 0 ? (
                <img
                  src={ApiIntegration}
                  alt=""
                  width={minimizeNav ? 295 : 295}
                />
              ) : (
                <img src={Stats} alt="" width={minimizeNav ? 178 : 178} />
              )}
            </div>
          </div>
          {fetchedProjects.length === 0 ? (
            <div className="api-services__networks">
              <div className="w-full">
                <Networks
                  connectAction={openModal}
                  setModalNetwork={setProjectNetwork}
                  type="api"
                />
              </div>
            </div>
          ) : (
            <div>
              <div id="API-Networks" className="project-list"></div>
              <ProjectsList
                projects={currentProjects}
                deleteProject={deleteUserProject}
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                filterProjects={filterProjects}
                searchText={searchText}
                setSearchText={setSearchText}
              />
            </div>
          )}
        </div>
      ) : (
        <div
          className="mx-auto mt-[10%] underline text-blue"
          onClick={fetchUserProjects}
        >
          Retry
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        contentLabel="Start Project Modal"
        overlayClassName={`
          left-0
          ${minimizeNav ? "xl:left-[70px]" : "xl:left-[270px]"}`}
        className="absolute bg-white"
        parentSelector={() => document.querySelector(".block-app")}
      >
        <div className="api-services__modal">
          <div className="api-services__modal-header">
            <button onClick={() => closeModal()}>
              <img src={CancelModal} alt="" width={22} />
            </button>
          </div>
          <form onSubmit={formik.handleSubmit} className="mt-6">
            <div className="mt-0">
              <div className="">
                <Dropdown
                  dropdownOpen={netDropdownOpen}
                  setDropDownOpen={setNetDropDownOpen}
                  options={networks}
                  value={projectNetwork}
                  setValue={setProjectNetwork}
                  networkIcon={true}
                  textKey={"name"}
                  label={"Network"}
                  errorText={formik.errors.projectNetwork}
                  isInvalid={formik.errors.projectNetwork}
                />
              </div>
            </div>

            <div className="app_form mt-6">
              <div className="app_form">
                <InputText
                  value={formik.values.projectName}
                  setValue={formik.handleChange}
                  id={"projectName"}
                  name={"projectName"}
                  label={"Name of Project"}
                  onChange={formik.handleChange}
                  placeholder={"Project name"}
                  isInvalid={formik.errors.projectName}
                  className={"w-full"}
                  inputClassName={"w-full"}
                  errorText={formik.errors?.projectName}
                />
              </div>
            </div>
            <div className="app_form mt-6">
              <button
                type="submit"
                disabled={status === NETWORK_STATE.LOADING}
                className="standard-btn-1 full"
              >
                {status === NETWORK_STATE.LOADING
                  ? "Connecting..."
                  : "Connect to Api Services"}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ApiServices;
