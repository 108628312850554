import { useStepper } from "../../../context/stepperContext";

const StepCount = ({ count, currentStep }) => {
  return (
    <div className="flex w-[50px] md:w-[70px] 2xl:w-[100px] h-[50px] md:h-[70px] 2xl:h-[100px] rounded-full">
      <div
        className={`w-[30px] md:w-[40px] 2xl:w-[50px] h-[30px] md:h-[40px] 2xl:h-[50px] rounded-full border-[2px] text-[14px] md:text-[18px] xl:text-[24px] flex m-auto ${
          currentStep === count
            ? "bg-[#DAE7FE] text-blue border-blue"
            : currentStep > count
            ? "bg-blue text-white border-blue"
            : "bg-white border-[#85868D]"
        }`}
      >
        <p className="m-auto">{count}</p>
      </div>
    </div>
  );
};

const LinkBar = ({ count, currentStep }) => {
  return (
    <div
      className={`h-[3px] w-[15px] sm:w-[60px] md:w-[100px] xl:w-[120px] rounded-[2px] ${
        currentStep === count + 1
          ? "bg-blue opacity-[0.5]"
          : currentStep > count + 1
          ? "bg-blue"
          : "bg-[#85868D]"
      }`}
    ></div>
  );
};

const ProgressBar = () => {
  const { chainCurrentStep } = useStepper();
  return (
    <div className="flex items-center mb-5">
      <StepCount currentStep={chainCurrentStep - 1} count={1} />
      <LinkBar currentStep={chainCurrentStep - 1} count={1} />
      <StepCount currentStep={chainCurrentStep - 1} count={2} />
      <LinkBar currentStep={chainCurrentStep - 1} count={2} />
      <StepCount currentStep={chainCurrentStep - 1} count={3} />
      <LinkBar currentStep={chainCurrentStep - 1} count={3} />
      <StepCount currentStep={chainCurrentStep - 1} count={4} />
    </div>
  );
};

export default ProgressBar;
