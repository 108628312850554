import axios from "../axios";

export const signup = (user) => {
  return axios.post("auth/signup/", user);
};

export const signin = (user) => {
  return axios.post("auth/login/", user);
};

export const verifyCode = (code) => {
  return axios.post("auth/signup/verify-user", code);
};

export const initiatePasswordReset = (email) => {
  return axios.post("auth/password-reset/initiate", email);
};

export const validateResetPasswordToken = (token) => {
  return axios.post("auth/password-reset/validate-token", token);
};

export const resetPassword = (payload) => {
  return axios.post("auth/password-reset/complete", payload);
};

export const updatePassword = (payload) => {
  return axios.put("user/password", payload);
};

export const updateProfile = (payload) => {
  return axios.put("user/profile", payload);
};
