import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import { initiatePasswordReset } from "../../services/AuthService";
import { alertError, alertSuccess, NETWORK_STATE } from "../../utils";
import EmailFrame from "../../assets/img/EmailFrame.png";
import * as Yup from "yup";
import { ErrorText } from "../../components/layouts/Auth";
import Countdown from 'react-countdown';

export default function AppLogin() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [emailSent, setEmailSent] = useState(false);
  const [emailResent, setEmailResent] = useState(false);
  const email = useRef("");
  const [status, setStatus] = useState(NETWORK_STATE.IDLE);

  const handleReset = async (values) => {
    try {
      setStatus(NETWORK_STATE.LOADING);
      const response = await initiatePasswordReset({ email: values.email });
      setStatus(NETWORK_STATE.SUCCESS);

      auth.initUser(response.data.data);
      setEmailSent(true);
      alertSuccess(response.data.message);
    } catch (err) {
      alertError(err.response.data.message);
      setStatus(NETWORK_STATE.ERROR);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { email: "" },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(<ErrorText text="Invalid email address." />)
        .required(<ErrorText text="Please provide your email." />)
    }),
    onSubmit: (values) => { email.current = values.email; handleReset(values) }
  });

  document.title = "Blockops | Forgot Password";
  return (
    <div>
      {
        !emailSent
        ?
        <div className="form-body">
          <div className="form">
            <h6 className="form-header">Request password reset</h6>
            <p className="form-subtext">Please enter your email</p>
            <div className="w-full max-w-[500px]">
              <form onSubmit={formik.handleSubmit}>
                <div className="w-full mb-6">
                  <div className="app_form">
                    <label htmlFor="email">Email address</label>
                    <input
                      id="email"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="email"
                      placeholder="Enter your email address"
                      className={`border-[1px] focus:outline-none signup-input
                        ${!formik.errors?.email && formik.touched?.email ? 'focus:border-blue' : ''}
                        ${formik.errors?.email && formik.touched?.email ? 'border-[#C63737] border-[1.5px]' : 'border-[rgba(18,18,18,0.3)]'}
                      `}
                    />
                    <div className={`${formik.touched.email && formik.errors.email ? 'inline-block' : 'hidden'} app_form-error`}>
                      {formik.touched.email && formik.errors.email && (formik.errors.email)}
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={status == NETWORK_STATE.LOADING}
                  className="app_button full"
                >
                  {status == NETWORK_STATE.LOADING
                    ? "Requesting..."
                    : "Request reset"}
                </button>
              </form>
            </div>
          </div>

          <div className="mt-2">
            <p className="text-center text-sm font-light">
              Remember your password? &nbsp;
              <Link className="underline text-blue" to="/login">
                Sign in
              </Link>{" "}
            </p>
          </div>
        </div>
        :
        <div className="form-body">
          <div className="form">
            <img src={EmailFrame} alt="" className="self-center w-[130px] mt-10" />
            <p className="form-header">Check your mail</p>
            <p className="form-subtext">We've sent password recovery instructions to your email</p>
            <button
              type="button"
              onClick={() => navigate("/login")}
              className="app_button full"
            >
              Return to login
            </button>
            <div className="bottom-text self-center">{emailResent ? "Please check your email." : "Didn’t receive the email?"}
              <span className={`${emailResent ? 'hidden' : ''} cursor-pointer`}
                onClick={() => {
                  if (!emailResent) {
                    handleReset( { email: email.current } );
                    setEmailResent(true);
                    setTimeout(() => {setEmailResent(false);}, 59000)
                  }
                }}
              >
                &nbsp;
                {
                  !emailResent && "Click to resend."
                }
              </span>
              <div className="inline">
                &nbsp;
                {
                  emailResent &&
                  <Countdown
                    date={Date.now() + 59000}
                    renderer={({ minutes, seconds }) => <p className="text-black underline-none inline">Resend in {minutes}:{seconds}s</p> }
                  />
                }
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
