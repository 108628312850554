import { ReactComponent as Kusama } from "../../assets/svg/networks/Kusama.svg";

export const ValidatedNetworks = [
  {
    name: "Kusama",
    nodeAddresses: [
      "HLsQZEMMN2cDN1cZBgMc48E2v1XergevtDZQ6Nueue7x2F9",
      "E7fzahPfmG1HGENnzh6JAcDm5u5PqBzHVckfT6uV5PNWAJ2",
    ],
    stakeAmount: "500KSM",
    blockHeight: "7689440",
    imageVersion: "Version 2.0",
    icon: <Kusama width={30} />,
  },
];
