/* import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  Colors,
} from "chart.js";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { getGraphData } from "../../../services/NetworkService";
import { getDateTime } from "../../../utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Colors,
  Title,
  Tooltip,
  Filler,
  Legend
);

ChartJS.defaults.color = "#000000";

export const options = {
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
  elements: {
    point: {
      borderWidth: 0,
      radius: 1,
      backgroundColor: "rgba(0,0,0,0)",
    },
  },
  plugins: {
    legend: {
      position: "top",
      display: false,
      labels: {
        font: {
          size: 14,
          family: "BR Firma",
        },
      },
    },
    title: {
      display: true,
      text: "Latency in seconds",
      color: "#000000",
      font: {
        weight: 400,
        size: 15,
        family: "BR Firma",
      },
      padding: { bottom: 20 },
    },
    color: {
      enabled: true,
    },
    tooltip: {
      callbacks: {
        labelColor: function (context) {
          return {
            borderColor: "white",
            backgroundColor: "white",
            borderWidth: 2,
            borderDash: [0, 0],
            borderRadius: 5,
            boxPadding: 5,
            width: 120,
          };
        },
        labelTextColor: function (context) {
          return "#FFFFFF";
        },
        labelPointStyle: function (context) {
          return {
            pointStyle: "triangle",
            rotation: 0,
          };
        },
      },
      displayColors: false,
      backgroundColor: "#045CF4",
      boxWidth: 5,
      boxHeight: 5,
      boxPadding: 10,
    },
  },
};

const CPUChart = ({ networkId }) => {
  const [labels, setLabels] = useState({ placeholder: "Loading..." });

  const networkMapping = {
    1: "blockops-polkadot",
    2: "blockops-kusama",
    3: "blockops-flow",
    4: "blockops-composable",
    5: "blockops-moonbeam",
    6: "blockops-moonriver",
  };

  const startTimeOfChart = new Date(new Date().getTime() - 23 * 60 * 60 * 1000);
  startTimeOfChart.setMinutes(0);
  startTimeOfChart.setSeconds(0);
  startTimeOfChart.setMilliseconds(0);

  const endTimeOfChart = new Date();
  endTimeOfChart.setMinutes(0);
  endTimeOfChart.setSeconds(0);
  endTimeOfChart.setMilliseconds(0);

  const defaultData = {
    labels: Object.values(labels),
    datasets: [
      {
        fill: false,
        label: "Latency(s)",
        data: Object.values(labels).map(() => 0),
        borderColor: "#045CF4",
        borderWidth: "2",
        backgroundColor: "#f2f5ff74",
        tension: 0.3,
      },
    ],
  };
  const [data, setData] = useState(defaultData);

  // Get graph data
  const fetchGraphData = async (endpoint) => {
    let response;
    if (networkId === 1 || networkId === 2) {
      try {
        response = null; //await getGraphData(endpoint);
      } catch {
        response = null;
      }
    } else {
      response = null;
    }
    const matrix = response?.data?.data?.result[0]?.values;

    const graphLabels = {};
    let i = 0;

    while (i < 24) {
      const currDate = new Date(startTimeOfChart.getTime());
      currDate.setHours(currDate.getHours() + i);
      let graphTime = getDateTime(currDate).time;
      const key = String(currDate.getTime()).slice(0, 10);
      graphLabels[key] = graphTime;
      i++;
    }

    const countMapping = { ...graphLabels };
    Object.keys(countMapping).map((key) => {
      countMapping[key] = 0;
    });

    matrix &&
      matrix.map((point) => {
        if (graphLabels[point[0]]) {
          countMapping[point[0]] = +point[1];
        } else {
          countMapping[point[0]] = 0;
        }
      });

    const newData = {
      labels: Object.values(graphLabels),
      datasets: [
        {
          fill: false,
          label: "Latency(s)",
          data: Object.keys(countMapping).map((key) => countMapping[key]),
          borderColor: "#045CF4",
          borderWidth: "2",
          backgroundColor: "#f2f5ff74",
          tension: 0.3,
        },
      ],
    };

    setData(newData);
  };

  useEffect(() => {
    const dataEndpoint = `${
      process.env.REACT_APP_DATAPOINT_URL
    }/api/v1/query_range?query=sum(nginx_ingress_controller_ingress_upstream_latency_seconds_sum 
      {ingress="blockops-polkadot-ingress"})&start=${String(
        startTimeOfChart.getTime()
      ).slice(0, 10)}&end=${String(endTimeOfChart.getTime()).slice(
      0,
      10
    )}&step=1h`;

    fetchGraphData(dataEndpoint);
  }, []);

  return <Line options={options} data={data} />;
};

export default CPUChart;
 */

import ReactECharts from "echarts-for-react";
import { getDateTime } from "../../../utils";

export const nodeColors = [
  "#045CF4",
  "#F49600",
  "#9C04F4",
  "#00A1A1",
  "#6AA0FF",
];
const white = "#FFFFFF";
const lightGrey = "#00000033";
const black = "#000000";

const ParachainCPUChart = () => {
  const startTimeOfChart = new Date(new Date().getTime() - 23 * 60 * 60 * 1000);
  startTimeOfChart.setMinutes(0);
  startTimeOfChart.setSeconds(0);
  startTimeOfChart.setMilliseconds(0);

  const endTimeOfChart = new Date();
  endTimeOfChart.setMinutes(0);
  endTimeOfChart.setSeconds(0);
  endTimeOfChart.setMilliseconds(0);

  const graphLabels = {};
  const xAxisLabels = [];
  let i = 0;

  while (i < 24) {
    const currDate = new Date(startTimeOfChart.getTime());
    currDate.setHours(currDate.getHours() + i);
    let graphTime = getDateTime(currDate).time;
    const key = String(currDate.getTime()).slice(0, 10);
    graphLabels[key] = graphTime;
    xAxisLabels.push(graphTime);
    i++;
  }

  const option = {
    title: {
      text: "CPU 4 cores",
      textStyle: {
        color: black,
        fontSize: 20,
      },
    },
    legend: {
      show: false,
      type: "scroll",
      top: 10,
      data: ["Node 1", "Node 2"],
      orient: "horizontal",
      icon: "roundRect",
      itemGap: 30,
    },
    color: nodeColors,
    xAxis: {
      type: "category",
      data: xAxisLabels,
      axisTick: {
        alignWithLabel: true,
        show: false,
      },
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: lightGrey,
          width: 0.5,
          opacity: "0.5",
        },
      },
      axisLabel: {
        color: black,
      },
      nameTextStyle: {
        color: black,
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        show: false,
      },
    },
    grid: {
      borderColor: lightGrey,
      left: "20px",
      right: "20px",
    },
    series: [
      {
        name: "Node 1",
        data: [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        type: "line",
        smooth: true,
        lineStyle: {
          width: 4,
          /* color: colors[0], */
        },
        /* tooltip: {
          backgroundColor: colors[0],
          borderColor: colors[0],
          textStyle: {
            color: white,
            textBorderColor: white,
          },
        }, */
      },
    ],
    markpoint: {
      symbol: "none",
    },
    tooltip: {
      trigger: "item",
      position: "top",
      backgroundColor: white,
    },
  };

  return (
    <div className="w-full">
      <ReactECharts style={{ height: "500px" }} option={option} />
    </div>
  );
};

export default ParachainCPUChart;
