import React, { useState } from "react";
import CopyTag from "../../assets/svg/projects/content_copy.svg";

export function CopyEndpoint({ query, https, websocket, children }) {
  const [isCopied, setIsCopied] = useState({ copy: false, id: "" });

  const handleCopy = (id) => {
    setIsCopied({ copy: true, id: id });
    setTimeout(() => {
      setIsCopied({ copy: false, id: "" });
    }, 2000);
  };

  return (
    <div>
      <h5 className="font-semibold">{children}</h5>
      <div className="flex items-center gap-[25px] text-sm  h-[48px] bg-[#F2F5FF] text-[#000000] rounded-md px-[10px] border border-[#D8E3F4] border-2">
        <div className="w-full md:w-[450px truncate">{https || websocket}</div>
        <div className="relative">
          <img
            src={CopyTag}
            alt="copy"
            onClick={() => {
              navigator.clipboard.writeText(https || websocket);
              handleCopy(query.id);
            }}
            className={`${
              isCopied.copy && isCopied.id === query.id ? "hidden" : "block"
            } cursor-pointer scale-150`}
          />
          {isCopied.id === query.id && (
            <div className="absolute left-[-35px] top-[-15px] bg-black text-white text-xm font-bold p-1 rounded-lg">
              Copied
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function CopyEndpoints({
  query,
  https,
  websocket,
  children,
  tps,
  wss,
  copy,
}) {
  const [isCopied, setIsCopied] = useState({ copy: false, id: "" });

  const handleCopy = (id) => {
    setIsCopied({ copy: true, id: id });
    setTimeout(() => {
      setIsCopied({ copy: false, id: "" });
    }, 2000);
  };

  return (
    <div className="flex flex-col text-14 text-black">
      <p>
        {tps ? "HTTPS" : ""} {children}
      </p>
      <div className="flex w-full h-[52px] bg-[#EFF5FF] items-center justify-between rounded-[10px] mt-[6px] px-4">
        <p>{tps || wss}</p>
        {copy && (
          <img
            src={CopyTag}
            alt="copy"
            className={`scale-150 ${
              isCopied.copy && isCopied.id === query.id ? "hidden" : "block"
            } cursor-pointer`}
            onClick={() => {
              navigator.clipboard.writeText(https || websocket);
              handleCopy(query.id);
            }}
          />
        )}
        {isCopied.id === query.id && (
          <div className="bg-[#6E6E6F] text-white text-[12px] font-bol p-1 rounded-lg">
            Copied!
          </div>
        )}
      </div>
    </div>
  );
}
export default CopyEndpoints;
