import { useState } from "react";
import { ReactComponent as DropDown } from "../../assets/svg/icons/select-arrow.svg";
import { networkIcons } from "../api-services/Networks";

const Dropdown = ({
  dropdownOpen,
  setDropDownOpen,
  options,
  value,
  setValue,
  iconKey,
  networkIcon,
  textKey,
  itemPosition,
  label,
  errorText,
  isInvalid,
}) => {
  const [btnFocus, setBtnFocus] = useState(false);

  return (
    <div className="standard-dropdown-1">
      <p className={`label ${isInvalid ? "text-red-error" : "text-[#686A6D]"}`}>
        {label}
      </p>
      <button
        type="button"
        className={`button ${
          !isInvalid &&
          (btnFocus
            ? "border-[2px] border-blue"
            : "border-[1px] border-[#8B8D90]")
        } ${isInvalid && "border-[2px] border-red-error"}
      ${!isInvalid && "hover:border-blue"}`}
        onClick={() => {
          setDropDownOpen(!dropdownOpen);
        }}
        onFocus={() => {
          setBtnFocus(true);
        }}
        onBlur={() => {
          setDropDownOpen(false);
          setBtnFocus(false);
        }}
      >
        <div className="display-text">
          {networkIcon && <div>{networkIcons[value]}</div>}
          <p className="cut-text">{value}</p>
        </div>
        <DropDown />

        {dropdownOpen && (
          <div className="button__options">
            {options?.map((option, index) => {
              if (option.id !== 3) {
                return (
                  <div
                    key={index}
                    type="button"
                    onClick={() => {
                      setValue(option[textKey]);
                      setDropDownOpen(false);
                    }}
                    className={`button__options-item ${
                      itemPosition === "center" && "justify-center"
                    }`}
                  >
                    {iconKey && <div>{option[iconKey]}</div>}
                    {networkIcon && <div>{networkIcons[option.name]}</div>}
                    <p>{option[textKey]}</p>
                  </div>
                );
              }
            })}
          </div>
        )}
      </button>

      {isInvalid && errorText && (
        <div className="error">
          <div className="err-icon">!</div>
          <p>{errorText}</p>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
